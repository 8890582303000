import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Loader } from 'components/Shared/Loader'
import { EditDriverAccountForm } from 'components/Drivers/EditDriverForm/EditDriverAccountForm'
import { DriverForm, driverSchema } from 'components/Drivers/DriverForm'
import { editDriver, fetchDriver } from 'services'
import { SuccessDialog } from 'components/Shared/Dialogs/SuccessDialog'
import { getUserPrivilege, useAuth } from 'context/auth'
import { ADMIN, SUPER_ADMIN } from 'components/Shared/AccessControl/Privileges'
import styles from 'components/Shared/CrudComponents/SimpleForm.module.css'
import { useErrorHandling } from 'context/errorHandling'
import { HTTP_404_ERROR, HTTP_500_ERROR } from 'Constants'
import { useNavigatePrevent } from 'context/navigatePrevent'

export function EditDriverForm (props) {
  const [editedDialogOpen, setEditedDialogOpen] = useState(false)
  const [driver, setDriver] = useState(null)

  const history = useHistory()
  const { handleError } = useErrorHandling()

  const { authTokens } = useAuth()
  const isAdmin = authTokens && [SUPER_ADMIN, ADMIN].includes(getUserPrivilege(authTokens))

  const { setNavigationUnlocked, setNavigationBlocked } = useNavigatePrevent()

  useEffect(() => {
      setNavigationBlocked()
    }, [setNavigationBlocked]
  )

  useEffect(() => {
      fetchDriver(props.match.params.id).then(response => {
          setDriver(response)
        },
      ).catch(error => handleError(error, {
        [HTTP_404_ERROR]: 'notifications.error.driverNotFound'
      }).then(() => {
        setNavigationUnlocked()
        history.push('/drivers')
      }))
    }, [props.match.params.id, handleError, history, setNavigationUnlocked],
  )

  const { t } = useTranslation()

  const onDriverEdit = useCallback((values) => editDriver({
    id: driver.id,
    ...values,
  }).then(() => {
      setEditedDialogOpen(true)
    },
  ).catch(error => handleError(error, {
    [HTTP_404_ERROR]: 'notifications.error.driverNotFound',
    [HTTP_500_ERROR]: 'notifications.error.editDriver',
  })), [driver, handleError])

  return <div>
    <SuccessDialog open={editedDialogOpen}
                   onClose={() => {
                     setEditedDialogOpen(false)
                     setNavigationUnlocked()
                     history.push('/drivers')
                   }} content={t('notifications.success.updatedDriver')}/>

    {driver ? <div className={styles.containerRow}>
        <DriverForm
          initialValues={{
            firstName: driver.firstName,
            lastName: driver.lastName,
            address: driver.address,
            phoneNumber: driver.phoneNumber,
            personalPhoneNumber: driver.personalPhoneNumber,
            idCardNumber: driver.idCardNumber,
            idCardExpirationDate: driver.idCardExpirationDate,
            driverLicenseNumber: driver.driverLicenseNumber,
            driverLicenseCategory: driver.driverLicenseCategory,
            driverLicenseExpirationDate: driver.driverLicenseExpirationDate,
            adrCertificateNumber: driver.adrCertificateNumber,
            adrCertificateExpirationDate: driver.adrCertificateExpirationDate,
          }}
          validationSchema={driverSchema}
          onSubmit={onDriverEdit}
          title={t('form.driver.editDriverTitle')}
        >
          {isAdmin && <EditDriverAccountForm id={driver.id}/>}
        </DriverForm>
      </div>
      :
      <Loader/>
    }
  </div>
}
