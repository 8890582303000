import React from 'react'
import { SingleStateElement } from 'components/Order/OrderDetails/StatusBar/StatusBarItem/StatusBarItemTypes/SingleStateElement'
import { LoadingAndUnloadingItem } from 'components/Order/OrderDetails/StatusBar/StatusBarItem/StatusBarItemTypes/LoadingAndUnloadingItem'

export function StatusBarItem ({ status, index }) {
  return status.type === 'LOADING' || status.type === 'UNLOADING'
    ?
    <LoadingAndUnloadingItem status={status} index={index}/>
    :
    <SingleStateElement status={status} index={index}/>
}
