import { useEffect, useState } from 'react'
import { onConnectObservable, onDisconnectObservable, isConnected as isWebSockedConnected } from 'services/SocketClient'

export default () => {
  const [isConnected, setConnected] = useState(isWebSockedConnected())

  useEffect(() => {
    const onConnectSub = onConnectObservable.subscribe(() => setConnected(true))
    const onDisconnectSub = onDisconnectObservable.subscribe(() => setConnected(false))
    setConnected(isWebSockedConnected())

    return () => {
      onConnectSub.unsubscribe()
      onDisconnectSub.unsubscribe()
    }
  }, [])

  return isConnected
}