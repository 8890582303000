import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { CircularProgress, Paper, Table, TableBody, TableHead, Toolbar, } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AddIcon } from 'assets/icons/add-red.svg'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { ContainedButton } from 'components/Shared/ContainedButton'
import { Paginator, RowsNumberSelector } from './pagination'
import { WarningDialog } from 'components/Shared/Dialogs/WarningDialog'
import { SuccessDialog } from 'components/Shared/Dialogs/SuccessDialog'
import { deepFind } from 'components/Shared/Table/TableBodyRow/utils'
import { backgroundColor } from 'styles/variables'
import { useErrorHandling } from 'context/errorHandling'
import { useTableStyles } from './styles'
import {
  getColumnsVisibilityFromLocalStorage,
  getDefaultColumnsVisibility,
  getFiltersFromLocalStorage,
  getRowsPerPageFromLocalStorage,
  getSortFromLocalStorage,
  mergeColumnsVisibilityWithColumns,
  saveColumnsVisibilityInLocalStorage,
  saveFiltersInLocalStorage,
  saveRowsPerPageInLocalStorage,
  saveSortInLocalStorage
} from './preferences'
import { FilterType, Order } from './enums'
import PropTypes from 'prop-types'
import TableBodyRow from './TableBodyRow'
import TableHeadRow from './TableHeadRow'
import TableFiltersRow from './TableFiltersRow'
import { Loader } from 'components/Shared/Loader'
import { TableContainerWrapper } from 'components/Shared/Table/TableContainerWrapper'

const TableComponent = ({
  columns,
  tableKey,
  title,
  addButton,
  fetchData,
  onRowClick,
  menu = {},
  renderToolbarLeftPanel = () => null,
  sort: sortConfig = {
    defaultSortParameters: [],
    columnsGroupedBy: []
  },
  backgroundRefresh = {
    shouldHighlightRow: () => false
  }
}, ref) => {
  const { t } = useTranslation()
  const history = useHistory()
  const queryParams = queryString.parse(window.location.search)
  const classes = useTableStyles()

  const [pageResponse, setPageResponse] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [highlightedIds, setHighlightedIds] = useState([])
  const [selectedId, setSelectedId] = useState('')
  const [page, setPage] = useState(queryParams.page ? parseInt(queryParams.page) : 0)
  const [rowsPerPage, setRowsPerPage] = useState(queryParams.rowsPerPage ? parseInt(queryParams.rowsPerPage) :
    getRowsPerPageFromLocalStorage(tableKey, 25))
  const [sort, setSort] = useState(getSortFromLocalStorage(tableKey, sortConfig.defaultSortParameters))
  const [filters, setFilters] = useState(getFiltersFromLocalStorage(tableKey))
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [successDeletingDialogOpen, setSuccessDeletingDialogOpen] = useState(false)

  const { handleError } = useErrorHandling()

  useEffect(() => {
    saveFiltersInLocalStorage(tableKey, filters)
  }, [tableKey, filters])

  const [columnsVisibility, setColumnsVisibility] = useState(
    getColumnsVisibilityFromLocalStorage(tableKey) ||
    getDefaultColumnsVisibility(columns))

  useEffect(
    () => saveColumnsVisibilityInLocalStorage(tableKey, columnsVisibility),
    [columnsVisibility, tableKey])

  useEffect(() => {
    saveSortInLocalStorage(tableKey, sort)
  }, [sort, tableKey])

  useEffect(() => {
    saveRowsPerPageInLocalStorage(tableKey, rowsPerPage)
  }, [rowsPerPage, tableKey])

  const filteredColumns = useMemo(() =>
      mergeColumnsVisibilityWithColumns(columnsVisibility, columns),
    [columnsVisibility, columns],
  )

  const rows = useMemo(() => {
    if (pageResponse === null) {
      return null
    }
    if (sort.length > 0 && sortConfig.columnsGroupedBy.includes(sort[0].param)) {
      const colorKeyPath = columns.find(col => col.id === sort[0].param).path
      let lastState = ''
      let background = backgroundColor
      return pageResponse.items.map(item => {
        const colorKeyValue = deepFind(item, colorKeyPath)
        if (lastState !== colorKeyValue) {
          lastState = colorKeyValue
          if (background === backgroundColor) {
            background = ''
          } else {
            background = backgroundColor
          }
        }
        return { rowColor: background, ...item }
      })
    } else {
      return pageResponse.items.map((item, index) => {
        if (index % 2 === 0) {
          return item
        } else {
          return { rowColor: backgroundColor, ...item }
        }
      })
    }
  }, [pageResponse, sortConfig.columnsGroupedBy, sort, columns])

  useEffect(() => {
    setSelectedId('')
  }, [pageResponse])

  useEffect(() => {
    const queryParams = {
      page: page,
      rowsPerPage: rowsPerPage,
      sort: sort.length > 0 ? JSON.stringify(sort) : undefined,
      filters: filters.length > 0 ? JSON.stringify(filters) : undefined,
    }
    history.replace({
      pathname: window.location.pathname,
      search: queryString.stringify(queryParams),
    })
  }, [page, rowsPerPage, sort, filters, history])

  const fetch = useCallback(() =>
    fetchData({
      page: page,
      rowsPerPage: rowsPerPage,
      sort: sort,
      filters: filters,
    }), [fetchData, filters, page, rowsPerPage, sort])

  const refreshContent = useCallback(() => {
    setLoading(true)
    fetch()
      .then(setPageResponse)
      .catch(handleError)
      .then(() => setLoading(false))
  }, [fetch, handleError])

  const refreshContentInBackground = useCallback(() => {
    if (!pageResponse) {
      return
    }
    fetch()
      .then(response => {
        const updatedItemsIds = response.items.filter(fetchedItem => {
          const currentItem = pageResponse.items.find(it => it.id === fetchedItem.id)
          if (!currentItem) {
            return true
          }
          return backgroundRefresh.shouldHighlightRow(currentItem, fetchedItem)
        }).map(it => it.id)
        setHighlightedIds([...highlightedIds, ...updatedItemsIds])
        setPageResponse(response)
      })
      .catch(handleError)
  }, [fetch, handleError, pageResponse, highlightedIds, backgroundRefresh])

  useEffect(refreshContent, [fetchData, filters, sort, page, rowsPerPage, sort])

  const handleFiltersChanged = useCallback(newFilters => {
    setFilters(newFilters)
    setPage(0)
  }, [])

  const handleChangePage = useCallback((withScrollTop) => (event, newPage) => {
    setPage(newPage)
    if (withScrollTop) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [])

  const handleChangeRowsPerPage = useCallback(value => {
    setRowsPerPage(value)
    setPage(0)
  }, [])

  const handleColumnsChanged = useCallback(newColumns => {
    const newColumnsVisibility = newColumns.map(col => ({
      id: col.id,
      isVisible: col.isVisible,
    }))
    setColumnsVisibility(newColumnsVisibility)
  }, [])

  const onDeleteDialogClose = (isDeleteConfirmed) => {
    if (isDeleteConfirmed === true) {
      menu.delete.onDelete(selectedId)
        .then(() => {
          setSelectedId('')
          setSuccessDeletingDialogOpen(true)
          const isOnLastAndEmptyPage = rowsPerPage * page === pageResponse.totalItemCount - 1
          if (isOnLastAndEmptyPage && page !== 0) {
            setPage(page - 1)
          } else {
            refreshContent()
          }
        })
        .catch(error => {
          if (menu.delete.onDeleteError) {
            menu.delete.onDeleteError(error)
          }
        })
    }
    setShowDeleteDialog(false)
  }

  const isRowHighlighted = row => highlightedIds.some(id => row.id === id)

  const onRowClickHandler = row => (event) => {
    const selection = window.getSelection()
    if (!selection.toString()) {
      if (isRowHighlighted(row)) {
        const newHighlightedIds = highlightedIds.filter(id => id !== row.id)
        setHighlightedIds(newHighlightedIds)
        event.stopPropagation()
      } else {
        if (onRowClick) {
          onRowClick(row)
        }
      }
    }
  }

  const rowMenuOptions = row => {
    const menuOptions = {}

    if (menu.edit) {
      menuOptions.edit = {
        onClick: () => menu.edit.onClick(row)
      }
    }
    if (menu.copy) {
      menuOptions.copy = {
        onClick: () => menu.copy.onClick(row)
      }
    }
    if (menu.delete && menu.delete.isDeletable?.(row) !== false) {
      menuOptions.delete = {
        onClick: () => {
          setShowDeleteDialog(true)
          setSelectedId(row.id)
        }
      }
    }
    return menuOptions
  }

  useImperativeHandle(ref, () => ({
    refreshContent,
    refreshContentInBackground,
    filters,
    setFilters: handleFiltersChanged
  }), [refreshContent, refreshContentInBackground, filters, handleFiltersChanged])

  return pageResponse ? (
    <div className={classes.root}>
      <SuccessDialog
        open={successDeletingDialogOpen}
        onClose={() => setSuccessDeletingDialogOpen(false)}
        content={'notifications.success.delete'}
      />
      <WarningDialog open={showDeleteDialog}
                     onClose={onDeleteDialogClose}
                     title={menu.delete?.deleteDialog?.title}
                     content={menu.delete?.deleteDialog?.content}/>
      <Paper className={classes.paper} elevation={0}>
        <TableContainerWrapper className={classes.tableContainer}
                               columnsVisibilityChanged={columnsVisibility}>
          <Toolbar className={classes.toolbar} id={'toolbar'}>
            <div className={classes.toolbarContentContainer}>
              <div className={classes.toolbarLeftPanel}>
                <h1 className={classes.title}>
                  {`${t(title)} (${pageResponse.totalItemCount})`}
                </h1>
                <div className={classes.titleNavigation}>
                  <RowsNumberSelector
                    rowsPerPageOptions={[25, 50, 75, 100]}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  {
                    renderToolbarLeftPanel()
                  }
                  {isLoading && <CircularProgress className={classes.loader} size={30}/>}
                </div>
              </div>
              <div className={classes.toolbarRightPanel}>
                <Paginator
                  count={pageResponse.totalItemCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage(false)}
                />
                {
                  addButton && (
                    <div className={classes.addButtonContainer}>
                      <ContainedButton
                        onClick={addButton.onClick}
                        label={addButton.label}
                        icon={<AddIcon/>}
                        className={classes.addButton}/>
                    </div>
                  )
                }
              </div>
            </div>
          </Toolbar>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead className={classes.tableHead}>
              <TableHeadRow
                tableKey={tableKey}
                sort={sort}
                onSortChanged={setSort}
                columns={filteredColumns}
                onColumnsChanged={handleColumnsChanged}
              />
              <TableFiltersRow
                columns={filteredColumns}
                filters={filters}
                onFiltersChanged={handleFiltersChanged}
              />
              <tr className={classes.spacer} id={'table-body-spacer'}/>
            </TableHead>
            <TableBody>
              {
                rows.map(row => (
                  <TableBodyRow
                    key={row.id}
                    row={row}
                    onClick={onRowClickHandler(row)}
                    menuOptions={rowMenuOptions(row)}
                    columns={filteredColumns}
                    isHighlighted={isRowHighlighted(row)}
                  />
                ))
              }
            </TableBody>
          </Table>
          <Toolbar>
            <div className={'flex-grow-1'}/>
            <Paginator
              count={pageResponse.totalItemCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage(true)}
            />
            <div className={'flex-grow-1'}/>
          </Toolbar>
        </TableContainerWrapper>
      </Paper>
    </div>
  ) : <Loader/>
}

const ForwardedTableComponent = forwardRef(TableComponent)

ForwardedTableComponent.displayName = 'Table'

ForwardedTableComponent.propTypes = {
  title: PropTypes.string.isRequired,
  tableKey: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      filterType: PropTypes.oneOf(Object.values(FilterType)).isRequired,
      filterName: PropTypes.string,
      filterNames: PropTypes.arrayOf(PropTypes.string),
      filterPlaceholders: PropTypes.arrayOf(PropTypes.string),
      renderFunc: PropTypes.func,
      sortable: PropTypes.bool,
      filterInputProps: PropTypes.object,
      selectOptions: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        })
      ),
      statusToIndicatorColorMap: PropTypes.object,
    })
  ).isRequired,
  fetchData: PropTypes.func.isRequired,
  addButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }),
  onRowClick: PropTypes.func,
  menu: PropTypes.shape({
    edit: PropTypes.shape({
      onClick: PropTypes.func.isRequired
    }),
    copy: PropTypes.shape({
      onClick: PropTypes.func.isRequired
    }),
    delete: PropTypes.shape({
      onDelete: PropTypes.func.isRequired,
      onDeleteError: PropTypes.func,
      isDeletable: PropTypes.func,
      deleteDialog: PropTypes.shape({
        content: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
      })
    })
  }),
  renderToolbarLeftPanel: PropTypes.func,
  sort: PropTypes.shape({
    defaultSortParameters: PropTypes.arrayOf(
      PropTypes.shape({
        param: PropTypes.string.isRequired,
        order: PropTypes.oneOf(Object.values(Order)).isRequired
      })
    ),
    columnsGroupedBy: PropTypes.arrayOf(PropTypes.string)
  }),
  backgroundRefresh: PropTypes.shape({
    shouldHighlightRow: PropTypes.func
  })
}

export default ForwardedTableComponent
