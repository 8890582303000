import React from 'react'
import mailOptionsJson from 'components/Order/OrderForm/MailSettingsForm/mailOptions'
import mailLanguages from 'components/Order/OrderForm/MailSettingsForm/mailLanguages'
import styles from 'components/Order/OrderForm/MailSettingsForm/MailSettingsForm.module.css'
import { useTranslation } from 'react-i18next'
import { SelectField } from 'components/Shared/SelectField'

export function MailSettingsForm ({ setFieldValue, values }) {
  const { t } = useTranslation()

  const mailOptions = mailOptionsJson.map(option => {
    return {
      id: option.id,
      label: t(option.label),
      frequencySettingsType: option.frequencySettingsType,
      sendIntervalHours: option.sendIntervalHours
    }
  })

  const mapValue = (field, id) => {
    const selectedOption = mailOptions.find(obj => obj.id === id)
    setFieldValue('emailNotificationSettings.frequencySettingsType', selectedOption ? selectedOption.frequencySettingsType : '')
    setFieldValue('emailNotificationSettings.sendIntervalHours', selectedOption ? selectedOption.sendIntervalHours : '')
  }

  const defaultLanguageValue = () => {
    return mailLanguages.find(lang => lang.id === values.emailNotificationSettings.language)
  }

  const defaultIntervalValue = () => {
    const options = mailOptions.filter(option => option.frequencySettingsType === values.emailNotificationSettings.frequencySettingsType)
    if (options.length === 1) {
      return options[0]
    }
    const selectedValue = options.find(option => option.sendIntervalHours === values.emailNotificationSettings.sendIntervalHours)
    return selectedValue ? selectedValue : mailOptions.find(option => option.id === 'MORNING_AND_EVENING')
  }

  return <div className={styles.mailContainer}>
    <div className={styles.mailFormContainer}>
      <div>{t('form.emailNotificationSetting.label')}</div>
      <div>
        <SelectField data={mailOptions} label={t('form.emailNotificationSetting.sendInterval.title')}
                     fieldName={'sendIntervalOptions'}
                     setFieldValue={mapValue}
                     defaultValue={defaultIntervalValue}
                     inputClassName={styles.intervalField}
        />
      </div>
      <div>
        <SelectField data={mailLanguages} label={t('form.emailNotificationSetting.language.title')}
                     fieldName={'emailNotificationSettings.language'}
                     setFieldValue={setFieldValue}
                     defaultValue={defaultLanguageValue}
                     inputClassName={styles.languageField}
        />
      </div>
    </div>
  </div>
}
