import { TableCell, TableRow, TableSortLabel } from '@material-ui/core'
import SortPriorityBadge from '../SortPriorityBadge'
import { TableSortingList } from '../TableSortingList'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTableHeadStyles } from './styles'
import { Order } from '../enums'

const HeadCell = ({ column, sort, onSortChanged }) => {
  const sortParam = sort.find(param => param.param === column.id)
  const isSortable = column.sortable || false

  const classes = useTableHeadStyles()

  const { t } = useTranslation()

  const handleSortChangeClick = () => {
    let newSort
    if (sortParam) {
      if (sortParam.order === Order.Ascending) {
        newSort = sort.map(sp => {
          if (sp.param === column.id) {
            return {
              param: column.id,
              order: Order.Descending,
            }
          } else {
            return sp
          }
        })
      } else {
        newSort = sort.filter(sp => sp.param !== column.id)
      }
    } else {
      newSort = sort.concat({ param: column.id, order: Order.Ascending })
    }
    onSortChanged(newSort)
  }

  return (
    <TableCell sortDirection={sortParam?.order || false}>
      {
        isSortable ? (
            <div className={classes.sortLabelContainer}
                 onClick={handleSortChangeClick}>
              <TableSortLabel active={!!sortParam}
                              direction={sortParam?.order}>
                <div className={classes.columnLabel}>
                  {t(column.label)}
                </div>
                {
                  sortParam && (
                    <span className={classes.visuallyHidden}>
                    {sortParam.order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                  )
                }
              </TableSortLabel>
              {sortParam && <SortPriorityBadge priority={1 + sort.indexOf(sortParam)}/>}
            </div>
          )
          : t(column.label)
      }
    </TableCell>
  )

}

export default ({ tableKey, columns, onColumnsChanged, sort, onSortChanged }) => {

  return (
    <TableRow id={'column-headers-row'}>
      {
        columns.filter(col => col.isVisible).map(col =>
          <HeadCell
            key={col.id}
            column={col}
            sort={sort}
            onSortChanged={onSortChanged}
          />
        )
      }
      <TableCell>
        <TableSortingList
          tableKey={tableKey}
          headers={columns}
          setHeaders={onColumnsChanged}
          sort={sort}
        />
      </TableCell>
    </TableRow>
  )
}
