import { makeStyles } from '@material-ui/core/styles'
import { backgroundColor, primaryRed } from 'styles/variables'

export const useTableStyles = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    // transform required to make toolbar width match parent
    transform: 'translate(0, 0)'
  },
  table: {
    minWidth: 750,
    overflowX: 'auto',
    borderCollapse: 'separate'
  },
  tableContainer: {
    overflowX: 'auto',
    width: '100%',
    maxHeight: '100vh',
    transition: 'padding-top 0.3s'
  },
  tableHead: {
    '& tr > th': {
      boxShadow: '0 -1px 0 rgb(224 224 224), 0 1px 0 rgb(224 224 224)',
      zIndex: 3,
      backgroundColor: '#FFF',
      top: 0,
      position: 'sticky'
    }
  },
  toolbarContentContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px',
    backgroundColor: backgroundColor,
    '@media (max-width: 1224px)': {
      flexDirection: 'column',
      padding: '50px 5px 30px 5px',
    },
    zIndex: 3,
  },
  toolbar: {
    top: 0,
    display: 'flex',
    width: '100%',
    paddingRight: 0,
    paddingLeft: 0,
    position: 'fixed',
    zIndex: 3,
    transition: 'top .3s',
  },
  toolbarLeftPanel: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1224px)': {
      flexDirection: 'column',
    },
  },
  toolbarRightPanel: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1224px)': {
      flexDirection: 'column-reverse',
    },
  },
  titleNavigation: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    whiteSpace: 'nowrap',
    marginBottom: 0,
    '@media (max-width: 1224px)': {
      fontSize: '27px !important',
    },
  },
  addButtonContainer: {
    alignSelf: 'center',
    margin: '0 0 0 70px',
    '@media (max-width: 1224px)': {
      margin: '10px 0 20px',
    },
  },
  addButton: {
    minWidth: '170px'
  },
  formControl: {
    width: '100%',
    maxWidth: '175px',
  },
  loader: {
    color: primaryRed,
    marginLeft: '8px'
  },
  spacer: {
    transition: 'height 0.3s'
  }
})
