import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import styles from 'components/Order/OrderForm/ShipmentDetailsForm/ShipmentDetailsForm.module.css'
import { FieldComponent } from 'components/Shared/FieldComponent'
import { NumberField } from 'components/Shared/NumberField'

export function CargoMainInfoForm ({ name }) {
  return <div className={styles.containerColumn}>
    <FieldComponent fieldName={`${name}.cargoDetails.name`} label={'form.order.cargo.name'}/>
    <FieldComponent fieldName={`${name}.cargoDetails.wrappingType`} label={'form.order.cargo.wrappingType'}/>
    <div className={styles.containerRow}>
      <NumberField
        fieldName={`${name}.cargoDetails.quantity`}
        label={'form.order.cargo.quantity'}
        className={styles.cargoQuantityField}
      />
      <NumberField
        fieldName={`${name}.cargoDetails.weight`}
        label={'form.order.cargo.weight'}
        className={styles.cargoWeightField}
        endAdornment
        endAdornmentValue={<InputAdornment position="end">kg</InputAdornment>}
      />
    </div>
    <FieldComponent fieldName={`${name}.cargoDetails.dispenser`} label={'form.order.cargo.dispenser'}/>
    <FieldComponent
      fieldName={`${name}.additionalInfo`}
      label={'form.order.cargo.remarks'}
      multiline
    />
  </div>
}
