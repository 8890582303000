import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import Popover from '@material-ui/core/Popover'
import InputLabel from '@material-ui/core/InputLabel'
import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg'
import { DateRangeSelect } from 'components/Shared/DateRangeSelect'
import { AvenirMedium } from 'styles/variables'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    '& > .MuiInputLabel-formControl': {
      top: '-2px',
      left: '-2px'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: 'inherit'
    },
    '& .MuiFilledInput-root.Mui-focused': {
      borderColor: '#E2E2EA'
    }
  },
  filterSelect: {
    height: '50px',
    width: '210px',
    '& div': {
      padding: '11px 11px 5px 9px',
      fontSize: 15,
      fontFamily: AvenirMedium,
      textAlign: 'left',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'unset'
    }
  }
}))

const filterOptions = {
  none: 'dateFilter.all',
  today: 'dateFilter.today',
  lastMonth: 'dateFilter.lastMonth',
  last3Month: 'dateFilter.last3Month',
  lastHalfYear: 'dateFilter.lastHalfYear',
  lastYear: 'dateFilter.lastYear',
  selectFromCalendar: 'dateFilter.selectFromCalendar',
}

const { lastYear, lastHalfYear, none, last3Month, today, selectFromCalendar, lastMonth } = filterOptions
const filterOptionsOrder = [
  none,
  today,
  lastMonth,
  last3Month,
  lastHalfYear,
  lastYear,
  selectFromCalendar]

const filterNames = ['anyLoadingAfter', 'anyLoadingBefore']

export function CalendarFilter ({ filters, onFiltersChanged }) {
  const classes = useStyles()

  const filterValues = filterNames.map(name => filters.find(f => f.key === name)?.value)

  const dateRangeSelectDefaultValue = filterValues.map(val => val ? new Date(val) : null)

  function getSelectedOptionFromFilters () {
    const from = filterValues[0]
    const to = filterValues[1]
    if (from && to) {
      const startDate = moment(from).format('L')
      const endDate = moment(to).format('L')
      const currentDate = moment().format('L')
      if (startDate === currentDate && endDate === currentDate) {
        return today
      } else if (startDate === moment().year(moment().year() - 1).format('L') && endDate === currentDate) {
        return lastYear
      } else if (startDate === moment().month(moment().month() - 6).format('L') && endDate === currentDate) {
        return lastHalfYear
      } else if (startDate === moment().month(moment().month() - 3).format('L') && endDate === currentDate) {
        return last3Month
      } else if (startDate === moment().month(moment().month() - 1).format('L') && endDate === currentDate) {
        return lastMonth
      } else {
        return selectFromCalendar
      }
    } else {
      return none
    }
  }

  const { t } = useTranslation()
  const [selected, setSelected] = useState(getSelectedOptionFromFilters())
  const [selectComponent, setSelectComponent] = useState(null)
  const [isPopoverOpen, setPopoverOpen] = useState(false)

  const closePopoverWithNoResults = () => {
    setPopoverOpen(false)
    setSelected(getSelectedOptionFromFilters())
  }

  const handleRangeSelected = (startMoment, endMoment) => {
    const filterValues = [startMoment, endMoment].map(m => m.format('YYYY-MM-DDTHH:mm:ss'))
    let newFilters = [...filters]
    filterNames.forEach(name => {
      newFilters = newFilters.filter(f => f.key !== name)
    })
    filterNames.forEach((name, i) => {
      const newFilter = {
        key: name,
        value: filterValues[i]
      }
      newFilters.push(newFilter)
    })
    onFiltersChanged(newFilters)
  }

  const handleNoneSelected = () => {
    let newFilters = [...filters]
    filterNames.forEach(name => {
      newFilters = newFilters.filter(f => f.key !== name)
    })
    onFiltersChanged(newFilters)
  }

  const closePopoverWithResults = result => {
    const startMoment = moment(result[0]).startOf('day')
    const endMoment = moment(result[1]).endOf('day')
    handleRangeSelected(startMoment, endMoment)
    setPopoverOpen(false)
    setSelected(selectFromCalendar)
  }

  const select = (event) => {
    let selectedValue = event.target.value

    if (selectedValue === undefined) {
      selectedValue = selected
      if (selectedValue === selectFromCalendar) {
        return
      }
    }
    if (selectedValue === 0) {
      selectedValue = selected
    } else {
      setSelected(selectedValue)
    }

    const todayStart = moment().startOf('day')
    const todayEnd = moment().endOf('day')
    switch (selectedValue) {
      case today : {
        handleRangeSelected(todayStart, todayEnd)
        break
      }
      case lastYear : {
        handleRangeSelected(
          todayStart.year(moment().year() - 1),
          todayEnd)
        break
      }
      case lastHalfYear : {
        handleRangeSelected(
          todayStart.month(moment().month() - 6),
          todayEnd)
        break
      }
      case selectFromCalendar : {
        setPopoverOpen(true)
        break
      }
      case last3Month : {
        handleRangeSelected(
          todayStart.month(moment().month() - 3),
          todayEnd)
        break
      }
      case lastMonth : {
        handleRangeSelected(
          todayStart.month(moment().month() - 1),
          todayEnd)
        break
      }
      case none :
      default: {
        handleNoneSelected()
        break
      }
    }
  }

  return <FormControl className={classes.formControl}>
    <FormControl variant="filled" className={classes.formControl}>
      <InputLabel shrink id="dateSelect">
        {t('orderTable.calendarFilterLabel')}
      </InputLabel>
      <Select id="params.id" labelId="dateSelect" className={classes.filterSelect} displayEmpty
              value={selected}
              disableUnderline
              IconComponent={DropdownIcon}
              onClick={(event) => {
                select(event)
                setSelectComponent(event.currentTarget)
              }}
      >
        {filterOptionsOrder.map(option => <MenuItem value={option} key={option}>{t(option)}</MenuItem>)}
      </Select>
    </FormControl>
    <Popover
      open={isPopoverOpen}
      anchorEl={selectComponent}
      onClose={closePopoverWithNoResults}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <DateRangeSelect defaultValue={dateRangeSelectDefaultValue}
                       onClose={result => {
                         if (result) {
                           closePopoverWithResults(result)
                         } else {
                           closePopoverWithNoResults()
                         }
                       }}/>
    </Popover>
  </FormControl>
}
