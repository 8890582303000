import * as Yup from 'yup'
import styles from 'components/Users/UserForm.module.css'
import React from 'react'
import { PasswordField } from 'components/Shared/PasswordField'
import { FieldComponent } from 'components/Shared/FieldComponent'

export const UserAccountSchema = Yup.object().shape({
  username: Yup.string()
    .min(6, 'form.validation.string.tooShort')
    .max(30, 'form.validation.string.tooLong')
    .required('form.validation.required'),
  password: Yup.string()
    .min(6, 'form.validation.string.tooShort')
    .max(30, 'form.validation.string.tooLong')
    .required('form.validation.required'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'form.validation.passwordMatch')
    .min(6, 'form.validation.string.tooShort')
    .max(30, 'form.validation.string.tooLong')
    .required('form.validation.required')
})

export function UserAccountFormFields ({ disableAccountFieldsHelperTextMargin }) {
  return <>
    <div className={styles.container}>
      <div
        className={
          `${styles.usernameFieldContainer} 
           ${disableAccountFieldsHelperTextMargin ? styles.disableHelperTextMargin : ''}
          `
        }>
        <FieldComponent fieldName={'username'} label={'form.account.username'}/>
      </div>
      <PasswordField
        fieldName={'password'}
        label={'form.account.password'}
        disableAccountFieldsHelperTextMargin={disableAccountFieldsHelperTextMargin}
      />
      <PasswordField
        fieldName={'passwordConfirmation'}
        label={'form.account.passwordConfirmation'}
        disableAccountFieldsHelperTextMargin={disableAccountFieldsHelperTextMargin}
      />
    </div>
  </>
}
