import React from 'react'
import NumberRange from 'components/Shared/Table/NumberRange'
import { findFilter, setOrDropFilter } from 'components/Shared/Table/TableFiltersRow/utils'
import { useTableFiltersStyles } from 'components/Shared/Table/TableFiltersRow/styles'

export default ({ column, filters, onFiltersChanged }) => {
  const classes = useTableFiltersStyles()

  const defaultValue = column.filterNames.map(filterName => findFilter(filters, filterName)?.value)

  const handleChange = value => {
    let newFilters = [...filters]
    column.filterNames.forEach((filterName, i) => {
      newFilters = setOrDropFilter(newFilters, filterName, value[i])
    })
    onFiltersChanged(newFilters)
  }

  return (
    <NumberRange
      defaultValue={defaultValue}
      onChange={handleChange}
      minValue={0}
      minPlaceholder={column.filterPlaceholders?.[0]}
      maxPlaceholder={column.filterPlaceholders?.[1]}
      numberFieldClassName={classes.numberInput}
    />
  )
}
