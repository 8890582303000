import { createContext, useContext } from 'react'
import jwt_decode from 'jwt-decode'

export const AuthContext = createContext()

export function useAuth () {
    return useContext(AuthContext)
}

export function getUserPrivilege (authToken) {
    return jwt_decode(authToken).privilege
}

export function getUsername (authToken) {
    return jwt_decode(authToken).sub
}
