export const Order = {
  Ascending: 'asc',
  Descending: 'desc',
}

export const FilterType = {
  None: 'none',
  Text: 'text',
  Select: 'select',
  DateRange: 'dateRange',
  Date: 'date',
  NumberRange: 'numberRange',
}
