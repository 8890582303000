import React, { useCallback } from 'react'
import { Button, TableCell, TableRow } from '@material-ui/core'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useTableFiltersStyles } from './styles'
import { FilterType } from '../enums'
import { debounce } from 'lodash'
import TextFilter from './TextFilter'
import NumberRangeFilter from './NumberRangeFilter'
import DateFilter from './DateFilter'
import DateRangeFilter from 'components/Shared/Table/TableFiltersRow/DateRangeFilter'
import SelectFilter from 'components/Shared/Table/TableFiltersRow/SelectFilter'

const FilterCell = ({ column, filters, onFiltersChanged }) => {
  const classes = useTableFiltersStyles()
  const filterComponent = {
    [FilterType.Text]: TextFilter,
    [FilterType.Date]: DateFilter,
    [FilterType.DateRange]: DateRangeFilter,
    [FilterType.NumberRange]: NumberRangeFilter,
    [FilterType.Select]: SelectFilter,
    [FilterType.None]: () => null
  }[column.filterType] || (() => null)

  return (
    <TableCell className={classes.cell}>
      {
        filterComponent({ column, filters, onFiltersChanged })
      }
    </TableCell>
  )
}

export default ({ columns, filters, onFiltersChanged }) => {
  const { t } = useTranslation()
  const classes = useTableFiltersStyles()

  const handleClearFiltersClick = () => {
    onFiltersChanged([])
    window.location.reload()
  }

  const onFiltersChangedDebounced = useCallback(debounce(onFiltersChanged, 1000), [onFiltersChanged])

  return (
    <TableRow id={'filter-headers-row'}>
      {
        columns.filter(col => col.isVisible).map(col =>
          <FilterCell
            key={col.id}
            column={col}
            filters={filters}
            onFiltersChanged={onFiltersChangedDebounced}/>
        )
      }
      <TableCell
        className={classNames(classes.cell, classes.clearFiltersCell)}>
        <Button
          size="small"
          className={classNames(classes.clearFiltersBtn, filters.length === 0 && 'invisible')}
          onClick={handleClearFiltersClick}>
          {t('table.clearFilters')}
        </Button>
      </TableCell>
    </TableRow>
  )
}
