import moment from 'moment'

export const isoLocalDatetimeFormat = 'YYYY-MM-DDTHH:mm:ss'

export const displayDateTimeFormat = 'DD-MM-YYYY HH:mm'

export const zonedDateTimeFormat = 'YYYY-MM-DDTHH:mm:ssZ'

export function toDisplayDateTime (isoDateTimeStr) {
  return moment(isoDateTimeStr).format(displayDateTimeFormat)
}

export function toISOLocalDateTime (displayDateTimeStr) {
  return moment(displayDateTimeStr, displayDateTimeFormat, true).format(isoLocalDatetimeFormat)
}

export function toISOZonedDateTime (displayDateTimeStr) {
  return moment(displayDateTimeStr, displayDateTimeFormat, true).format()
}

export function momentToISOLocalDateTime (moment) {
  return moment.format(isoLocalDatetimeFormat)
}

export function momentToISOZonedDateTime (moment) {
  return moment.format(zonedDateTimeFormat)
}

export function momentToDisplayDateTimeFormat (moment) {
  return moment.format(displayDateTimeFormat)
}

export function momentToISOLocalDate (moment) {
  return moment.format('YYYY-MM-DD')
}

export function momentToISOLocalTime (moment) {
  return moment.format('HH:mm')
}

export function parseMomentFromLocalDateTimeField (str) {
  return moment(str, displayDateTimeFormat, true)
}

export function parseMomentFromLocalDateAndLocalTimeFields (localDateStr, localTimeStr) {
  return moment(`${localDateStr} ${localTimeStr}`)
}

export function parseMomentFromZonedDateTime (str) {
  return moment(str, zonedDateTimeFormat, true)
}