import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Loader } from 'components/Shared/Loader'
import { EditUserAccountForm } from 'components/Users/EditUserForm/EditUserAccountForm'
import internalStyles from 'components/Users/Details/UserDetails.module.css'
import { ReadOnlyTextField } from 'components/Shared/ReadOnlyTextField'
import { fetchDepartments } from 'services/DepartmentService'
import { fetchBranches } from 'services/BranchService'
import styles from 'components/Shared/CrudComponents/SimpleDetails.module.css'
import classNames from 'classnames'
import { HTTP_404_ERROR } from "Constants";
import { useErrorHandling } from "context/errorHandling";

export function UserDetails ({
  fetchData,
  title,
  showPasswordChangeForm,
  disableAccountFieldsHelperTextMargin
}) {
  const [user, setUser] = useState(null)
  const [department, setDepartment] = useState(null)
  const [branch, setBranch] = useState(null)

  const history = useHistory()
  const { t } = useTranslation()
  const { handleError } = useErrorHandling()

  const handleNotFoundError = useCallback((error, message) =>
      handleError(error, {
          [HTTP_404_ERROR]: message
        }
      ).then(() => history.push('/users'))
    , [handleError, history])

  useEffect(() => {
    fetchData().then(response => {
        setUser(response)
      },
    ).catch(error => handleNotFoundError(error, 'notifications.error.userFetchError'))
  }, [fetchData, handleNotFoundError])

  useEffect(() => {
    if (user) {
      fetchBranches().then(response => {
        const filteredBranch = response.find(
          branch => branch.id === user.branchId)
        if (filteredBranch) {
          setBranch(filteredBranch)
        } else {
          handleNotFoundError({ response: { status: HTTP_404_ERROR } }, 'notifications.error.branchFetchError')
        }
      }).catch(error => handleNotFoundError(error, 'notifications.error.branchFetchError'))
      fetchDepartments().then(response => {
        const filteredDepartment = response.find(
          department => department.id === user.departmentId)
        if (filteredDepartment) {
          setDepartment(filteredDepartment)
        } else {
          handleNotFoundError({ response: { status: HTTP_404_ERROR } }, 'notifications.error.departmentFetchError')
        }
      }).catch(error => handleNotFoundError(error, 'notifications.error.departmentFetchError'))

    }
  }, [user, handleNotFoundError])

  return <div>
    {user ?
      <div>
        {title(user)}
        <div>
          <div className={styles.contentRowTwoColumn}>
            <div className={styles.detailsColumn}>
              <div className={styles.headerTwoColumn}>
                {t('user.details.basicDetails')}</div>
              <div className={styles.detailsContentColumn}>
                <ReadOnlyTextField clearDisabled label={'user.details.firstName'} value={user.firstName}/>
                <ReadOnlyTextField clearDisabled label={'user.details.lastName'} value={user.lastName}/>
                <ReadOnlyTextField clearDisabled label={'user.details.phoneNumber'} value={user.phoneNumber}/>
                <ReadOnlyTextField clearDisabled label={'user.details.personalPhoneNumber'}
                                   value={user.personalPhoneNumber}/>
                <ReadOnlyTextField clearDisabled label={'user.details.email'} value={user.email}/>
                <ReadOnlyTextField clearDisabled label={'user.details.department'}
                                   value={department ? department.name : ''}/>
                <ReadOnlyTextField clearDisabled label={'user.details.branch'} value={branch ? branch.name : ''}/>
                <ReadOnlyTextField clearDisabled label={'user.details.hash'} value={user.hash}/>
              </div>
            </div>
            {showPasswordChangeForm && <div className={styles.detailsColumn}>
              <div className={styles.headerTwoColumn}>
                {t('form.account.title')}
              </div>
              <div className={classNames(styles.detailsContentColumn, internalStyles.editUserAccountContainer)}>
                <EditUserAccountForm
                  user={user}
                  disableAccountFieldsHelperTextMargin={disableAccountFieldsHelperTextMargin}/>
              </div>
            </div>
            }
            <div className={styles.flexHeaderTwoColumn}/>
          </div>
        </div>
      </div>
      :
      <Loader/>
    }
  </div>
}
