import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { TitleBar } from '../Shared/CrudComponents/TitleBar'
import { UserFormFields } from './UserFormFields'

export function UserForm ({ initialValues, validationSchema, onSubmit, children, title }) {
  const [isSubmitting, setSubmitting] = useState(false)
  const { t } = useTranslation()

  const handleSubmit = (values) => {
    setSubmitting(true)
    onSubmit(values)
      .finally(() => setSubmitting(false))
  }

  return <Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}>
    <Form className={"w-100"}>
      <TitleBar
        buttonLabel={t('form.user.submit')}
        title={title}
        isButtonDisabled={isSubmitting}/>
      <UserFormFields children={children}/>
    </Form>
  </Formik>
}
