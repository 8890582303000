import React, { useCallback, useContext, useState } from 'react'
import ApiError from "constants/ApiError";
import {
  HTTP_401_ERROR,
  HTTP_403_ERROR,
  HTTP_404_ERROR,
  HTTP_408_ERROR,
  HTTP_422_ERROR,
  HTTP_500_ERROR
} from "Constants";

const ErrorContext = React.createContext({
  error: null,
  handleError: () => {},
  clearError: () => {}
})

export function ErrorHandlingProvider ({ children }) {
  const [error, setError] = useState(null)

  const handleError = useCallback((error, overrideHandler) => {
    const errorCode = error?.response?.status;
    if (errorCode) {
      if (overrideHandler && overrideHandler[errorCode]) {
        setError(overrideHandler[errorCode])
      } else if (errorCode === HTTP_401_ERROR) {
        setError(ApiError.Unauthorized)
      } else if (errorCode === HTTP_403_ERROR) {
        setError(ApiError.Forbidden)
      } else if (errorCode === HTTP_404_ERROR) {
        setError(ApiError.NotFound)
      } else if (errorCode === HTTP_408_ERROR) {
        setError(ApiError.Timeout)
      } else if (errorCode === HTTP_422_ERROR) {
        setError(ApiError.UnprocessableEntity)
      } else if (errorCode === HTTP_500_ERROR) {
        setError(ApiError.InternalError)
      } else setError(ApiError.UnknownFailure)
    } else {
      setError(ApiError.NetworkError)
    }
    return Promise.resolve(errorCode)
  }, [])

  const clearError = useCallback(() => setError(null), [])

  const value = {
    error,
    handleError: handleError,
    clearError: clearError
  }

  return (
    <ErrorContext.Provider value={value}>
      {children}
    </ErrorContext.Provider>
  )
}

export function useErrorHandling () {
  const { error, handleError, clearError } = useContext(ErrorContext)
  return { error, handleError, clearError }
}
