import React, { useMemo } from 'react'
import AttachmentNotification from 'components/Notification/NotificationContent/AttachmentNotification'
import OrderWaitingForApproveNotification
  from 'components/Notification/NotificationContent/OrderWaitingForApproveNotification'

export function Notification ({ item, onNotificationUpdate, bodyClassName }) {
  const content = useMemo(() => JSON.parse(item.content), [item])

  return <div>
    {{
      'NEW_ATTACHMENT_IN_ORDER': <AttachmentNotification item={item}
                                                         content={content}
                                                         onNotificationUpdate={onNotificationUpdate}
                                                         bodyClassName={bodyClassName}/>,
      'ORDER_WAITING_FOR_APPROVE': <OrderWaitingForApproveNotification item={item}
                                                                       content={content}
                                                                       onNotificationUpdate={onNotificationUpdate}
                                                                       bodyClassName={bodyClassName}/>
    }[item.type]}
  </div>
}