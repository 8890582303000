import { useTranslation } from 'react-i18next'
import styles from './NotificationBody.module.css'
import React from 'react'
import { ReactComponent as Read } from 'assets/icons/eye.svg'
import { ReactComponent as NotRead } from 'assets/icons/invisible.svg'
import classNames from 'classnames'
import moment from 'moment'
import { markAsNotRead, markAsRead } from 'services'
import { useErrorHandling } from 'context/errorHandling'

export function NotificationBody ({
  title,
  item,
  footerLeftContent,
  onNotificationUpdate,
  bodyClassName,
  children
}) {

  const { t } = useTranslation()
  const { handleError } = useErrorHandling()

  const markRead = () => {
    markAsRead(item.id)
      .then(onNotificationUpdate)
      .catch(handleError)
  }

  const markNotRead = () => {
    markAsNotRead(item.id)
      .then(onNotificationUpdate)
      .catch(handleError)
  }

  return <div className={classNames(styles.body, bodyClassName)}>
    <div className={styles.markAsReadContainer}>
      {!item.read && <Read className={styles.readIcon} onClick={markRead}/>}
      {item.read && <NotRead className={styles.readIcon} onClick={markNotRead}/>}
    </div>
    <div className={styles.title}>
      <div className={classNames(styles.ellipse, item.read ? styles.inactive : styles.active)}/>
      <div>
        {t(title)}
      </div>
    </div>
    <div className={styles.content}>
      {children}
    </div>
    <div className={styles.footer}>
      <div className={styles.footerLeft}>{footerLeftContent}</div>
      <div className={styles.footerRight}>{moment(item.creationDateTime).fromNow()}</div>
    </div>
  </div>
}
