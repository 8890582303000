import Tooltip from '@material-ui/core/Tooltip'
import withStyles from '@material-ui/core/styles/withStyles'
import { AvenirRegular } from 'styles/variables'

export default withStyles(() => ({
  tooltip: {
    backgroundColor: '#CBCBCB',
    color: '#000',
    fontSize: 12,
    fontFamily: AvenirRegular,
    padding: '7px 16px',
    maxWidth: 'none',
    borderRadius: '4px'
  },
  arrow: {
    color: '#CBCBCB'
  }
}))(Tooltip)
