import {stringify} from 'query-string';

export function stringifyPageQuery(params) {
  let query = {
    pageNumber: JSON.stringify(params.page),
    pageSize: JSON.stringify(params.rowsPerPage),
    sort: params.sort.map(param => formatCamelToSnakeCase(param.param + ',' + param.order))
  };
  params.filters.forEach(filter => {
    query[filter.key] = filter.value
  });
  return stringify(query)
}

function formatCamelToSnakeCase(string) {
  return string.replace(/[\w]([A-Z])/g, (m) => m[0] + '_' + m[1]).toUpperCase()
}
