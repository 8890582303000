import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import { PrimaryCheckbox } from 'components/Shared/PrimaryCheckbox'
import { AvenirRegular } from 'styles/variables'

export function ReadOnlyCheckbox ({ label, checked = true }) {
  const { t } = useTranslation()
  return (
    <FormControlLabel
      control={
        <PrimaryCheckbox
          checked={checked}
          disabled
        />
      }
      label={<Typography style={{ fontFamily: AvenirRegular }}>{t(label)}</Typography>}
    />
  )
}
