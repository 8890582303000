import React from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ReactComponent as VislineLogo } from 'assets/logos/logowithsubtext.svg'
import { ReactComponent as TruckAsset } from 'assets/logos/truck.svg'
import { ReactComponent as SplashAsset } from 'assets/images/splash.svg'
import styles from 'components/Login/Login.module.css'
import { ContainedButton } from 'components/Shared/ContainedButton'
import { PasswordField } from 'components/Shared/PasswordField'
import { FieldComponent } from 'components/Shared/FieldComponent'
import { MOBILE_USER } from 'components/Shared/AccessControl/Privileges'
import { getUserPrivilege, useAuth } from 'context/auth'
import { login } from 'services'
import { HTTP_401_ERROR } from "Constants";
import { useErrorHandling } from "context/errorHandling";

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .required('form.validation.required'),
  password: Yup.string()
    .required('form.validation.required')
})

export function Login () {
  const { setAuthTokens } = useAuth()

  let history = useHistory()
  const { handleError } = useErrorHandling()

  const { t } = useTranslation()

  const loginHandler = (values) => {
    login({
      username: values.username,
      password: values.password
    }).then(response => {
      const authToken = response.token
      setAuthTokens(authToken)
      const privilege = getUserPrivilege(authToken)
      privilege === MOBILE_USER ? history.push('/driver_onboarding') : history.push('/orders')
    }).catch(error => handleError(error, {
      [HTTP_401_ERROR]: 'notifications.error.login'
    }))
  }

  return <div className={styles.loginWrapper}>
    <div className={styles.loginContainer}>
      <VislineLogo/>
      <h1>{t('form.login.title')}</h1>
      <Formik
        initialValues={{
          username: '',
          password: ''
        }}
        validationSchema={LoginSchema}
        onSubmit={(values) => loginHandler(values)}>
        {() => (
          <Form>
            <div className={styles.loginFormContainer}>
              <FieldComponent
                fieldName={'username'}
                label={t('form.login.username')}
                className={styles.loginFormWidth}
                isLoginPasswordField
              />
              <PasswordField
                fieldName={'password'}
                label={t('form.login.password')}
                className={'loginPasswordField'}
                isLoginPasswordField
              />
              <ContainedButton
                type="submit"
                label={t('form.login.submit')}
                className={styles.loginFormWidth}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
    <div className={styles.truckContainer}>
      <SplashAsset className={styles.splashAsset}/>
      <TruckAsset className={styles.truckAsset}/>
    </div>
  </div>
}
