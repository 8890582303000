import React from 'react'
import styles from 'components/Order/OrderForm/ClientDataForm/ClientDataForm.module.css'
import { useTranslation } from 'react-i18next'
import { FieldComponent } from 'components/Shared/FieldComponent'

export function OrderInfoForm () {
  const { t } = useTranslation()
  return <div className={styles.container}>
    <h2 className={styles.title}>{t('form.order.orderInfo.title')}</h2>
    <FieldComponent fieldName={'comment'} label={'form.order.orderInfo.comment'} multiline/>
  </div>
}
