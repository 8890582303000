import React, { useEffect, useState } from 'react'
import { usePagination } from '@material-ui/lab/Pagination'
import styles from 'components/Shared/Table/pagination/pagination.module.css'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right.svg'

export default function ({ count, rowsPerPage, page, onChangePage }) {
  const [hoveredItem, setHoveredItem] = useState('')
  const [pages, setPages] = useState(1)

  useEffect(() => {
    const pagesNumber = Math.ceil(count / rowsPerPage)
    if (0 === pagesNumber) {
      setPages(1)
    } else {
      setPages(pagesNumber)
    }
  }, [count, rowsPerPage])

  const changePage = (event, value) => {
    if (value > 0 && value <= pages) {
      onChangePage(event, value - 1)
    }
  }

  const { items } = usePagination({
    count: pages,
    siblingCount: 0,
    page: page + 1,
    onChange: changePage
  })

  const onMouseOn = (type) => () => {
    if (isActiveHover(type)) {
      setHoveredItem(type)
    }
  }
  const onMouseOut = () => () => {
    setHoveredItem(null)
  }

  function activeBorder (type) {
    if (isActiveHover(type)) return styles.active
  }

  function activeIcon (type) {
    if (isActiveHover(type) && hoveredItem === type) return styles.icon
  }

  function isActiveHover (type) {
    return (type === 'next' && page !== pages - 1) ||
      (type === 'previous' && page !== 0)
  }

  return (
    <nav>
      <ul className={styles.paginator}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = <div className={styles.shape} {...item}>
              <div className={styles.ellipsis}>...</div>
            </div>
          } else if (type === 'page') {
            children = (
              <div className={styles.shape} {...item}>
                <div className={selected ? styles.selectItem : styles.number}>{page}</div>
              </div>
            )
          } else {
            children = (
              <div onMouseOver={onMouseOn(type)} onMouseOut={onMouseOut()}
                   className={`${styles.shape} ${styles.border} ${activeBorder(type)}`}
                   style={type === 'next' ? { transform: 'rotate(-180deg)' } : {}} type="button" {...item}>
                <ArrowIcon className={`m-auto ${activeIcon(type) ? styles.icon : ''} `}/>
              </div>
            )
          }
          return <li key={index}>{children}</li>
        })}
      </ul>
    </nav>
  )
}


