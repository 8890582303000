import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import classes from './NumberRange.module.css'

const NumberField = ({ value, onChange, placeholder, className, min }) => {
  const { t } = useTranslation()
  return (
    <TextField
      value={value}
      placeholder={t(placeholder)}
      onChange={onChange}
      type='number'
      variant='filled'
      className={className}
      InputProps={{
        disableUnderline: true,
        inputProps: {
          min: min
        }
      }}/>
  )
}

export default ({
  defaultValue,
  onChange,
  minPlaceholder,
  maxPlaceholder,
  numberFieldClassName,
  minValue
}) => {
  const [value, setValue] = useState(defaultValue)

  const changeHandler = index => event => {
    const newValue = [...value]
    newValue[index] = event.target.value
    setValue(newValue)
    onChange(newValue)
  }

  return (
    <div className={classes.root}>
      <NumberField
        value={value[0] || ''}
        onChange={changeHandler(0)}
        min={minValue}
        placeholder={minPlaceholder}
        className={numberFieldClassName}/>
      <span className={classes.separator}>-</span>
      <NumberField
        value={value[1] || ''}
        onChange={changeHandler(1)}
        min={minValue}
        placeholder={maxPlaceholder}
        className={numberFieldClassName}/>
    </div>
  )
}
