const orderStatusOptions = [
  {
    'label': 'order.status.saved',
    'value': 'SAVED'
  },
  {
    'label': 'order.status.sentToDriver',
    'value': 'SENT_TO_DRIVER'
  },
  {
    'label': 'order.status.readByDriver',
    'value': 'READ_BY_DRIVER'
  },
  {
    'label': 'order.status.accepted',
    'value': 'ACCEPTED'
  },
  {
    'label': 'order.status.rejected',
    'value': 'REJECTED'
  },
  {
    'label': 'order.status.loading',
    'value': 'LOADING'
  },
  {
    'label': 'order.status.loaded_not_approved',
    'value': 'LOADED_NOT_APPROVED'
  },
  {
    'label': 'order.status.loaded',
    'value': 'LOADED'
  },
  {
    'label': 'order.status.unloading',
    'value': 'UNLOADING'
  },
  {
    'label': 'order.status.unloaded_not_approved',
    'value': 'UNLOADED_NOT_APPROVED'
  },
  {
    'label': 'order.status.unloaded',
    'value': 'UNLOADED'
  },
  {
    'label': 'order.status.finished',
    'value': 'FINISHED'
  },
  {
    'label': 'order.status.cancelled',
    'value': 'CANCELLED'
  }
]

export default orderStatusOptions
