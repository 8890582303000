import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import styles from 'components/Shared/CrudComponents/SimpleForm.module.css'
import internalStyles from 'components/Users/EditUserForm/EditUserAccountForm.module.css'
import { useHistory } from 'react-router-dom'
import { ContainedButton } from 'components/Shared/ContainedButton'
import { UserAccountFormFields, UserAccountSchema } from 'components/Users/UserAccountFormFields'
import { SuccessDialog } from 'components/Shared/Dialogs/SuccessDialog'
import { USERNAME_CONFLICT } from 'services/errorCodes'
import { editUserAccount } from 'services'
import classNames from 'classnames'
import { HTTP_422_ERROR } from "Constants";
import ApiError from "constants/ApiError";
import { useErrorHandling } from "context/errorHandling";

export function EditUserAccountForm ({ user, inEditView, disableAccountFieldsHelperTextMargin }) {
  const [isSubmitting, setSubmitting] = useState(false)
  const [editedDialogOpen, setEditedDialogOpen] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()
  const { handleError } = useErrorHandling()

  const initialValues = {
    username: user.username,
    password: '',
    passwordConfirmation: '',
  }

  function handleSubmit (values) {
    setSubmitting(true)
    editUserAccount({
      id: user.id,
      ...values
    })
      .then(() => {
          setEditedDialogOpen(true)
        },
      )
      .catch(error => handleError(error, {
        [HTTP_422_ERROR]: error?.response?.data?.errorCode === USERNAME_CONFLICT ?
          'notifications.error.usernameConflict' : ApiError.UnprocessableEntity
      }))
      .finally(() => setSubmitting(false))
  }

  return (
    <div>
      <SuccessDialog open={editedDialogOpen}
                     onClose={() => {
                       setEditedDialogOpen(false)
                       history.push('/users')
                     }} content={t('notifications.success.editedUserCredentials')}/>
      <Formik
        initialValues={initialValues}
        validationSchema={UserAccountSchema}
        onSubmit={handleSubmit}>
        <Form>
          <div className={classNames(styles.container, internalStyles.credentialsContainer)}>
            {inEditView && <h2>{t('form.account.title')}</h2>}
            <UserAccountFormFields
              disableAccountFieldsHelperTextMargin={disableAccountFieldsHelperTextMargin}
            />
            <div className={styles.buttonContainer}>
              <ContainedButton
                label={t('form.account.update')}
                isDisabled={isSubmitting}
              />
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  )
}
