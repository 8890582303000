import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { primaryRed } from 'styles/variables'

const useStyles = makeStyles((theme) => ({
  root: {
    color: primaryRed,
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
}));

export function Loader () {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress color="inherit"/>
    </div>
  )
}
