import React from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@material-ui/pickers'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { useField } from 'formik'
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ErrorMessage } from 'components/Shared/ErrorMessage'
import { hoverColor, primaryRed } from 'styles/variables'
import { momentToISOLocalDate } from 'utils/date'

const materialTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: hoverColor,
        }
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'unset'
        }
      }
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        '&:hover': {
          backgroundColor: hoverColor
        }
      }
    },
    MuiPickersYear: {
      root: {
        '&:focus': {
          color: primaryRed
        }
      }
    },
    MuiTypography: {
      colorPrimary: {
        color: primaryRed
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: primaryRed,
      }
    },
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: hoverColor
        }
      },
      daySelected: {
        backgroundColor: primaryRed,
        '&:hover': {
          backgroundColor: '#CD1C24'
        }
      },
      current: {
        color: primaryRed,
      },
    },
  },
})

const useStyles = makeStyles({
  defaultWidth: {
    width: '310px'
  },
  tableFilterWidth: {
    width: '130px'
  }
})

export function DatePickerFormFieldInline ({
  label,
  format = 'DD-MM-YYYY',
  name,
  skipIcon = false,
  inputClassName,
  onChange = () => {},
  notRequiredTouchedToError
}) {

  const [, meta, helpers] = useField(name)
  const { value, error, touched } = meta
  const { setValue, setTouched } = helpers
  const { t } = useTranslation()

  const labelText = t(label)

  function handleChange (momentValue) {
    const newValue = momentToISOLocalDate(momentValue)
    setValue(newValue)
    onChange(newValue)
    setTouched(name)
  }

  return (
    <ThemeProvider theme={materialTheme}>
      <DatePicker
        error={!!error && (touched || notRequiredTouchedToError)}
        helperText={<ErrorMessage name={name} notRequiredTouchedToError={notRequiredTouchedToError}/>}
        variant="inline"
        inputVariant="filled"
        label={labelText}
        placeholder={labelText}
        value={value}
        onChange={handleChange}
        format={format}
        autoOk
        InputProps={{
          classes: {
            input: inputClassName
          },
          endAdornment: (
            skipIcon ? null : <CalendarIcon/>
          ),
          disableUnderline: true
        }}
      />
    </ThemeProvider>
  )
}

export function DatePickerInline ({
  placeholder,
  value,
  onChange,
  format,
  className = 'defaultWidth',
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <ThemeProvider theme={materialTheme}>
      <DatePicker
        variant="inline"
        disableToolbar
        inputVariant="outlined"
        placeholder={t(placeholder)}
        value={value}
        onChange={onChange}
        format={format}
        autoOk
        className={classes[className]}
        InputProps={{
          endAdornment: (
            value &&
            <IconButton size='small'
                        onClick={(event) => {
                          onChange(null)
                          event.stopPropagation()
                        }}>
              <ClearIcon fontSize="inherit"/>
            </IconButton>
          ),
        }}
      />
    </ThemeProvider>
  )
}
