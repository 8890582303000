import axios from 'axios'
import qs from 'qs'
import { HTTP_401_ERROR, TOKEN_KEY } from 'Constants'

const { REACT_APP_BASE_API } = process.env

const api = axios.create({
  baseURL: REACT_APP_BASE_API,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
})

api.interceptors.request.use(
  config => {
    const token = JSON.parse(localStorage.getItem(TOKEN_KEY));
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return Promise.resolve(config)
  },
  error => {
    return Promise.reject(error)
  }
)

function invalidLoginOrPasswordMessage (error) {
  return error?.response?.data?.message === "Invalid login or password";
}

api.interceptors.response.use(
  response => response.data,
  error => {
    if (error.response && (error.response.status === HTTP_401_ERROR && !invalidLoginOrPasswordMessage(error))) {
      setTimeout(() => {
        localStorage.setItem(TOKEN_KEY, null);
        window.location.reload();
      }, 3000)
    }
    return Promise.reject(error)
  }
)

export default api
