import moment from 'moment'
import {
  momentToISOLocalDate,
  momentToISOLocalDateTime,
  momentToISOLocalTime,
  parseMomentFromLocalDateAndLocalTimeFields
} from 'utils/date'

const emptyDeliveryPointFormValues = {
  fromDate: null,
  fromTime: '',
  toDate: null,
  toTime: '',
  companyName: '',
  cityName: '',
  country: 'PL',
  address: '',
  zipCode: '',
  additionalInfo: '',
  optionalFeatures: [],
  cargoDetails: {
    name: '',
    wrappingType: '',
    quantity: '',
    weight: '',
    dispenser: ''
  }
}

export function newLoadingDeliveryPointFormValues () {
  const fromMoment = moment().hours(8).startOf('hour')
  const toMoment = moment().hours(16).startOf('hour')
  return {
    ...emptyDeliveryPointFormValues,
    fromDate: momentToISOLocalDate(fromMoment),
    fromTime: momentToISOLocalTime(fromMoment),
    toDate: momentToISOLocalDate(toMoment),
    toTime: momentToISOLocalTime(toMoment)
  }
}

export function newUnloadingDeliveryPointFormValues () {
  const fromMoment = moment().add(1, 'day').hours(8).startOf('hour')
  const toMoment = moment().add(1, 'day').hours(12).startOf('hour')
  return {
    ...emptyDeliveryPointFormValues,
    fromDate: momentToISOLocalDate(fromMoment),
    fromTime: momentToISOLocalTime(fromMoment),
    toDate: momentToISOLocalDate(toMoment),
    toTime: momentToISOLocalTime(toMoment)
  }
}

export function deliveryPointWithTimeFrameStartingToday (dp) {
  const startMoment = moment(dp.fromDateTime)
  const newStartMoment = moment()
    .hours(startMoment.hours())
    .minutes(startMoment.minutes())
  return deliveryPointWithTimeFrameStartingAt(dp, newStartMoment)
}

export function deliveryPointWithTimeFrameStartingAt (dp, timeFrameStartMoment) {
  const oldFromMoment = moment(dp.fromDateTime)
  const oldToMoment = moment(dp.toDateTime)
  const frameDuration = oldToMoment.diff(oldFromMoment)
  const newFromMoment = timeFrameStartMoment.clone()
  const newToMoment = timeFrameStartMoment.clone().add(frameDuration)
  return {
    ...dp,
    fromDateTime: momentToISOLocalDateTime(newFromMoment),
    toDateTime: momentToISOLocalDateTime(newToMoment)
  }
}

export function mapDeliveryPointToFormValues (dp) {
  const fromMoment = moment(dp.fromDateTime)
  const toMoment = moment(dp.toDateTime)
  const { cargoDetails } = dp
  const { quantity, weight } = cargoDetails
  return {
    ...dp,
    cargoDetails: {
      ...cargoDetails,
      quantity: quantity === null ? '' : quantity,
      weight: weight === null ? '' : weight
    },
    fromDate: momentToISOLocalDate(fromMoment),
    fromTime: momentToISOLocalTime(fromMoment),
    toDate: momentToISOLocalDate(toMoment),
    toTime: momentToISOLocalTime(toMoment)
  }
}

export function mapFormValuesToRequestBody (values) {
  const { carrier, loadingDeliveryPoints, unloadingDeliveryPoints, ...rest } = values
  return ({
    ...rest,
    deliveryMethod: {
      internal: carrier.isInternal
        ? {
          carrierId: carrier.id,
          carId: carrier.internalCar.carId
        }
        : null,
      external: carrier.isInternal === false
        ? {
          carrierId: carrier.id,
          ...carrier.externalCar
        }
        : null
    },
    dispatcherId: carrier.isInternal ? values.dispatcherId : null,
    loadingDeliveryPoints: loadingDeliveryPoints.map(mapDeliveryPointFormValuesToRequestBody),
    unloadingDeliveryPoints: unloadingDeliveryPoints.map(mapDeliveryPointFormValuesToRequestBody)
  })
}

function mapDeliveryPointFormValuesToRequestBody (dp) {
  const { fromDate, fromTime, toDate, toTime, ...rest } = dp
  return ({
    ...rest,
    fromDateTime: momentToISOLocalDateTime(
      parseMomentFromLocalDateAndLocalTimeFields(fromDate, fromTime)
    ),
    toDateTime: momentToISOLocalDateTime(
      parseMomentFromLocalDateAndLocalTimeFields(toDate, toTime)
    )
  })
}

