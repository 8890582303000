import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg'
import { useField } from 'formik'
import { ErrorMessage } from 'components/Shared/ErrorMessage'

const useStyles = makeStyles({
  defaultWidth: {
    width: '310px'
  }
})

function defaultOnChange () {
}

function defaultOptionLabel (option) {
  return option.name
}

function defaultOptionValue (option) {
  return option.id
}

export function ControlledSelectField ({
  options,
  getOptionLabel = defaultOptionLabel,
  getOptionValue = defaultOptionValue,
  onChange = defaultOnChange,
  label,
  disableClear = false,
  className,
  fieldName,
  inputClassName
}) {
  const [, meta, helpers] = useField(fieldName)
  const { error, touched, value } = meta

  const { setValue, setTouched } = helpers

  const internalStyles = useStyles()
  const { t } = useTranslation()

  const selectedOption = options.find(opt => getOptionValue(opt) === value) || null

  return <Autocomplete
    disableClearable={disableClear}
    options={options}
    getOptionLabel={getOptionLabel}
    className={className || internalStyles.defaultWidth}
    onChange={(_, newSelectedOption) => {
      const newValue = newSelectedOption
        ? getOptionValue(newSelectedOption)
        : null
      setValue(newValue)
      onChange(newValue)
    }}
    value={selectedOption}
    noOptionsText={t('form.select.noOptionsText')}
    popupIcon={<DropdownIcon/>}
    renderInput={params => (
      <TextField
        {...params}
        onBlur={() => setTouched(true)}
        error={!!error && touched}
        helperText={<ErrorMessage name={fieldName}/>}
        className={internalStyles.input}
        label={label}
        variant="filled"
        fullWidth
        InputProps={{
          ...params.InputProps,
          className: `${params.InputProps.className} ${inputClassName}`,
          disableUnderline: true
        }}
      />
    )}
  />
}
