import api from 'services/api'
import { stringifyPageQuery } from 'services/utils'

export const createCar = ({
  brand,
  model,
  registrationNumber,
  trailerRegistrationNumber,
  type,
  capacity,
  loadingSpace,
  optionalFeatures,
  driverId,
  carrierId,
  phoneNumber
}) =>
  api.post('/cars', {
    brand: brand,
    model: model,
    registrationNumber: registrationNumber,
    trailerRegistrationNumber: trailerRegistrationNumber,
    type: type,
    capacity: capacity,
    loadingSpace: loadingSpace,
    optionalFeatures: optionalFeatures,
    driverId: driverId,
    carrierId: carrierId,
    phoneNumber: phoneNumber
  })

export const fetchCars = (params) =>
  api.get(`/cars?${stringifyPageQuery(params)}`)

export const fetchCarsForCarrier = (carrierId) =>
  api.get(`/cars/availableForOrder?carrierId=${carrierId ? carrierId : ''}`)

export const fetchCar = id => api.get(`/cars/${id}`)

export const editCar = ({
  brand,
  model,
  registrationNumber,
  trailerRegistrationNumber,
  type,
  capacity,
  loadingSpace,
  optionalFeatures,
  driverId,
  carrierId,
  id
}) =>
  api.put(`/cars/${id}`, {
    brand,
    model,
    registrationNumber,
    trailerRegistrationNumber,
    type,
    capacity,
    loadingSpace,
    optionalFeatures,
    driverId,
    carrierId
  })

export const deleteCar = (id) => api.post(`/cars/${id}/mark-as-deleted`)

export const fetchCarLocationServicesConnectionStatus = (id) => api.get(
  `/cars/${id}/location-services-connection-status`)
