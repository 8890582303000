import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import TextField from '@material-ui/core/TextField'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ErrorMessage } from 'components/Shared/ErrorMessage'

const useStyles = makeStyles({
  passwordField: {
    width: '310px'
  },
  disableHelperTextMargin: {
    '& > p': {
      marginTop: 0
    }
  },
  loginPasswordField: {
    width: '363px'
  },
  button: {
    color: '#d2d2d2',
    backgroundColor: 'transparent',
    border: 'none',
    padding: '6px',
  }
})

export function PasswordField ({
  fieldName,
  label,
  disableAccountFieldsHelperTextMargin,
  isLoginPasswordField
}) {
  const classes = useStyles()
  const [field, meta] = useField(fieldName)
  const { error, touched } = meta
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => setShowPassword(!showPassword)

  const passwordIcon = <button
    type="button"
    className={classes.button}
    onClick={e => togglePasswordVisibility(e)}
  >
    {showPassword ? <Visibility/> : <VisibilityOff/>}
  </button>

  return (
    <TextField
      error={!!error && touched}
      name={fieldName}
      key={fieldName}
      label={t(label)}
      type={showPassword ? 'text' : 'password'}
      variant="filled"
      helperText={<ErrorMessage name={fieldName}/>}
      InputProps={{
        disableUnderline: true,
        endAdornment: passwordIcon
      }}
      className={
        `${classes.passwordField} 
         ${disableAccountFieldsHelperTextMargin ? classes.disableHelperTextMargin : ''}
         ${isLoginPasswordField && classes.loginPasswordField}
        `
      }
      {...field}
    />
  )
}
