import styles from 'components/Shared/CrudComponents/SimpleForm.module.css'
import React, { useCallback, useEffect, useState } from 'react'
import rolesJson from 'components/Users/roles'
import { useTranslation } from 'react-i18next'
import { FieldComponent } from 'components/Shared/FieldComponent'
import { ControlledSelectField } from 'components/Order/OrderForm/CarrierForm/ControlledSelectField'
import { SelectField } from 'components/Shared/SelectField'
import { fetchAllowedDepartmentsForRole } from 'services/DepartmentService'
import { fetchBranches } from 'services/BranchService'
import { useErrorHandling } from 'context/errorHandling'
import { useFormikContext } from 'formik'

export function UserFormFields ({ children }) {
  const { t } = useTranslation()
  const { setFieldValue, values } = useFormikContext()

  const [branches, setBranches] = useState(null)
  const [departments, setDepartments] = useState(null)

  const { handleError } = useErrorHandling()

  const roles = useCallback(() => rolesJson.map(role => ({
    id: role.id,
    label: t(role.label)
  })), [t])

  const selectedRole = values.role

  useEffect(() => {
    fetchBranches()
      .then(response => {
        setBranches(response)
      })
      .catch(handleError)
  }, [handleError])

  useEffect(() => {
    if (selectedRole) {
      fetchAllowedDepartmentsForRole(selectedRole)
        .then(response => {
          setDepartments(response)
        })
        .catch(handleError)
    }
  }, [selectedRole, setFieldValue, handleError])

  const defaultRole = useCallback(() => {
    const role = roles().find(r => r.id === values.role)
    return role ? role : roles()[0]
  }, [values.role, roles])

  const defaultBranch = useCallback(() => {
    if (branches) {
      const branch = branches.find(b => b.id === values.branchId)
      return branch ? branch : branches[0]
    }
    return { id: values.branchId }
  }, [branches, values.branchId])

  useEffect(() => {
    setFieldValue('role', defaultRole().id)
    const branch = defaultBranch()
    setFieldValue('branchId', branch ? branch.id : null)
  }, [setFieldValue, branches, departments, defaultRole, defaultBranch])

  return branches && roles && departments && <div className={styles.containerRow}>
    <div className={styles.container}>
      <h2>{t('user.details.basicDetails')}</h2>
      <SelectField
        data={roles()}
        label={t('form.user.select.role.title')}
        fieldName={'role'}
        setFieldValue={(fieldName, value) => {
          setFieldValue('departmentId', null)
          setFieldValue(fieldName, value)
        }}
        defaultValue={defaultRole()}
      />

      <FieldComponent fieldName={'firstName'} label={'form.user.firstName'}/>
      <FieldComponent fieldName={'lastName'} label={'form.user.lastName'}/>
      <FieldComponent fieldName={'phoneNumber'} label={'form.user.phoneNumber'}/>
      <FieldComponent fieldName={'email'} label={'form.user.email'}/>
      <FieldComponent fieldName={'personalPhoneNumber'} label={'form.user.personalPhoneNumber'}/>

      <SelectField data={branches}
                   label={t('form.user.select.branch')}
                   fieldName={'branchId'}
                   setFieldValue={setFieldValue}
                   optionLabelKey={'name'}
                   defaultValue={defaultBranch}
      />

      <ControlledSelectField
        options={departments}
        label={t('form.user.select.department')}
        fieldName="departmentId"
        disableClear/>

      <FieldComponent fieldName={'hash'} label={'form.user.hash'}/>
    </div>
    {children}
  </div>
}
