import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { Notification } from 'components/Notification'
import { fetchLoggedUser, subscribeNotifications } from 'services'
import { makeStyles } from '@material-ui/core/styles'
import Slide from '@material-ui/core/Slide'
import useWebsocketConnection from 'context/webSocketConnection'
import styles from 'components/Notification/NotificationDialog/NotificationDialog.module.css'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    left: 80,
    maxWidth: 280,
    pointerEvents: 'auto',
    boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: 8,
    [theme.breakpoints.down(1224)]: {
      left: 10,
    }
  },
  root: {
    pointerEvents: 'none',
    padding: '0 !important',
    color: 'red'
  },
  container: {
    pointerEvents: 'none',
    padding: '0 !important',
    color: 'red'
  }
}))

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export function NotificationDialog () {
  const [isSubscribed, setSubscribed] = useState()
  const [user, setUser] = useState(null)
  const [newNotifications, setNewNotifications] = useState([])
  const [notificationToAdd, setNotificationToAdd] = useState()
  const [notificationToRemove, setNotificationToRemove] = useState()

  const classes = useStyles()
  const isConnected = useWebsocketConnection()

  useEffect(() => {
    if (notificationToRemove) {
      setNotificationToRemove(null)
      setNewNotifications(newNotifications.filter(notification => notification.id !== notificationToRemove.id))
    }
  }, [notificationToRemove, newNotifications])

  useEffect(() => {
    if (notificationToAdd) {
      let timer = setTimeout(() => {
        setNotificationToRemove(notificationToAdd)
        clearTimeout(timer)
      }, 10000)
      setNotificationToAdd(null)
      setNewNotifications([...newNotifications, notificationToAdd])
    }
  }, [notificationToAdd, newNotifications])

  useEffect(() => {
    fetchLoggedUser()
      .then(setUser)
  }, [])

  useEffect(() => {
    if (user && !isSubscribed && isConnected) {
      subscribeNotifications(user.accountId, setNotificationToAdd)
      setSubscribed(true)
    }
  }, [user, isConnected, isSubscribed])

  function onClose (notification) {
    setNotificationToRemove(notification)
  }

  return (
    <div>
      {newNotifications.map((notification, index) => <Dialog
        key={index}
        disableEnforceFocus
        disableScrollLock
        open={!!notification}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        hideBackdrop={true}
        TransitionComponent={Transition}
        classes={classes}
        PaperProps={{
          style: {
            bottom: 10 + index * 150
          }
        }}
        >
        <DialogContent className={'p-1'}>
          {notification &&
          <Notification
            bodyClassName={styles.notification}
            item={notification}
            onNotificationUpdate={onClose}/>}
        </DialogContent>
        </Dialog>
      )}
    </div>
  )
}
