import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { Checkbox } from '@material-ui/core'
import { checkBoxGrey, primaryRed } from 'styles/variables'

export const PrimaryCheckbox = withStyles({
  root: {
    color: checkBoxGrey,
    '&$checked': {
      color: primaryRed
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);
