import styles from 'components/Shared/CrudComponents/SimpleDetails.module.css'
import React from 'react'
import { PrimaryButton } from 'components/Shared/PrimaryButton'

export function TitleBar ({ buttonLabel, title, renderTitle = null, onButtonClick, isButtonDisabled = false }) {
  return <div className={styles.titleBarBackground}>
    <div className={`${styles.contentRow} ${styles.titleRow}`}>
      <div className={styles.title}>
        {renderTitle
          ? renderTitle(title)
          : <h1>{title}</h1>}
      </div>
      <div className={'flex-grow-1'}/>
      <div className={styles.buttonMargin}><PrimaryButton
        onClick={onButtonClick} label={buttonLabel} disabled={isButtonDisabled}/></div>
    </div>
  </div>
}
