import React from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import errorIcon from 'assets/fields/error.svg'
import { ErrorMessage } from 'components/Shared/ErrorMessage'
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";

const useStyles = makeStyles({
  defaultStyles: {
    width: '310px',
    maxWidth: '100%'
  }
})

export function FieldComponent ({
  fieldName,
  label,
  type,
  disabled,
  skipErrorIcon = false,
  inputClassName,
  className,
  multiline,
  rowsMax = 10,
  requireTouchToShowError = true
}) {
  const [field, meta] = useField(fieldName)
  const { error, touched } = meta
  const { t } = useTranslation()
  const classes = useStyles();
  return (
    <TextField
      {...field}
      error={!!error && (!requireTouchToShowError || touched)}
      name={fieldName}
      key={fieldName}
      label={t(label)}
      type={type}
      variant="filled"
      disabled={disabled}
      helperText={<ErrorMessage name={fieldName}/>}
      InputProps={{
        classes: {
          input: inputClassName
        },
        disableUnderline: true,
        endAdornment: !!error && touched && !skipErrorIcon
          ? (
            <InputAdornment position="end">
              <img
                src={errorIcon}
                style={{ height: 20 }} alt="Field warning"/>
            </InputAdornment>
          )
          : null,
      }}
      className={classNames(classes.defaultStyles, className)}
      multiline={multiline}
      rowsMax={rowsMax}
    />
  )
}
