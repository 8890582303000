import React, { useMemo } from 'react'
import { FieldComponent } from 'components/Shared/FieldComponent'
import { ReadOnlyTextField } from 'components/Shared/ReadOnlyTextField'
import { PrimaryCheckbox } from 'components/Shared/PrimaryCheckbox'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { Tooltip } from '@material-ui/core'
import wrappingTypes from 'components/Order/OrderDetails/DeliveryPointCargoApproveForm/wrappingTypes'
import styles from './DeliveryPointCargoApproveForm.module.css'
import { ControlledSelectField } from 'components/Order/OrderForm/CarrierForm/ControlledSelectField'
import {
  getDeliveryPointCargo,
  isLoaded,
  isUnloaded
} from 'components/Order/OrderDetails/DeliveryPointCargoApproveForm/deliveryPointCargoHelper'
import * as Yup from "yup";

export const ApproveCargoSchema = Yup.object().shape({
  cargo: Yup.object().shape({
    quantity: Yup.number().nullable(),
    weight: Yup.number().nullable(),
    wrappingType: Yup.string(),
    responsiblePerson: Yup.string(),
    signed: Yup.boolean()
  })
})

export function DeliveryPointCargoApproveForm ({ order, action }) {
  const { values, setFieldValue } = useFormikContext()
  const expectedDeliveryPointCargo = useMemo(() => getDeliveryPointCargo(order, action), [order, action])
  const { t } = useTranslation()

  return (
    <>
      <div className="row">
        <h3 className="col-6 text-right">
          {isLoaded(action) ? t('form.deliveryPointCargo.title.loaded') : t('form.deliveryPointCargo.title.unloaded')}
        </h3>
        <h3 className="col-6 text-left">{t('form.deliveryPointCargo.title.expected')}</h3>
      </div>
      <DeliveryPointCargoFieldComponentRow
        fieldName={`cargo.quantity`}
        type={'number'}
        label={'form.deliveryPointCargo.quantity'}
        expectedValue={expectedDeliveryPointCargo.quantity}
      />
      <DeliveryPointCargoFieldComponentRow
        fieldName={`cargo.weight`}
        type={'number'}
        label={'form.deliveryPointCargo.weight'}
        expectedValue={expectedDeliveryPointCargo.weight}
      />
      <DeliveryPointCargoWrappingTypeRow
        fieldName={`cargo.wrappingType`}
        label={'form.deliveryPointCargo.wrappingType'}
        expectedValue={expectedDeliveryPointCargo.wrappingType}
      />
      {isUnloaded(action) &&
      <>
        <DeliveryPointCargoFieldComponentRow
          fieldName={`cargo.responsiblePerson`}
          label={'form.deliveryPointCargo.responsiblePerson'}
          expectedValue={expectedDeliveryPointCargo.responsiblePerson}
        />
        <label>
          <PrimaryCheckbox
            name={`cargo.signed`}
            value={true}
            checked={values.cargo?.signed}
            onChange={e => setFieldValue(`cargo.signed`, e.target.checked)}
          />
          {t('form.deliveryPointCargo.signed')}
        </label>
      </>
      }
    </>
  )
}

function DeliveryPointCargoFieldComponentRow ({ fieldName, type = 'text', label, expectedValue }) {
  const { values } = useFormikContext()
  const valuesAreNotEqual = useMemo(() => expectedValue !== get(values, fieldName), [values, expectedValue, fieldName])
  return (
    <DeliveryPointCargoApproveFormRow
      label={label}
      expectedValue={expectedValue}
      valuesAreNotEqual={valuesAreNotEqual}>
      <FieldComponent
        fieldName={fieldName}
        label={label}
        type={type}
        skipErrorIcon
        inputClassName={valuesAreNotEqual ? 'backgroundColorValidation' : ''}
      />
    </DeliveryPointCargoApproveFormRow>
  )
}

function DeliveryPointCargoWrappingTypeRow ({ fieldName, label, expectedValue }) {
  const { values } = useFormikContext()
  const { t } = useTranslation()
  const valuesAreNotEqual = useMemo(() => expectedValue !== get(values, fieldName), [values, expectedValue, fieldName])
  const expectedValueLabel = useMemo(() => (wrappingTypes.find(wt => wt.id === expectedValue)?.label || expectedValue), [expectedValue])
  const options = useMemo(() => wrappingTypes.map(wt => ({
    id: wt.id,
    name: t(wt.label)
  })), [t])

  return (
    <DeliveryPointCargoApproveFormRow
      label={label}
      expectedValue={expectedValueLabel ? t(expectedValueLabel) : expectedValueLabel}
      valuesAreNotEqual={valuesAreNotEqual}>
      <ControlledSelectField options={options}
                             fieldName={fieldName}
                             label={t(label)}
                             disableClear
                             className={'ml-auto ' + styles.fieldWidth}
                             inputClassName={valuesAreNotEqual ? 'backgroundColorValidation' : ''}
      />
    </DeliveryPointCargoApproveFormRow>
  )
}

function DeliveryPointCargoApproveFormRow ({ label, expectedValue, children, valuesAreNotEqual }) {
  const { t } = useTranslation()
  return (
    <div className='row'>
      <div className='col-6 text-right'>
        <Tooltip title={valuesAreNotEqual ? t('form.deliveryPointCargo.notEqualValuesWarning') : ''}
                 placement={'top'}
                 arrow>
          <div className="d-inline-block">
            {children}
          </div>
        </Tooltip>
      </div>
      <div className='col-6 text-left'>
        <ReadOnlyTextField
          clearDisabled
          label={label}
          value={expectedValue}/>
      </div>
    </div>
  )
}
