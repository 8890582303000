import React, { useState } from 'react'
import { useField } from 'formik'
import ChipInput from 'material-ui-chip-input'
import * as Yup from 'yup'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ErrorMessage } from 'components/Shared/ErrorMessage'
import { hoverColor } from 'styles/variables'
import { Chip } from '@material-ui/core'

const invalidEmailMessage = 'form.order.email'
const requiredMessage = 'form.validation.required'
const emailSchema = Yup.string().email(invalidEmailMessage)
const requiredSchema = Yup.string().required(requiredMessage)

const useStyles = makeStyles({
  root: { marginBottom: 20 },
  chipContainer: {
    display: 'block',
    maxWidth: '310px',
    backgroundColor: '#FFF',
    border: '1px solid #e2e2e1',
    borderRadius: '3px',
    padding: '10px',
    minHeight: 50,
    minWidth: 310,
  },
  chip: {
    background: hoverColor,
    textOverflow: 'ellipsis',
    maxWidth: '290px',
    '&:hover': {
      background: hoverColor
    }
  },
  helperText: {
    '& span': {
      bottom: '-42px',
      left: '11px'
    }
  },
  input: {
    padding: '-10px'
  }
})

function MailChip ({ text, className, onDelete, onEdit }) {
  return (
    <Chip
      className={className}
      onClick={() => onEdit()}
      onDelete={onDelete}
      label={text}
    />
  )
}

export function MailField ({ name, placeholder }) {
  const classes = useStyles()

  const [inputValue, setInputValue] = useState('')

  const [, meta, helpers] = useField(name)
  const { value, touched } = meta
  const { setValue, setTouched, setError } = helpers

  function dropEmail (index) {
    const newValue = value.slice(0, index).concat(value.slice(index + 1))
    setValue(newValue)
    if (!requiredSchema.isValidSync(newValue))
      setError(requiredMessage)
  }

  function handleAdd (email) {
    const newValue = [...value, email]
    setValue(newValue)
    setError(null)
    setInputValue('')
  }

  function handleDelete (email, index) {
    dropEmail(index)
  }

  function handleAddTry (email) {
    const isValid = emailSchema.isValidSync(email)
    if (!isValid) {
      setError(invalidEmailMessage)
    }
    return isValid
  }

  function handleEditClick (index) {
    const email = value[index]
    dropEmail(index)
    setInputValue(email)
  }

  function handleInputUpdate (event) {
    // check to prevent form rerender
    if (!touched) {
      setTouched(true)
    }
    setInputValue(event.target.value)
  }

  return <ChipInput
    helperText={<ErrorMessage name={name}/>}
    inputValue={inputValue}
    chipRenderer={({ value, text, isFocused, isDisabled, handleClick, handleDelete, className }, key) =>
      (
        <MailChip
          key={key}
          text={text}
          className={className}
          onEdit={() => handleEditClick(key)}
          onDelete={handleDelete}
        />
      )}
    value={value}
    onBeforeAdd={handleAddTry}
    classes={{
      root: classes.root,
      chipContainer: classes.chipContainer,
      chip: classes.chip,
      helperText: classes.helperText,
      input: classes.input
    }}
    InputProps={{ disableUnderline: true }}
    onAdd={handleAdd}
    onDelete={handleDelete}
    onUpdateInput={handleInputUpdate}
    placeholder={placeholder}
    alwaysShowPlaceholder
    disableUnderline
    fullWidthInput
  />
}
