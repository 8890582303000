import { useTranslation } from 'react-i18next'
import styles from './NotificationContent.module.css'
import React from 'react'
import { markAsRead } from 'services'
import { useErrorHandling } from 'context/errorHandling'

export function RouteNotificationContent ({
  item,
  content,
  additionalInfo,
  onNotificationUpdate,
  action
}) {

  const { t } = useTranslation()
  const { handleError } = useErrorHandling()

  const markRead = () => {
    markAsRead(item.id)
      .then(onNotificationUpdate)
      .catch(handleError)
  }

  const handleAction = () => {
    if (!item.read) markRead()
    action.onClick()
  }

  return <>
    <div>
      <div>
        <span className={styles.place}>{t('notificationsList.place.from')} :</span>
        {content.loadingPlace}
      </div>
      <div>
        <span className={styles.place}>{t('notificationsList.place.to')} :</span>
        {content.unloadingPlace}
      </div>
    </div>
    <div>
      {additionalInfo}
      {action.isShow &&
      <span className={styles.decisionContent}
            onClick={handleAction}>
            &nbsp;{t('notificationsList.acceptReject')}
        </span>
      }
    </div>
  </>
}
