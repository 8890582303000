import React from 'react'
import { FieldArray } from 'formik'
import carOptionalFeatures from 'components/Car/carOptionalFeatures'
import { useTranslation } from 'react-i18next'
import { PrimaryCheckbox } from 'components/Shared/PrimaryCheckbox'
import styles from 'components/Car/CarOptionalFeaturesForm/CarOptionalFeaturesForm.module.css'

export function CarOptionalFeaturesForm ({ values }) {
  const { t } = useTranslation()
  return <FieldArray
    name={`optionalFeatures`}
    render={arrayHelpers => (
      <div className={styles.container}>
        {t('form.car.optionalFeatures.title')}
        {carOptionalFeatures.map(feature => (
          <div key={feature.id}>
            <PrimaryCheckbox
              name="optionalFeatures"
              type="checkbox"
              value={feature.id}
              checked={values.optionalFeatures.includes(feature.id)}
              onChange={e => {
                if (e.target.checked) arrayHelpers.push(feature.id)
                else {
                  const idx = values.optionalFeatures.indexOf(feature.id)
                  arrayHelpers.remove(idx)
                }
              }}
            />
            {t(`form.car.optionalFeatures.${feature.label}`)}
          </div>
        ))}
      </div>
    )}
  />
}

