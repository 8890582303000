import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Loader } from 'components/Shared/Loader'
import {
  deliveryPointWithTimeFrameStartingAt,
  deliveryPointWithTimeFrameStartingToday,
  mapDeliveryPointToFormValues,
  mapFormValuesToRequestBody,
  newLoadingDeliveryPointFormValues,
  OrderForm
} from 'components/Order/OrderForm/OrderForm'
import { SuccessDialog } from 'components/Shared/Dialogs/SuccessDialog'
import { createOrder, fetchOrder } from 'services/OrderService'
import UserRole from 'constants/UserRole'
import { useAuth } from 'context/auth'
import moment from 'moment'
import { HTTP_404_ERROR, HTTP_422_ERROR } from 'Constants'
import { useErrorHandling } from 'context/errorHandling'
import {
  DISPATCHER_NOT_FOUND,
  FORWARDER_NOT_FOUND,
  INVALID_DELIVERY_METHOD,
  INVALID_DELIVERY_POINTS
} from 'services/errorCodes'
import ApiError from 'constants/ApiError'
import { fetchUser } from 'services'
import { useNavigatePrevent } from 'context/navigatePrevent'

function getLoggedInUserByRole (role, auth) {
  return auth.user && auth.user.role === role
    ? auth.user
    : null
}

function getInitialValuesOfNewOrder (auth) {
  const loggedInForwarder = getLoggedInUserByRole(UserRole.Forwarder, auth)
  const loggedInDispatcher = getLoggedInUserByRole(UserRole.Dispatcher, auth)
  const bccEmails = []
  if (loggedInForwarder) {
    bccEmails.push(loggedInForwarder.email)
  }
  if (loggedInDispatcher) {
    bccEmails.push(loggedInDispatcher.email)
  }
  return ({
    referenceId: '',
    loadingDeliveryPoints: [newLoadingDeliveryPointFormValues()],
    unloadingDeliveryPoints: [],
    contractorInfo: {
      name: '',
      emails: [],
      bccEmails: bccEmails
    },
    emailNotificationSettings: {
      language: 'EN',
      frequencySettingsType: 'MORNING_AND_EVENING',
      sendIntervalHours: null
    },
    attachments: [],
    carrier: {
      id: null,
      isInternal: null,
      internalCar: null,
      externalCar: null
    },
    forwarderId: loggedInForwarder?.id || null,
    dispatcherId: loggedInDispatcher?.dispatcherId || null,
    comment: ''
  })
}

function getInitialValuesOfCopiedOrder (auth, order, forwarderOfOrder, dispatcherOfOrder) {
  const {
    readableId, referenceId, deliveryMethod, attachments,
    loadingDeliveryPoints, unloadingDeliveryPoints, forwarderId, dispatcherId, contractorInfo, ...rest
  } = order
  const [offsetLoadingDeliveryPoints, offsetUnloadingDeliveryPoints]
    = offsetDeliveryPointsOfCopiedOrder(loadingDeliveryPoints, unloadingDeliveryPoints)
  const loggedInForwarder = getLoggedInUserByRole(UserRole.Forwarder, auth)
  const loggedInDispatcher = getLoggedInUserByRole(UserRole.Dispatcher, auth)
  let bccEmails = contractorInfo.bccEmails
  let newEmail = null
  if (loggedInForwarder) {
    if (forwarderOfOrder) {
      bccEmails = bccEmails.filter(email => email !== forwarderOfOrder.email)
    }
    newEmail = loggedInForwarder.email
  }
  if (loggedInDispatcher) {
    if (dispatcherOfOrder) {
      bccEmails = bccEmails.filter(email => email !== dispatcherOfOrder.email)
    }
    newEmail = loggedInDispatcher.email
  }
  if (newEmail && !bccEmails.includes(newEmail)) {
    bccEmails = [...bccEmails, newEmail]
  }
  return {
    contractorInfo: {
      ...contractorInfo,
      bccEmails: bccEmails
    },
    attachments: [],
    readableId: '',
    referenceId: '',
    carrier: deliveryMethod.internal
      ? {
        id: deliveryMethod.internal.carrierId,
        isInternal: true,
        internalCar: {
          carId: deliveryMethod.internal.carId,
          driverId: deliveryMethod.internal.driverId
        },
        externalCar: null
      }
      : {
        id: deliveryMethod.external.carrierId,
        isInternal: false,
        externalCar: {
          carrierName: deliveryMethod.external.carrierName,
          carRegistrationNumber: deliveryMethod.external.carRegistrationNumber,
          driverFirstName: deliveryMethod.external.driverFirstName,
          driverLastName: deliveryMethod.external.driverLastName,
          driverPhoneNumber: deliveryMethod.external.driverPhoneNumber
        },
        internalCar: null
      },
    forwarderId: loggedInForwarder?.id || forwarderId,
    dispatcherId: loggedInDispatcher?.id || dispatcherId,
    loadingDeliveryPoints: offsetLoadingDeliveryPoints.map(
      ldp => mapDeliveryPointToFormValues(ldp)),
    unloadingDeliveryPoints: offsetUnloadingDeliveryPoints.map(
      udp => mapDeliveryPointToFormValues(udp)),
    ...rest
  }
}

function offsetDeliveryPointsOfCopiedOrder (
  loadingDeliveryPoints, unloadingDeliveryPoints) {
  const offsetLoadingDeliveryPoints = [], offsetUnloadingDeliveryPoints = []
  let i = 0, j = 0
  for (; i < loadingDeliveryPoints.length && j <
  unloadingDeliveryPoints.length; i++, j++) {
    const ldp = loadingDeliveryPoints[i]
    const udp = unloadingDeliveryPoints[j]
    const loadingStart = moment(ldp.fromDateTime)
    const unloadingStart = moment(udp.fromDateTime)
    const diff = unloadingStart.diff(loadingStart)
    const newLoadingStart = moment()
      .hours(loadingStart.hours())
      .minutes(loadingStart.minutes())
    const newUnloadingStart = newLoadingStart.clone()
      .add(diff)
    const offsetLdp = deliveryPointWithTimeFrameStartingAt(ldp, newLoadingStart)
    const offsetUdp = deliveryPointWithTimeFrameStartingAt(udp,
      newUnloadingStart)
    offsetLoadingDeliveryPoints.push(offsetLdp)
    offsetUnloadingDeliveryPoints.push(offsetUdp)
  }
  for (; i < loadingDeliveryPoints.length; i++) {
    const ldp = loadingDeliveryPoints[i]
    const offsetLdp = deliveryPointWithTimeFrameStartingToday(ldp)
    offsetLoadingDeliveryPoints.push(offsetLdp)
  }
  for (; j < unloadingDeliveryPoints.length; j++) {
    const udp = unloadingDeliveryPoints[j]
    const offsetUdp = deliveryPointWithTimeFrameStartingToday(udp)
    offsetUnloadingDeliveryPoints.push(offsetUdp)
  }
  return [offsetLoadingDeliveryPoints, offsetUnloadingDeliveryPoints]
}

export function CreateOrder (props) {
  const [initialValuesOfCopiedOrder, setInitialValuesOfCopiedOrder] = useState(
    null)
  const [savedOrderId, setSavedOrderId] = useState(null)
  const { t } = useTranslation()
  const history = useHistory()
  const auth = useAuth()
  const initialValuesOfNewOrder = useMemo(
    () => getInitialValuesOfNewOrder(auth), [auth])

  const idOfCopiedOrder = props.location.state?.idOfCopiedOrder
  const { handleError } = useErrorHandling()
  const { setNavigationUnlocked, setNavigationBlocked } = useNavigatePrevent()

  useEffect(() => {
      setNavigationBlocked()
    }, [setNavigationBlocked]
  )

  useEffect(() => {
      if (idOfCopiedOrder) {
        fetchOrder(idOfCopiedOrder)
          .then(order =>
            Promise.all(
              [order.forwarderId, order.dispatcherId]
                .filter(id => id != null)
                .map(id => fetchUser(id)))
              .then(([forwarder, dispatcher]) =>
                ([order, forwarder, dispatcher]))
              .catch(() => ([order]))
          )
          .then(([order, forwarder, dispatcher]) => {
              setInitialValuesOfCopiedOrder(
                getInitialValuesOfCopiedOrder(auth, order, forwarder, dispatcher))
            }
          ).catch(error => handleError(error, {
            [HTTP_404_ERROR]: 'notifications.error.orderNotFound'
          }).then(() => {
            setNavigationUnlocked()
            history.push('/orders')
          })
        )
      }
    }, [idOfCopiedOrder, auth, handleError, history, setNavigationUnlocked]
  )

  function handleSubmit (values) {
    const request = mapFormValuesToRequestBody(values)
    return createOrder(request)
      .then(response => setSavedOrderId(response.id))
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode
        handleError(error, {
          [HTTP_422_ERROR]: errorCode === INVALID_DELIVERY_METHOD ?
            'notifications.error.invalidDeliveryMethod' :
            errorCode === INVALID_DELIVERY_POINTS ?
              'notifications.error.invalidDeliveryPoints' :
              errorCode === FORWARDER_NOT_FOUND ?
                'notifications.error.forwarderNotFound' :
                errorCode === DISPATCHER_NOT_FOUND ?
                  'notifications.error.dispatcherNotFound' :
                  ApiError.UnprocessableEntity
        })
      })
  }

  const initialValues = idOfCopiedOrder
    ? initialValuesOfCopiedOrder
    : initialValuesOfNewOrder
  return (!idOfCopiedOrder || initialValuesOfCopiedOrder ?
      <>
        <SuccessDialog open={!!savedOrderId}
                       onClose={() => {
                         setNavigationUnlocked()
                         history.push(savedOrderId
                           ? `/orders/details/${savedOrderId}`
                           : '/orders')
                       }}
                       content={t('notifications.success.createdOrder')}/>
        <OrderForm initialValues={initialValues}
                   onSubmit={handleSubmit}
                   title={'form.order.create'}>
        </OrderForm>
      </>
      :
      <Loader/>
  )
}
