import { getUserPrivilege, useAuth } from 'context/auth'
import { SUPER_ADMIN } from 'components/Shared/AccessControl/Privileges'

export const AccessControl = ({
  allowedPrivileges = [],
  children,
  renderNoAccess
}) => {
  const { authTokens } = useAuth()
  if (authTokens) {
    allowedPrivileges.push(SUPER_ADMIN)

    const userPrivileges = getUserPrivilege(authTokens)

    if (allowedPrivileges.includes(userPrivileges)) {
      return children
    }
  }

  if (renderNoAccess != null) {
    return renderNoAccess()
  }
  return null
}
