import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styles from 'components/Shared/CrudComponents/SimpleDetails.module.css'
import { Loader } from 'components/Shared/Loader'
import { ReadOnlyTextField } from 'components/Shared/ReadOnlyTextField'
import { TitleBar } from 'components/Shared/CrudComponents/TitleBar'
import { fetchDriver } from 'services'
import { useErrorHandling } from "context/errorHandling";
import { HTTP_404_ERROR } from "Constants";

export function DriverDetails (props) {
  const [driver, setDriver] = useState(null)
  const { handleError } = useErrorHandling()

  const history = useHistory()

  useEffect(() => {
      fetchDriver(props.match.params.id).then(response => {
          setDriver(response)
        },
      ).catch((error) => {
        handleError(error, {
            [HTTP_404_ERROR]: 'notifications.error.driverNotFound'
          }
        ).then(() => history.push('/drivers'))
      })
    }, [props.match.params.id, handleError, history],
  )
  const { t } = useTranslation()

  const editDriver = () => {
    history.push('/drivers/edit/' + props.match.params.id)
  }
  return <div>
    {driver ?
      <div>
        <TitleBar
          buttonLabel={t('driver.details.edit')}
          onButtonClick={editDriver}
          title={driver.firstName + ' ' + driver.lastName}
        />
        <div>
          <div className={styles.contentRowTwoColumn}>
            <div className={styles.detailsColumn}>
              <div className={styles.headerTwoColumn}>{t(
                'driver.details.basicDetails')}</div>
              <div className={styles.detailsContentColumn}>
                <ReadOnlyTextField clearDisabled
                                   label={'driver.details.firstName'} value={driver.firstName}/>
                <ReadOnlyTextField clearDisabled label={'driver.details.lastName'}
                                   value={driver.lastName}/>
                <ReadOnlyTextField clearDisabled
                                   label={'driver.details.phoneNumber'} value={driver.phoneNumber}/>
                <ReadOnlyTextField clearDisabled
                                   label={'driver.details.personalPhoneNumber'} value={driver.personalPhoneNumber}/>
                <ReadOnlyTextField clearDisabled label={'driver.details.address'}
                                   value={driver.address}/>
                <ReadOnlyTextField clearDisabled
                                   label={'driver.details.idCardNumber'} value={driver.idCardNumber}/>
                <ReadOnlyTextField clearDisabled
                                   label={'driver.details.idCardExpirationDate'}
                                   value={driver.idCardExpirationDate}
                                   type={'date'}/>
              </div>
            </div>
            <div className={styles.detailsColumn}>
              <div className={styles.headerTwoColumn}>{t(
                'driver.details.additionalDetails')}</div>
              <div className={styles.detailsContentColumn}>
                <ReadOnlyTextField clearDisabled
                                   label={'driver.details.adrCertificateNumber'}
                                   value={driver.adrCertificateNumber}/>
                <ReadOnlyTextField clearDisabled
                                   label={'driver.details.adrCertificateExpirationDate'}
                                   value={driver.adrCertificateExpirationDate}
                                   type={'date'}/>
                <ReadOnlyTextField clearDisabled
                                   label={'driver.details.driverLicenseNumber'}
                                   value={driver.driverLicenseNumber}/>
                <ReadOnlyTextField clearDisabled
                                   label={'driver.details.driverLicenseCategory'}
                                   value={driver.driverLicenseCategory}/>
                <ReadOnlyTextField clearDisabled
                                   label={'driver.details.driverLicenseExpirationDate'}
                                   value={driver.driverLicenseExpirationDate}
                                   type={'date'}/>
              </div>
            </div>
            <div className={styles.flexHeaderTwoColumn}/>
          </div>
        </div>
      </div>
      :
      <Loader/>
    }
  </div>
}
