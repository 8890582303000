import React from 'react'
import styles from 'components/Contractor/ContractorOrderStatus/ContractorOrderStatus.module.css'
import { StatusItem } from 'components/Contractor/ContractorOrderStatus/StatusItem'

export function ContractorOrderStatus ({ orderStatuses }) {

  return (
    <div className={styles.actions}>
      {orderStatuses.map((row, index) => (
        <StatusItem status={row} key={`${row.type} ${index}`}/>
      ))}
    </div>
  )
}

