import React from 'react'
import { useErrorHandling } from 'context/errorHandling'
import { ErrorDialog } from 'components/Shared/Dialogs/ErrorDialog'
import { useTranslation } from 'react-i18next'

export default () => {
  const { error, clearError } = useErrorHandling()
  const { t } = useTranslation()

  const msg = error ? t(error) : ''

  return (
    <ErrorDialog
      open={error != null}
      content={msg}
      onClose={clearError}/>
  )
}
