import api from 'services/api'
import { stringifyPageQuery } from 'services/utils'
import { client as websocketClient } from 'services/SocketClient'

export const uploadFile = ({ file }) => {
  let formData = new FormData()
  formData.append('file', file)
  return api.post('/order-attachments', formData)
}

export const createOrder = (values) => api.post('/orders', values)

export const editOrder = ({ id, ...values }) => api.put(`/orders/${id}`, values)

export const fetchOrder = (id) => api.get(`/orders/${id}`)

export const sendToDriver = (id) => api.post(`/orders/${id}/actions/send`)

export const fetchOrders = (params) => api.get(`/orders?${stringifyPageQuery(params)}`)

export const getStatusBar = (id) => api.get(`/orders/${id}/progress-bar`)

export const getOrderActions = (id) => api.get(`/orders/${id}/actions`)

export const getOrderAction = (id) => api.get(`/orders/actions/${id}`)

export const fetchOrderToken = (id) => api.get(`/contractor-view/${id}/order-token`)

export const fetchOrderByToken = (token) => api.get(`/contractor-view?orderToken=${token}`)

export const editStatusChange = (id, values) => api.put(`/orders/actions/status-changes/${id}`, values)

export const approveCargo = (id, values) =>
  api.put(`/orders/actions/${id}/approve-cargo`, values)

export const rejectCargo = (id) =>
  api.put(`/orders/actions/${id}/reject-cargo`)

export const getOrderAllowedStatusChanges = (id) => api.get(`/orders/${id}/actions/allowed-status-changes`)

export const getOrderMailingHistory = (id) => api.get(`/orders/${id}/email-send-attempts`)

export const setOrderStatus = (id, newStatus, values) => {
  switch (newStatus.status) {
    case 'SAVED':
      return api.post(`/orders/${id}/actions/save`, { dateTime: values.dateTime })
    case 'SENT_TO_DRIVER':
      return api.post(`/orders/${id}/actions/send`, { dateTime: values.dateTime })
    case 'READ_BY_DRIVER':
      return api.post(`/orders/${id}/actions/read`, { dateTime: values.dateTime })
    case 'ACCEPTED':
      return api.post(`/orders/${id}/actions/accept`, {
        sendEmail: values.sendEmail, dateTime: values.dateTime
      })
    case 'REJECTED':
      return api.post(`/orders/${id}/actions/reject`, { rejectionCause: '', dateTime: values.dateTime })
    case 'LOADING':
      return api.post(`/orders/${id}/actions/loading`, {
        position: newStatus.deliveryPointNumber,
        sendEmail: values.sendEmail,
        dateTime: values.dateTime
      })
    case 'LOADED':
      return api.post(`/orders/${id}/actions/loaded`, {
        position: newStatus.deliveryPointNumber,
        sendEmail: values.sendEmail,
        dateTime: values.dateTime,
        cargo: values.cargo
      })
    case 'UNLOADING':
      return api.post(`/orders/${id}/actions/unloading`, {
        position: newStatus.deliveryPointNumber,
        sendEmail: values.sendEmail,
        dateTime: values.dateTime
      })
    case 'UNLOADED':
      return api.post(`/orders/${id}/actions/unloaded`, {
        position: newStatus.deliveryPointNumber,
        sendEmail: values.sendEmail,
        dateTime: values.dateTime,
        cargo: values.cargo
      })
    case 'FINISHED':
      return api.post(`/orders/${id}/actions/finish`)
    case 'CANCELLED':
      return api.post(`/orders/${id}/actions/cancel`, {
        cancellationCause: values.cancellationCause, sendEmail: values.sendEmail, dateTime: values.dateTime
      })
    default:
      return Promise.reject('Unknown status')
  }
}

export const fetchFinishedOrdersCount = () => api.get('/finished-orders-count')

export const fetchDeliveryPointAddresses = (companyName) => api.get(`/delivery-point-addresses?companyName=${companyName}`)

export const updateComment = (id, values) => api.put(`/orders/${id}/comment`, values)

export const setAttachmentVerifiedAs = (attachmentId, verified) =>
  verified ? api.put(`/order-attachments/${attachmentId}/verified`) : api.put(`/order-attachments/${attachmentId}/not-verified`)

export const setAttachmentContractorAccessAs = (attachmentId, access) => access
  ? api.put(`/order-attachments/${attachmentId}/contractor-access`)
  : api.put(`/order-attachments/${attachmentId}/contractor-access-denied`)

export const setAttachmentDriverAccessAs = (attachmentId, access) => access ? api.put(`/order-attachments/${attachmentId}/driver-access`) : api.put(
  `/order-attachments/${attachmentId}/driver-access-denied`)

export const rejectAttachment = (attachmentId) =>
  api.put(`/order-attachments/${attachmentId}/reject`)

export function subscribeOrderUpdated (callback) {
  const sub = websocketClient.subscribe(`/topic/orderDataUpdated`, () => {
    callback()
  })
  return () => {
    sub.unsubscribe()
  }
}

export function subscribeOrderActionsUpdated (id, callback) {
  const sub = websocketClient.subscribe(`/topic/orderActionUpdate/${id}`, () => {
    callback()
  })
  return () => {
    sub.unsubscribe()
  }
}

export const deleteOrder = id => api.delete(`/orders/${id}`)
