import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/Car/SelectDriverField/SelectDriverField.module.css'
import { useField } from 'formik'
import { ReadOnlyTextField } from 'components/Shared/ReadOnlyTextField'
import { ControlledSelectField } from 'components/Order/OrderForm/CarrierForm/ControlledSelectField'
import { fetchAllDrivers } from 'services'

export function SelectDriverField ({ fieldName }) {
  const [drivers, setDrivers] = useState([])
  const [, meta,] = useField(fieldName)
  const { value } = meta

  useEffect(() => {
    fetchAllDrivers()
      .then(list => setDrivers(list))
  }, [])

  const selectedDriver = useMemo(() => drivers.find(d => d.id === value) || null, [drivers, value])

  const { t } = useTranslation()

  function getLabel (driver) {
    return `${driver.firstName} ${driver.lastName}, ${driver.idCardNumber}`
  }

  return <>
    <ControlledSelectField
      options={drivers}
      label={t('form.car.driver')}
      fieldName={fieldName}
      getOptionLabel={driver => getLabel(driver)}
      className={styles.select}/>
    <ReadOnlyTextField
      variantValue={'filled'}
      value={selectedDriver ? selectedDriver.firstName : ''}
      label={t('form.car.driverCredentials.firstName')}
      disabled
    />
    <ReadOnlyTextField
      variantValue={'filled'}
      value={selectedDriver ? selectedDriver.lastName : ''}
      label={t('form.car.driverCredentials.lastName')}
      disabled
    />
    <ReadOnlyTextField
      variantValue={'filled'}
      value={selectedDriver ? selectedDriver.phoneNumber : ''}
      label={t('form.car.driverCredentials.phoneNumber')}
      disabled
    />
  </>
}
