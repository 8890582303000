import React, { useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import styles from 'components/Order/OrderForm/OrderForm/OrderForm.module.css'
import { MailSettingsForm } from 'components/Order/OrderForm/MailSettingsForm'
import { CargoForm } from 'components/Order/OrderForm/CargoForm'
import { CarrierForm } from 'components/Order/OrderForm/CarrierForm'
import { ClientDataForm } from 'components/Order/OrderForm/ClientDataForm'
import { ResponsiblePersonsForm } from 'components/Order/OrderForm/ResponsiblePersonsForm'
import { Dropzone } from 'components/Shared/Dropzone'
import { OrderInfoForm } from 'components/Order/OrderForm/OrderInfoForm/OrderInfoForm'
import { time } from 'utils/form'
import { ContainedButton } from 'components/Shared/ContainedButton'
import UserRole from 'constants/UserRole'
import { useAuth } from 'context/auth'

const deliveryPointSchema = Yup.object().shape({
  fromDate: Yup.string(),
  fromTime: time(),
  toDate: Yup.string(),
  toTime: time(),
  companyName: Yup.string(),
  cityName: Yup.string()
    .required('form.validation.required'),
  country: Yup.string()
    .required('form.validation.required')
    .nullable(),
  address: Yup.string(),
  zipCode: Yup.string()
    .required('form.validation.required'),
  additionalInfo: Yup.string(),
  optionalFeatures: Yup.array(),
  cargoDetails: Yup.object().shape({
    name: Yup.string(),
    wrappingType: Yup.string(),
    quantity: Yup.number(),
    weight: Yup.number(),
    dispenser: Yup.string()
  })
})

const OrderSchema = Yup.object().shape({
  referenceId: Yup.string(),
  loadingDeliveryPoints: Yup.array()
    .of(deliveryPointSchema)
    .required('form.validation.action.loading'),
  unloadingDeliveryPoints: Yup.array()
    .of(deliveryPointSchema)
    .required('form.validation.action.unloading'),
  contractorInfo: Yup.object().shape({
    name: Yup.string()
      .required('form.validation.required'),
    emails: Yup.array()
      .required('form.validation.required'),
    bccEmails: Yup.array()
  }),
  emailNotificationSettings: Yup.object().shape({
    frequencySettingsType: Yup.string(),
    sendIntervalHours: Yup.string().nullable(),
    language: Yup.string()
  }),
  attachmentsIds: Yup.array()
    .of(Yup.string()),
  carrier: Yup.object({
    id: Yup.string()
      .nullable()
      .required('form.validation.required'),
    isInternal: Yup.bool().nullable(),
    internalCar: Yup.object({
      carId: Yup.string()
        .nullable()
        .required('form.validation.required')
    })
      .nullable()
      .when('isInternal', (isInternal, schema) =>
        isInternal === true
          ? schema.required('form.validation.required')
          : schema
      ),
    externalCar: Yup.object({
      carrierName: Yup.string(),
      carRegistrationNumber: Yup.string().required('form.validation.required'),
      driverFirstName: Yup.string(),
      driverLastName: Yup.string(),
      driverPhoneNumber: Yup.string()
    })
      .nullable()
      .when('isInternal', (isInternal, schema) =>
        isInternal === false
          ? schema.required('form.validation.required')
          : schema
      )
  }),
  forwarderId: Yup.string()
    .nullable()
    .required('form.validation.required'),
  dispatcherId: Yup.string()
    .nullable(),
  comment: Yup.string()
})

const handleAttachmentsUpdate = (updatedFiles, setFieldValue) => {
  setFieldValue('attachments', updatedFiles)
}

const ENTER_KEY_CODE = 13

function onKeyDown (keyEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === ENTER_KEY_CODE) {
    keyEvent.preventDefault()
  }
}

export function OrderForm ({ initialValues, initialAttachments, onSubmit, title }) {
  const [isSubmitting, setSubmitting] = useState(false)
  const { t } = useTranslation()
  const auth = useAuth()

  const setBccEmail = (values, setFieldValue) => (previousPerson, newPerson) => {
    if (newPerson) {
      const rest = previousPerson ?
        values.contractorInfo.bccEmails.filter(email => email !== previousPerson.email) : values.contractorInfo.bccEmails
      setFieldValue('contractorInfo.bccEmails', rest.includes(newPerson.email) ? rest : [...rest, newPerson.email])
    } else if (previousPerson && !newPerson) {
      setFieldValue('contractorInfo.bccEmails', values.contractorInfo.bccEmails.filter(email => email !== previousPerson.email))
    }
  }

  const setDispatcherId = (values, setFieldValue) => (carrierId) => {
    const previousCarrierId = values.carrier.id
    if (!previousCarrierId && !!carrierId) {
      setFieldValue('dispatcherId', auth?.user?.role === UserRole.Dispatcher ? auth.user.id : null)
    }
  }

  const handleSubmit = (values) => {
    setSubmitting(true)
    onSubmit(values)
      .finally(() => setSubmitting(false))
  }

  return <div className={styles.formWrapper}>
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={true}
      enableReinitialize={true}
      validationSchema={OrderSchema}
      onSubmit={handleSubmit}>
      {({ setFieldValue, values }) => (
        <Form onKeyDown={onKeyDown}>
          <div className={styles.title}>
            <h1>{t(title)}</h1>
            <ResponsiblePersonsForm onPersonChanged={setBccEmail(values, setFieldValue)}/>
          </div>
          <div>

          </div>
          <div className={styles.clientSection}>
            <div className={styles.sectionColumn}>
              <ClientDataForm/>
            </div>
            <div className={styles.sectionColumn}>
              <CarrierForm fieldName="carrier"
                           onCarrierSelect={setDispatcherId(values, setFieldValue)}/>
            </div>
            <div className={styles.sectionColumn}>
              <OrderInfoForm/>
            </div>
            <div className={styles.dropdownSection}>
              <h2>{t('form.order.attachmentsTitle')}</h2>
              <Dropzone initialAttachments={initialAttachments}
                        onAttachmentsUpdated={(updated) => handleAttachmentsUpdate(
                          updated, setFieldValue)}/>
            </div>
          </div>
          <div className={styles.divider}/>
          <CargoForm values={values} setFieldValue={setFieldValue}/>
          <MailSettingsForm setFieldValue={setFieldValue} values={values}/>
          <ContainedButton
            type="submit"
            className={styles.submitButton}
            label={t('form.order.submit')}
            isDisabled={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  </div>
}
