import React, { useEffect, useState } from 'react'
import styles from 'components/Order/OrderForm/ResponsiblePersonsForm/ResponsiblePersonsForm.module.css'
import { useTranslation } from 'react-i18next'
import { ControlledSelectField } from 'components/Order/OrderForm/CarrierForm/ControlledSelectField'
import { fetchDispatchers, fetchForwarders } from 'services'
import { useErrorHandling } from 'context/errorHandling'
import { useFormikContext } from "formik";

export function ResponsiblePersonsForm ({ onPersonChanged }) {
  const { t } = useTranslation()
  const [forwarders, setForwarders] = useState(null)
  const [dispatchers, setDispatchers] = useState(null)
  const { values } = useFormikContext()

  const { handleError } = useErrorHandling()

  const isInternalDeliveryMethodSelected = values.carrier.isInternal === true

  function getOptionLabel (user) {
    return `${user.firstName} ${user.lastName}`
  }

  const onChange = (persons, personIdPath) => (personId) => {
    const previousPerson = persons.find(person => person.id === values[personIdPath]);
    const newPerson = persons.find(person => person.id === personId);
    onPersonChanged(previousPerson, newPerson)
  }

  useEffect(() => {
    fetchForwarders()
      .then(forwarders => {
        setForwarders(forwarders)
      })
      .catch(handleError)

    fetchDispatchers()
      .then(dispatchers => {
        setDispatchers(dispatchers)
      })
      .catch(handleError)
  }, [handleError])

  return (forwarders && dispatchers && <div className={styles.containerRow}>
      {isInternalDeliveryMethodSelected &&
      <div className={styles.select}>
        <ControlledSelectField
          options={dispatchers}
          fieldName='dispatcherId'
          label={t('form.order.shipment.dispatcherSelect')}
          getOptionLabel={getOptionLabel}
          onChange={onChange(dispatchers, 'dispatcherId')}/>
      </div>
      }
      <div className={styles.select}>
        <ControlledSelectField
          options={forwarders}
          fieldName='forwarderId'
          label={t('form.order.shipment.forwarderSelect')}
          getOptionLabel={getOptionLabel}
          onChange={onChange(forwarders, 'forwarderId')}/>
      </div>
    </div>
  )
}
