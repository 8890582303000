import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ErrorDialog } from 'components/Shared/Dialogs/ErrorDialog'
import { RelatedCarErrorMessage, RelatedOrdersErrorMessage } from 'components/Shared/Dialogs/DialogContents'
import { deleteDriver, fetchDrivers } from 'services'
import Table, { FilterType } from 'components/Shared/Table'
import { HTTP_404_ERROR } from "Constants";
import { useErrorHandling } from "context/errorHandling";

const columnDefinitions =
  [
    {
      id: 'firstName',
      label: 'driver.driverTable.firstName',
      path: 'firstName',
      sortable: true,
      filterType: FilterType.Text
    },
    {
      id: 'lastName',
      label: 'driver.driverTable.lastName',
      path: 'lastName',
      sortable: true,
      filterType: FilterType.Text
    },
    {
      id: 'phoneNumber',
      label: 'driver.driverTable.phoneNumber',
      path: 'phoneNumber',
      filterType: FilterType.Text
    },
    {
      id: 'personalPhoneNumber',
      label: 'driver.driverTable.personalPhoneNumber',
      path: 'personalPhoneNumber',
      filterType: FilterType.Text
    },
    {
      id: 'address',
      label: 'driver.driverTable.address',
      path: 'address',
      filterType: FilterType.Text
    },
    {
      id: 'idCardNumber',
      label: 'driver.driverTable.idCardNumber',
      path: 'idCardNumber',
      filterType: FilterType.Text
    },
    {
      id: 'idCardExpirationDate',
      label: 'driver.driverTable.idCardExpirationDate',
      path: 'idCardExpirationDate',
      filterType: FilterType.DateRange,
      filterNames: [
        'idCardExpirationDateAfterOrEqual',
        'idCardExpirationDateBeforeOrEqual']
    },
    {
      id: 'adrCardNumber',
      label: 'driver.driverTable.adrCardNumber',
      path: 'adrCertificateNumber',
      filterType: FilterType.Text
    },
    {
      id: 'adrCardExpirationDate',
      label: 'driver.driverTable.adrCardExpirationDate',
      path: 'adrCertificateExpirationDate',
      filterType: FilterType.DateRange,
      filterNames: [
        'adrExpirationDateAfterOrEqual',
        'adrExpirationDateBeforeOrEqual']
    },
    {
      id: 'driverLicenseNumber',
      label: 'driver.driverTable.driverLicenseNumber',
      path: 'driverLicenseNumber',
      filterType: FilterType.Text
    },
    {
      id: 'driverLicenseCategory',
      label: 'driver.driverTable.driverLicenseCategory',
      path: 'driverLicenseCategory',
      filterType: FilterType.Text
    },
    {
      id: 'driverLicenseExpirationDate',
      label: 'driver.driverTable.driverLicenseExpirationDate',
      path: 'driverLicenseExpirationDate',
      filterType: FilterType.DateRange,
      filterNames: [
        'driverLicenseExpirationDateAfterOrEqual',
        'driverLicenseExpirationDateBeforeOrEqual']
    },
    {
      id: 'carRegistrationNumber',
      label: 'driver.driverTable.carRegistrationNumber',
      path: 'carRegistrationNumber',
      filterType: FilterType.Text
    },
  ]

export function DriversTable () {
  let history = useHistory()
  const [relatedOrdersErrors, setRelatedOrdersErrors] = useState([])
  const [relatedCarsError, setRelatedCarsError] = useState()
  const { handleError } = useErrorHandling()

  const addDriver = () => {
    history.push('/drivers/create')
  }

  const showDriverDetails = (row) => {
    history.push('/drivers/details/' + row.id)
  }

  const editDriver = (row) => {
    history.push('/drivers/edit/' + row.id)
  }

  const onDeleteError = (error) => {
    if (error?.response?.data?.data?.ordersId) {
      setRelatedOrdersErrors(error.response.data.data.ordersId)
    } else if (error?.response?.data?.data?.carId) {
      setRelatedCarsError(error.response.data.data.carId)
    } else {
      handleError(error, {
        [HTTP_404_ERROR]: 'notifications.error.delete'
      }).then(() => setRelatedOrdersErrors([]))
    }
  }

  return (
    <>
      <Table title={'driver.driverTable.title'}
             addButton={{
               label: 'driver.driverTable.addButtonLabel',
               onClick: addDriver
             }}
             fetchData={fetchDrivers}
             columns={columnDefinitions}
             tableKey={'drivers'}
             onRowClick={showDriverDetails}
             menu={{
               edit: {
                 onClick: editDriver
               },
               delete: {
                 onDelete: deleteDriver,
                 onDeleteError: onDeleteError,
                 deleteDialog: {
                   content: 'driver.driverTable.deleteDialog.content',
                   title: 'driver.driverTable.deleteDialog.title'
                 }
               }
             }}/>
      <ErrorDialog
        open={!!relatedOrdersErrors && relatedOrdersErrors.length > 0}
        onClose={() => {
          setRelatedOrdersErrors([])
        }}
        content={<RelatedOrdersErrorMessage idList={relatedOrdersErrors}/>}/>
      <ErrorDialog open={!!relatedCarsError}
                   onClose={() => {
                     setRelatedCarsError(null)
                   }}
                   content={<RelatedCarErrorMessage id={relatedCarsError}/>}/>
    </>
  )

}

