import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { fetchLoggedUser } from 'services'
import { UserDetails } from 'components/Users/Details/UserDetails'
import { TitleBar } from 'components/Shared/CrudComponents/TitleBar'
import { useAuth } from 'context/auth'

export function MyProfile () {
  const { setAuthTokens } = useAuth()
  const history = useHistory()
  const { t } = useTranslation()

  const logout = () => {
    setAuthTokens(null)
    history.push('/login')
  }

  const getTitle = (user) => (
    <TitleBar
      buttonLabel={t('menu.logout')}
      onButtonClick={logout}
      title={user.firstName + ' ' + user.lastName}
    />
  )

  return <UserDetails
    disableAccountFieldsHelperTextMargin
    fetchData={fetchLoggedUser}
    title={getTitle}
    showPasswordChangeForm
  />
}
