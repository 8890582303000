import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {useHistory} from "react-router-dom";
import {WarningDialog} from "../components/Shared/Dialogs/WarningDialog";

const NavigatePreventContext = React.createContext({
    setNavigationBlocked: () => {},
    setNavigationUnlocked: () => {}
})

export function NavigatePreventProvider({children}) {
    const [unlockedNavigation, setUnlockedNavigation] = useState(true)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [target, setTarget] = useState()

    const unblockHandle = useRef();

    const history = useHistory()

    useEffect(() => {
        unblockHandle.current = history.block(prompt => {
            if (!unlockedNavigation) {
                setTarget(prompt.pathname)
                setDialogOpen(true)
            }
            return unlockedNavigation
        })
        return function () {
            unblockHandle.current && unblockHandle.current()
        }
    })

    const setNavigationBlocked = useCallback(() => {
        setUnlockedNavigation(false)
    }, [])

    const setNavigationUnlocked = useCallback(() => {
        setUnlockedNavigation(true)
        unblockHandle.current && unblockHandle.current()
    }, [])

    const closeDialog = (result) => {
        if (result) {
            setNavigationUnlocked()
            unblockHandle.current()
            history.push(target)
            setTarget(null)
            setDialogOpen(false)
        } else {
            setDialogOpen(false)
        }
    }

    const value = {
        setNavigationBlocked: setNavigationBlocked,
        setNavigationUnlocked: setNavigationUnlocked
    }

    return (
        <NavigatePreventContext.Provider value={value}>
            <WarningDialog open={dialogOpen}
                           onClose={closeDialog}
                           title={'notifications.warnings.exitForm.title'}
                           content={'notifications.warnings.exitForm.message'}
            />
            {children}
        </NavigatePreventContext.Provider>
    )
}

export function useNavigatePrevent() {
    const {setNavigationBlocked, setNavigationUnlocked} = useContext(NavigatePreventContext)

    return {setNavigationBlocked, setNavigationUnlocked}
}
