import styles from 'components/Order/OrderDetails/StatusBar/StatusBar.module.css'
import React from 'react'

export const StatusCaptions = ({ status, t }) => {
  let inProgressStyle = 'notDoneCaption'
  let doneStyle = 'doneCaption'

  switch (status.status) {
    case 'TO_DO': {
      inProgressStyle = 'notDoneCaption'
      doneStyle = 'notDoneCaption'
      break
    }
    case 'IN_PROGRESS': {
      inProgressStyle = 'doneCaption'
      doneStyle = 'notDoneCaption'
      break
    }
    case 'DONE': {
      inProgressStyle = 'doneCaption'
      doneStyle = 'doneCaption'
      break
    }
    default:
      break
  }

  return (
    <>
      <div className={styles[inProgressStyle]}>
        {`${t('order.details.progressBar.' + status.type + '.inProgress')} ${status.positionInOrder}`}
      </div>
      <div className={styles[doneStyle]}>
        {`${t('order.details.progressBar.' + status.type + '.done')} ${status.positionInOrder}`}
      </div>
    </>
  )
}
