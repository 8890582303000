import React, { useMemo } from 'react'
import { COUNTRY_CODES } from 'constants/countries.js'
import { useTranslation } from 'react-i18next'
import { FieldComponent } from 'components/Shared/FieldComponent'
import styles from 'components/Order/OrderForm/ShipmentDetailsForm/ShipmentDetailsForm.module.css'
import { ControlledSelectField } from 'components/Order/OrderForm/CarrierForm/ControlledSelectField'
import CompanyAutocompleteField from './CompanyAutocompleteField'
import { DatePickerFormFieldInline } from 'components/Shared/DatePickerInline'
import { useField } from 'formik'
import { momentToISOLocalDate } from 'utils/date'
import moment from 'moment'

export function DestinationForm ({ name }) {
  const [, { value }, { setValue }] = useField(name)
  const { t } = useTranslation()

  const countrySelectItems = useMemo(() =>
      COUNTRY_CODES
        .map(code => ({ id: code, label: t(`countries.${code}`) }))
        .sort((i1, i2) => i1.label.localeCompare(i2.label)),
    [t])

  function handleCompanySelected (addr) {
    setValue({
      ...value,
      ...addr
    })
  }

  function handleFromDateSelected (newFromDate) {
    const { fromDate, toDate } = value
    const fromMoment = moment(fromDate)
    const toMoment = moment(toDate)
    const newFromMoment = moment(newFromDate)
    const newDaysDiff = toMoment.diff(newFromMoment, 'days')
    if (newDaysDiff < 0) {
      const daysDiff = toMoment.diff(fromMoment, 'days')
      const newToMoment = newFromMoment.clone().add(daysDiff, 'days')
      setValue({
        ...value,
        fromDate: newFromDate,
        toDate: momentToISOLocalDate(newToMoment)
      })
    }
  }

  function handleToDateSelected (newToDate) {
    const { fromDate, toDate } = value
    const fromMoment = moment(fromDate)
    const toMoment = moment(toDate)
    const newToMoment = moment(newToDate)
    const newDaysDiff = newToMoment.diff(fromMoment, 'days')
    if (newDaysDiff < 0) {
      const daysDiff = toMoment.diff(fromMoment, 'days')
      const newFromMoment = newToMoment.clone().subtract(daysDiff, 'days')
      setValue({
        ...value,
        fromDate: momentToISOLocalDate(newFromMoment),
        toDate: newToDate
      })
    }
  }

  return <div className={styles.containerColumn}>
    <CompanyAutocompleteField
      onCompanySelected={handleCompanySelected}
      fieldName={`${name}.companyName`}
      label='form.order.cargo.companyName'
    />

    <div className={styles.dateTimeFieldsContainer}>
      <DatePickerFormFieldInline
        name={`${name}.fromDate`}
        onChange={handleFromDateSelected}
        label={'form.order.cargo.fromDate'}
        inputClassName={styles.px4}
        skipIcon/>
      <div className={styles.fieldHorizontalSeparator}/>
      <FieldComponent
        fieldName={`${name}.fromTime`}
        label={'form.order.cargo.fromTime'}
        skipErrorIcon
        className={styles.cargoTimeField}
        inputClassName={styles.px4}
      />
      <div className={styles.fieldHorizontalSeparator}/>
      <DatePickerFormFieldInline
        name={`${name}.toDate`}
        onChange={handleToDateSelected}
        label={'form.order.cargo.toDate'}
        inputClassName={styles.px4}
        skipIcon/>
      <div className={styles.fieldHorizontalSeparator}/>
      <FieldComponent
        fieldName={`${name}.toTime`}
        label={'form.order.cargo.toTime'}
        skipErrorIcon
        className={styles.cargoTimeField}
        inputClassName={styles.px4}
      />
    </div>

    <FieldComponent
      fieldName={`${name}.address`}
      label={'form.order.cargo.address'}
    />
    <FieldComponent
      fieldName={`${name}.cityName`}
      label={'form.order.cargo.city'}
    />
    <div className={styles.containerRow}>
      <ControlledSelectField
        options={countrySelectItems}
        getOptionLabel={item => item.label}
        label={t('form.order.cargo.country')}
        fieldName={`${name}.country`}
        disableClear
        className={styles.countrySelect}
      />
      <FieldComponent
        fieldName={`${name}.zipCode`}
        label={'form.order.cargo.zipCode'}
        className={styles.cargoZipCodeField}
      />
    </div>
  </div>
}
