import React from 'react'
import styles from 'components/Contractor/ContractorOrderStatus/ContractorOrderStatus.module.css'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ReactComponent as UpIcon } from 'assets/icons/up.svg'
import { ReactComponent as DetailsIcon } from 'assets/icons/loupe.svg'
import { Tooltip } from '@material-ui/core'

export function StatusItem ({ status }) {

  const { t } = useTranslation()

  const CancellationStatusLabel = ({ cancellationCause, children }) => {
    return cancellationCause ? <Tooltip title={cancellationCause} arrow placement="top">
      <div>
        {children}
        <DetailsIcon width={32} height={32}/>
      </div>
    </Tooltip> : children
  }

  const mapActionTypesToStatusLabels = (param) => {
    const positionSuffix = param.type !== 'CONFIRMATION' ?
      `${param.positionInOrder === null ? '' : param.positionInOrder}`
      : ''

    const locationSuffix = param.type !== 'CONFIRMATION' &&
    param.deliveryPointLocation !== null ?
      `(${param.deliveryPointLocation.country} ${param.deliveryPointLocation.zipCode})`
      : ''

    switch (param.type) {
      case 'CONFIRMATION': {
        return t('contractor.statusBar.confirmed')
      }
      case 'LOADED': {
        return `${t('contractor.statusBar.loaded')} ${positionSuffix} ${locationSuffix}`
      }
      case 'ON_LOADING': {
        return `${t('contractor.statusBar.onLoading')} ${positionSuffix} ${locationSuffix}`
      }
      case 'UNLOADED': {
        return `${t('contractor.statusBar.unloaded')} ${positionSuffix} ${locationSuffix}`
      }
      case 'ON_UNLOADING': {
        return `${t('contractor.statusBar.onUnloading')} ${positionSuffix} ${locationSuffix}`
      }
      case 'IN_TRANSIT': {
        const distanceSuffix = param.distanceKm ? `${t('contractor.statusBar.distanceFirstPart', { distance: param.distanceKm })} ` : ''
        return `${t('contractor.statusBar.inTransit')}${distanceSuffix}`
      }
      case 'CANCELLATION': {
        return <CancellationStatusLabel cancellationCause={param.cancellationCause}>
          {t('contractor.statusBar.cancellation')}
        </CancellationStatusLabel>
      }
      default: {
        return ''
      }
    }
  }

  const mapStatusBarItemToClassName = (param) => {
    switch (param.status) {
      case 'LAST_COMPLETED': {
        return styles.bubbleLastCompleted
      }
      case 'DONE': {
        return styles.bubbleDone
      }
      case 'TO_DO': {
        return styles.bubbleToDo
      }
      default: {
        return ''
      }
    }
  }

  function inTransitElement () {
    switch (status.status) {
      case 'LAST_COMPLETED': {
        return <div className={styles.completedArrowUp}><UpIcon width={44} height={44}/></div>
      }
      case 'DONE': {
        return <div className={styles.arrowUp}><UpIcon width={18} height={18}/></div>
      }
      case 'TO_DO': {
        return <div className={styles.arrowUp}><UpIcon className={styles.inactiveIcon} width={18} height={18}/></div>
      }
      default: {
        return ''
      }
    }

  }

  function getStatusIcon () {
    return <div className={mapStatusBarItemToClassName(status)}>
      <div className={styles.joiningLine}/>
      {status.status === 'LAST_COMPLETED' && <div className={styles.joiningLine}/>}
      {status.type === 'IN_TRANSIT' && inTransitElement()}
    </div>

  }

  return (
    <div className={status.status === 'LAST_COMPLETED' ? styles.rowWrapperLastCompleted : styles.rowWrapper}>
      {getStatusIcon()}
      <div className={styles.action}>
        {status.dateTime ?
          <>
            <div className={styles.actionCell}>
              {moment(status.dateTime).format('DD.MM.YYYY')}
            </div>
            < div className={styles.actionCell}>
              {moment(status.dateTime).format('HH:mm')}
            </div>
          </>
          : ''}
        <div className={styles.actionTypeCell}>
          {mapActionTypesToStatusLabels(status)}
        </div>
      </div>
    </div>
  )
}
