import React from 'react'
import { Field, getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import styles from 'components/Shared/ErrorMessage/ErrorMessage.module.css'

export function ErrorMessage ({ name, notRequiredTouchedToError }) {
  const { t } = useTranslation()
  return <Field
    name={name}
  >{({ form }) => {

    const error = getIn(form.errors, name)
    const touch = getIn(form.touched, name)
    const msg = (notRequiredTouchedToError || touch) && error ? t(error) : ' '

    return <span className={styles.error}>{msg}</span>
  }}
  </Field>
}
