import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { approveCargo, fetchOrder, getOrderAction, rejectCargo } from 'services'
import { ApproveRejectCargoDialog } from 'components/Order/OrderDetails/HistoryTabs/ApproveRejectCargoDialog'
import { useErrorHandling } from 'context/errorHandling'
import { HTTP_422_ERROR } from 'Constants'
import { INVALID_STATUS_CHANGE } from 'services/errorCodes'
import ApiError from 'constants/ApiError'
import { NotificationBody } from 'components/Notification/NotificationBody/NotificationBody'
import { RouteNotificationContent } from 'components/Notification/NotificationContent/RouteNotificationContent'

export default function OrderWaitingForApproveNotification ({ item, content, onNotificationUpdate, bodyClassName }) {
  const [order, setOrder] = useState(null)
  const [action, setAction] = useState(null)

  const { handleError } = useErrorHandling()
  const { t } = useTranslation()

  const fetchAction = useCallback(() => {
    getOrderAction(content.orderStatusChangeId)
      .then(setAction)
      .catch(() => {})
  }, [content.orderStatusChangeId])

  useEffect(() => {
    if (!action) {
      fetchAction()
    }
  }, [content, fetchAction, action])

  const decideOnCargoAction = () => {
    fetchOrder(content.orderId)
      .then(setOrder)
      .catch(handleError)
  }

  const onDialogClose = () => {
    setOrder(null)
  }

  const onCargoRejectAction = () => {
    onDialogClose()
    rejectCargo(content.orderStatusChangeId)
      .then((action) => {
        setAction(action)
      })
      .catch(error =>
        handleError(error, {
          [HTTP_422_ERROR]: error?.response?.data?.errorCode === INVALID_STATUS_CHANGE ?
            'notifications.error.invalidStatusChange' : ApiError.UnprocessableEntity
        })
      )
  }

  const onCargoApproveAction = values => {
    onDialogClose()
    if (values) {
      const approveCargoParams = {
        ...values,
        emailSendRequested: true
      }
      approveCargo(action.id, approveCargoParams)
        .then(() => {
          fetchAction()
        })
        .catch(error =>
          handleError(error, {
            [HTTP_422_ERROR]: error?.response?.data?.errorCode === INVALID_STATUS_CHANGE ?
              'notifications.error.invalidStatusChange' : ApiError.UnprocessableEntity
          })
        )
    }
  }

  const deliveryPointData = {
    dpLocation: content.deliveryPointLocation,
    quantity: content.quantity,
    wrappingType: t(`order.details.history.actions.wrappingTypes.${content.wrappingType}`),
    weight: content.weight
  }

  const labels = useMemo(() => {
    switch (content.status) {
      case 'LOADED' :
        return {
          title: 'notificationsList.orderWaitingForApprove.loading.title',
          content: 'notificationsList.orderWaitingForApprove.loading.content'
        }
      case 'UNLOADED' :
        return {
          title: 'notificationsList.orderWaitingForApprove.unloading.title',
          content: 'notificationsList.orderWaitingForApprove.unloading.content'
        }
      default:
        return { title: '', content: '' }
    }
  }, [content.status])

  const dialogHeader = `${order?.readableId} / ${order?.referenceId} / ${content.registrationNumber} / 
  ${content.loadingPlace} ➔ ${content.unloadingPlace}`

  return <>
    {action && order && <ApproveRejectCargoDialog order={order}
                                                  action={action}
                                                  onClose={onDialogClose}
                                                  onApprove={onCargoApproveAction}
                                                  onReject={onCargoRejectAction}
                                                  renderHeader={dialogHeader}/>}
    <NotificationBody title={labels.title}
                      item={item}
                      footerLeftContent={content.registrationNumber}
                      onNotificationUpdate={onNotificationUpdate}
                      bodyClassName={bodyClassName}>
      <RouteNotificationContent item={item}
                                content={content}
                                additionalInfo={t(labels.content, deliveryPointData)}
                                onNotificationUpdate={onNotificationUpdate}
                                action={{
                                  isShow: action?.approved === null,
                                  onClick: decideOnCargoAction
                                }}/>
    </NotificationBody>
  </>
}
