import React, { useRef, useState } from 'react'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import styles from 'components/Order/CommentEdit/CommentEdit.module.css'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { PrimaryButton } from 'components/Shared/PrimaryButton'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { FieldComponent } from 'components/Shared/FieldComponent'
import * as Yup from 'yup'
import { triggerFormSubmit } from 'utils/utils'
import { updateComment } from 'services'
import { useErrorHandling } from "context/errorHandling";

const CommentSchema = Yup.object().shape({
  comment: Yup.string()
})

export function CommentEdit ({ row, onSuccess, visible }) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const formRef = useRef()
  const { handleError } = useErrorHandling()

  const initialFormValues = {
    comment: row.comment ? row.comment : ''
  }

  const handleClickOpen = (e) => {
    setOpen(true)
    e.stopPropagation()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClick = e => {//Prevents opening order details
    e.stopPropagation()
  }

  function handleFormSubmit (values) {
    updateComment(row.id, values).then(() => {
      handleClose()
      onSuccess()
    }).catch(handleError)
  }

  return <div>
    <EditIcon className={styles.icon} onClick={handleClickOpen}
              style={{visibility: visible? 'visible' : 'hidden'}}/>
    <Dialog open={open} onClose={handleClose} onClick={handleClick}
            aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t(
        'form.order.orderInfo.editComment')}</DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={initialFormValues}
          enableReinitialize
          validationSchema={CommentSchema}
          onSubmit={values => handleFormSubmit(values)}>
          <Form>
            <FieldComponent fieldName='comment'
                            label='form.order.orderInfo.comment' multiline/>
          </Form>
        </Formik>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          className={styles.dialogButton}
          onClick={triggerFormSubmit(formRef)}
          label={'form.order.orderInfo.save'}
        />
      </DialogActions>
    </Dialog>
  </div>
}
