import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Dropdown } from 'assets/icons/dropdown.svg'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

const LANGUAGES = ['pl', 'en', 'de']

export function LanguageSelectField () {
  const languageKey = 'Language'
  const [languageMenuOpenElement, setLanguageMenuOpenElement] = React.useState(null)

  const openLangMenu = (event) => {
    setLanguageMenuOpenElement(event.currentTarget)
  }

  const closeLangMenu = () => {
    setLanguageMenuOpenElement(null)
  }

  const { i18n } = useTranslation()

  useEffect(() => {
    let language = localStorage.getItem(languageKey)
    if (language) {
      i18n.changeLanguage(language)
    } else {
      i18n.changeLanguage('pl')
      localStorage.setItem(languageKey, 'pl')
    }
  }, [i18n])

  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
    localStorage.setItem(languageKey, lng)
  }

  return (
    <div className='my-2'>
      <Button aria-controls="simple-menu" aria-haspopup="true"
              onClick={openLangMenu}>
        {i18n.language}
        <Dropdown/>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={languageMenuOpenElement}
        keepMounted
        open={Boolean(languageMenuOpenElement)}
        onClose={closeLangMenu}
      >
        {LANGUAGES.map(lang =>
          <MenuItem key={lang}
                    className="p-0">
            <Button fullWidth
                    className="p-2"
                    onClick={() => {
                      changeLanguage(lang)
                      closeLangMenu()
                    }}>
              {lang}
            </Button>
          </MenuItem>)}
      </Menu>
    </div>
  )
}


