import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Loader } from 'components/Shared/Loader'
import { CarForm, mapCarToFormValues } from 'components/Car/CarForm'
import { SuccessDialog } from 'components/Shared/Dialogs/SuccessDialog'
import { REGISTRATION_NUMBER_CONFLICT } from 'services/errorCodes'
import { editCar, fetchCar } from 'services/CarService'
import { HTTP_404_ERROR, HTTP_422_ERROR } from 'Constants'
import ApiError from 'constants/ApiError'
import { useErrorHandling } from 'context/errorHandling'
import { useNavigatePrevent } from 'context/navigatePrevent'

export function EditCarForm (props) {
  const [updatedDialogOpen, setUpdatedDialogOpen] = useState(false)
  const [car, setCar] = useState(null)

  const { handleError } = useErrorHandling()
  const history = useHistory()
  const { setNavigationUnlocked, setNavigationBlocked } = useNavigatePrevent()

  useEffect(() => {
      setNavigationBlocked()
    }, [setNavigationBlocked]
  )

  useEffect(() => {
      fetchCar(props.match.params.id).then(response => {
          setCar(response)
        }
      ).catch(error => handleError(error, {
        [HTTP_404_ERROR]: 'notifications.error.carNotFound'
      }).then(() => {
        setNavigationUnlocked()
        history.push('/cars')
      }))
    }, [props.match.params.id, handleError, history, setNavigationUnlocked]
  )

  const handleSubmit = (values) =>
    editCar({
      id: car.id,
      ...values
    })
      .then(() => setUpdatedDialogOpen(true))
      .catch(error => handleError(error, {
        [HTTP_422_ERROR]: error?.response?.data?.errorCode === REGISTRATION_NUMBER_CONFLICT ?
          'notifications.error.carRegistrationNumberConflict' : ApiError.UnprocessableEntity
      }))

  return <div>
    <SuccessDialog open={updatedDialogOpen}
                   onClose={() => {
                     setUpdatedDialogOpen(false)
                     setNavigationUnlocked()
                     history.push('/cars')
                   }} content={'notifications.success.updatedCar'}/>
    {car ?
      <CarForm
        translateTitleLabel={'editCarTitle'}
        initialValues={mapCarToFormValues(car)}
        onSubmit={handleSubmit}/>
      :
      <Loader/>
    }
  </div>

}
