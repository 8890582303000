import React, { useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import styles from 'components/Shared/CrudComponents/SimpleForm.module.css'
import { useTranslation } from 'react-i18next'
import { FieldComponent } from 'components/Shared/FieldComponent'
import { DatePickerFormFieldInline } from 'components/Shared/DatePickerInline'
import { TitleBar } from '../Shared/CrudComponents/TitleBar'

export const driverSchema = Yup.object().shape({
  firstName: Yup.string().required('form.validation.required'),
  lastName: Yup.string().required('form.validation.required'),
  address: Yup.string().required('form.validation.required'),
  phoneNumber: Yup.string().required('form.validation.required'),
  personalPhoneNumber: Yup.string().required('form.validation.required'),
  idCardNumber: Yup.string().required('form.validation.required'),
  idCardExpirationDate: Yup.string().nullable().required('form.validation.required'),
  driverLicenseNumber: Yup.string().required('form.validation.required'),
  driverLicenseCategory: Yup.string().required('form.validation.required'),
  driverLicenseExpirationDate: Yup.string().nullable().required('form.validation.required'),
  adrCertificateNumber: Yup.string().nullable(),
  adrCertificateExpirationDate: Yup.string().nullable(),
})

export function DriverForm ({ title, initialValues, validationSchema, onSubmit, children }) {
  const [isSubmitting, setSubmitting] = useState(false)
  const { t } = useTranslation()

  const handleSubmit = (values) => {
    setSubmitting(true)
    onSubmit(values)
      .finally(() => setSubmitting(false))
  }

  return <Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}>
    <Form className={'w-100'}>
      <TitleBar
        buttonLabel={t('form.driver.submit')}
        title={title}
        isButtonDisabled={isSubmitting}/>
      <div className={styles.containerRow}>
        <div className={styles.container}>
          <h2>{t('form.driver.basicDetails')}</h2>
          <FieldComponent fieldName={'firstName'}
                          label={'form.driver.firstName'}/>
          <FieldComponent fieldName={'lastName'} label={'form.driver.lastName'}/>
          <FieldComponent fieldName={'personalPhoneNumber'}
                          label={'form.driver.personalPhoneNumber'}/>
          <FieldComponent fieldName={'phoneNumber'}
                          label={'form.driver.phoneNumber'}/>
          <FieldComponent fieldName={'address'} label={'form.driver.address'}/>
          <FieldComponent fieldName={'idCardNumber'}
                          label={'form.driver.idCardNumber'}/>
          <DatePickerFormFieldInline
            label={'form.driver.idCardExpirationDate'}
            name={'idCardExpirationDate'}
          />
        </div>
        <div className={styles.container}>
          <h2>{t('form.driver.additionalDetails')}</h2>
          <FieldComponent fieldName={'adrCertificateNumber'}
                          label={'form.driver.adrCertificateNumber'}/>
          <DatePickerFormFieldInline
            label={'form.driver.adrCertificateExpirationDate'}
            name={'adrCertificateExpirationDate'}
          />
          <FieldComponent fieldName={'driverLicenseNumber'}
                          label={'form.driver.driverLicenseNumber'}/>
          <FieldComponent fieldName={'driverLicenseCategory'}
                          label={'form.driver.driverLicenseCategory'}/>
          <DatePickerFormFieldInline
            label={'form.driver.driverLicenseExpirationDate'}
            name={'driverLicenseExpirationDate'}
          />
        </div>
        {children}
      </div>
    </Form>
  </Formik>
}
