import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReadOnlyTextField } from 'components/Shared/ReadOnlyTextField'
import { fetchCarrier } from 'services/CarrierService'
import { fetchCar } from 'services/CarService'
import { fetchDriver } from 'services'
import { useErrorHandling } from "context/errorHandling";
import { HTTP_404_ERROR } from "Constants";

function InternalCarDetails ({ value }) {
  const [driver, setDriver] = useState(null)
  const [car, setCar] = useState(null)

  useEffect(() => {
    fetchDriver(value.driverId).then(setDriver)
  }, [value.driverId])

  useEffect(() => {
    fetchCar(value.carId).then(setCar)
  }, [value.carId])

  return (
    <>
      <ReadOnlyTextField
        clearDisabled
        label='order.details.carRegistrationNumber'
        value={car ? car.registrationNumber : ''}/>
      <ReadOnlyTextField
        clearDisabled
        label='order.details.driverFirstName'
        value={driver ? driver.firstName : ''}/>
      <ReadOnlyTextField
        clearDisabled
        label='order.details.driverLastName'
        value={driver ? driver.lastName : ''}/>
      <ReadOnlyTextField
        clearDisabled
        label='order.details.driverPhoneNumber'
        value={driver ? driver.phoneNumber : ''}/>
    </>
  )
}

function ExternalCarDetails ({ value }) {
  const { carrierName, carRegistrationNumber, driverFirstName, driverLastName, driverPhoneNumber } = value
  return (
    <>
      <ReadOnlyTextField
        clearDisabled
        label='order.details.externalCarrierName'
        value={carrierName}/>
      <ReadOnlyTextField
        clearDisabled
        label='order.details.carRegistrationNumber'
        value={carRegistrationNumber}/>
      <ReadOnlyTextField
        clearDisabled
        label='order.details.driverFirstName'
        value={driverFirstName}/>
      <ReadOnlyTextField
        clearDisabled
        label='order.details.driverLastName'
        value={driverLastName}/>
      <ReadOnlyTextField
        clearDisabled
        label='order.details.driverPhoneNumber'
        value={driverPhoneNumber}/>
    </>
  )
}

export function CarrierDetails ({ value }) {
  const { t } = useTranslation();
  const { handleError } = useErrorHandling()

  const carrierId = value.internal
    ? value.internal.carrierId
    : value.external.carrierId

  const [carrier, setCarrier] = useState(null)

  useEffect(() => {
    fetchCarrier(carrierId)
      .then(setCarrier)
      .catch((error) => handleError(error, {
        [HTTP_404_ERROR]: 'notifications.error.fetchCarrier'
      }))
  }, [carrierId, handleError])

  return (
    <>
      <ReadOnlyTextField
        clearDisabled
        label='order.details.carrier'
        value={carrier ? t(carrier.name) : ''}/>
      {value.internal && <InternalCarDetails value={value.internal}/>}
      {!value.internal && <ExternalCarDetails value={value.external}/>}
    </>
  )
}
