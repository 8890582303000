import React from 'react'
import styles from 'components/Order/OrderDetails/StatusBar/StatusBar.module.css'
import { StatusBarItem } from 'components/Order/OrderDetails/StatusBar/StatusBarItem/StatusBarItem'

export function StatusBar ({ statuses }) {
  return (
    statuses &&
    <div className={styles.progressBarWindow}>
      <div className={styles.progressBarWrapper}>
        {statuses.map((status, index) =>
          <StatusBarItem status={status}
                         index={index}
                         key={`${status.type} ${status.positionInOrder}`}
          />
        )}
      </div>
    </div>
  )
}
