import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Table, { FilterType } from 'components/Shared/Table'
import { Loader } from 'components/Shared/Loader'
import { ErrorDialog } from 'components/Shared/Dialogs/ErrorDialog'
import { RelatedOrdersErrorMessage } from 'components/Shared/Dialogs/DialogContents'
import { deleteUser, fetchUsersPage } from 'services'
import { fetchDepartments } from 'services/DepartmentService'
import { fetchBranches } from 'services/BranchService'
import { useAuth } from 'context/auth'
import userRolesOptions from 'constants/userRolesOptions'
import { useErrorHandling } from 'context/errorHandling'
import { HTTP_404_ERROR } from "Constants";

export function UsersTable () {
  let history = useHistory()

  const { handleError } = useErrorHandling()

  const [columnDefinitions, setColumnDefinitions] = useState()
  const [relatedOrdersErrors, setRelatedOrdersErrors] = useState([])

  const { username: currentUserUsername } = useAuth()

  useEffect(() => {
    Promise.all(
      [
        fetchBranches(),
        fetchDepartments()])
      .then(response => {
        let branches = response[0].map(
          branch => ({
            label: branch.name,
            value: branch.id
          }))

        let departments = response[1].map(
          department => ({
            label: department.name,
            value: department.id
          }))

        initializeColumnDefinitions(branches, departments)
      })
      .catch(handleError)
  }, [handleError])

  const addUser = () => {
    history.push('/users/create')
  }

  const details = (row) => {
    history.push('/users/details/' + row.id)
  }

  const editUser = (row) => {
    history.push('/users/edit/' + row.id)
  }

  const onDeleteError = (error) => {
    if (error?.response?.data?.data?.ordersId) {
      setRelatedOrdersErrors(error.response.data.data.ordersId)
    } else {
      handleError(error, {
        [HTTP_404_ERROR]: 'notifications.error.delete'
      }).then(() => setRelatedOrdersErrors([]))
    }
  }

  const isUserDeletable = user => user.username !== currentUserUsername

  return (
    columnDefinitions ?
      <>
        <Table title={'userTable.title'}
               addButton={{
                 label: 'userTable.addButtonLabel',
                 onClick: addUser
               }}
               fetchData={fetchUsersPage}
               columns={columnDefinitions}
               tableKey={'users'}
               onRowClick={details}
               menu={{
                 edit: {
                   onClick: editUser
                 },
                 delete: {
                   onDelete: deleteUser,
                   onDeleteError: onDeleteError,
                   isDeletable: isUserDeletable,
                   deleteDialog: {
                     content: 'userTable.deleteDialog.content',
                     title: 'userTable.deleteDialog.title'
                   }
                 }
               }}/>
        <ErrorDialog
          open={!!relatedOrdersErrors && relatedOrdersErrors.length > 0}
          onClose={() => {
            setRelatedOrdersErrors([])
          }}
          content={<RelatedOrdersErrorMessage idList={relatedOrdersErrors}/>}/>
      </>
      : <Loader/>
  )

  function initializeColumnDefinitions (branches, departments) {
    setColumnDefinitions([
      {
        id: 'firstName',
        label: 'userTable.firstName',
        path: 'firstName',
        sortable: true,
        filterType: FilterType.Text
      },
      {
        id: 'lastName',
        label: 'userTable.lastName',
        path: 'lastName',
        sortable: true,
        filterType: FilterType.Text
      },
      {
        id: 'roles',
        label: 'userTable.role.title',
        path: 'role',
        filterType: FilterType.Select,
        selectOptions: userRolesOptions
      },
      {
        id: 'phoneNumber',
        label: 'userTable.phoneNumber',
        path: 'phoneNumber',
        filterType: FilterType.Text
      },
      {
        id: 'personalPhoneNumber',
        label: 'userTable.personalPhoneNumber',
        path: 'personalPhoneNumber',
        filterType: FilterType.Text
      },
      {
        id: 'email',
        label: 'userTable.email',
        path: 'email',
        filterType: FilterType.Text
      },
      {
        id: 'branchIds',
        label: 'userTable.company.branch',
        path: 'branchId',
        filterType: FilterType.Select,
        selectOptions: branches
      },
      {
        id: 'departmentIds',
        label: 'userTable.company.department',
        path: 'departmentId',
        filterType: FilterType.Select,
        selectOptions: departments
      }
    ])
  }
}
