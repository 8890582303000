import api from 'services/api'

export const downloadAttachment = (attachment) => {
    return api.get(`/order-attachments/${attachment.id}`,
      { responseType: 'blob' })
    .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', attachment.filename); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
}

export const downloadFileByOrderToken = (attachment, token) => {
    return api.get(`/contractor-view/attachment/${attachment.id}?orderToken=${token}`,
      { responseType: 'blob' })
    .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', attachment.filename); //or any other extension
        document.body.appendChild(link);
        link.click();
    });
}

export const getAttachment = (attachment) => {
    return api.get(`/order-attachments/${attachment.id}`,
        { responseType: 'blob' });
}

export const getAttachmentByToken = (attachment, token) => {
    return api.get(`/contractor-view/attachment/${attachment.id}?orderToken=${token}`,
        { responseType: 'blob' });
}
