import React from 'react'
import TextField from '@material-ui/core/TextField'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { ErrorMessage } from 'components/Shared/ErrorMessage'

export function NumberField ({
  fieldName,
  label,
  endAdornment,
  endAdornmentValue,
  className
}) {
  const [field, meta] = useField(fieldName)
  const { error, touched } = meta
  const { t } = useTranslation()

  return (
    <TextField
      error={!!error && touched}
      name={fieldName}
      key={fieldName}
      label={t(label)}
      type="number"
      variant="filled"
      helperText={<ErrorMessage name={fieldName}/>}
      className={className || ''}
      min="0"
      InputProps={{
        disableUnderline: true,
        inputProps: {
          min: 0
        },
        endAdornment: endAdornment ? endAdornmentValue : null
      }}
      {...field}
    />
  )
}
