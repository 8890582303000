import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import carFormStyles from 'components/Car/CarForm.module.css'
import { useTranslation } from 'react-i18next'
import { SelectDriverField } from 'components/Car/SelectDriverField'
import formStyles from 'components/Shared/CrudComponents/SimpleForm.module.css'
import { CarOptionalFeaturesForm } from 'components/Car/CarOptionalFeaturesForm'
import { FieldComponent } from 'components/Shared/FieldComponent'
import { ControlledSelectField } from 'components/Order/OrderForm/CarrierForm/ControlledSelectField'
import { TitleBar } from 'components/Shared/CrudComponents/TitleBar'
import { fetchInternalCarriers } from 'services/CarrierService'

export const CarSchema = Yup.object().shape({
  brand: Yup.string()
    .required('form.validation.required'),
  model: Yup.string()
    .required('form.validation.required'),
  registrationNumber: Yup.string()
    .required('form.validation.required')
    .matches('^([A-Z0-9])+$', 'form.validation.carRegistrationNumber'),
  trailerRegistrationNumber: Yup.string(),
  type: Yup.string()
    .required('form.validation.required'),
  capacity: Yup.string()
    .required('form.validation.required'),
  loadingSpace: Yup.string()
    .required('form.validation.required'),
  driverId: Yup.string()
    .nullable(),
  carrierId: Yup.string()
    .required('form.validation.required')
    .nullable(),
  optionalFeatures: Yup.array()
})

export function mapCarToFormValues (car) {
  return ({
    brand: car.brand,
    model: car.model,
    registrationNumber: car.registrationNumber,
    trailerRegistrationNumber: car.trailerRegistrationNumber,
    type: car.type,
    capacity: car.capacity,
    loadingSpace: car.loadingSpace,
    optionalFeatures: car.optionalFeatures,
    driverId: car.driverId,
    carrierId: car.carrierId
  })
}

export function CarForm ({
  translateTitleLabel,
  initialValues,
  onSubmit
}) {
  const { t } = useTranslation()

  const [isSubmitting, setSubmitting] = useState(false)
  const [carriers, setCarriers] = useState([])

  useEffect(() => {
      fetchInternalCarriers()
        .then(response => setCarriers(response))
    }, []
  )

  const handleSubmit = (values) => {
    setSubmitting(true)
    onSubmit(values)
      .finally(() => setSubmitting(false))
  }

  return <Formik
    initialValues={initialValues}
    validationSchema={CarSchema}
    onSubmit={handleSubmit}>
    {
      ({ values }) => (<Form>
        <TitleBar
          buttonLabel={t('form.car.submit')}
          title={t(`form.car.${translateTitleLabel}`)}
          isButtonDisabled={isSubmitting}/>
        <div className={formStyles.containerRow}>
          <div className={formStyles.container}>
            <h2>{t('form.car.carrier')}</h2>
            <ControlledSelectField
              options={carriers}
              label={t('form.car.carrier')}
              fieldName="carrierId"
              disableClear
              className={carFormStyles.select}/>
          </div>
          <div className={formStyles.container}>
            <h2>{t('form.car.car')}</h2>
            <FieldComponent fieldName={'registrationNumber'} label={'form.car.registrationNumber'}/>
            <FieldComponent fieldName={'brand'} label={'form.car.brand'}/>
            <FieldComponent fieldName={'model'} label={'form.car.model'}/>
            <FieldComponent fieldName={'trailerRegistrationNumber'} label={'form.car.trailerRegistrationNumber'}/>
            <FieldComponent fieldName={'loadingSpace'} label={'form.car.loadingSpace'}/>
            <FieldComponent fieldName={'type'} label={'form.car.type'}/>
            <FieldComponent fieldName={'capacity'} label={'form.car.capacity'}/>
            <CarOptionalFeaturesForm values={values}/>
          </div>
          <div className={formStyles.container}>
            <h2>{t('form.car.driver')}</h2>
            <SelectDriverField
              fieldName="driverId"
            />
          </div>
        </div>
      </Form>)}
  </Formik>
}
