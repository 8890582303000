import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckIcon } from 'assets/icons/check-copy-10.svg'
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark.svg'
import moment from 'moment'
import styles from 'components/Order/OrderDetails/HistoryTabs/Tabs/Tabs.module.css'
import {
  isLoaded,
  isUnloaded
} from 'components/Order/OrderDetails/DeliveryPointCargoApproveForm/deliveryPointCargoHelper'
import RowsCollapse from "components/Order/OrderDetails/HistoryTabs/Tabs/RowsCollapse";
import classNames from 'classnames'

export function OrderHistory ({
  actions,
  onOrderStatusChangeEdit,
  onCargoApproveRejectClicked,
  onDeliveryPointCargoShow
}) {
  const { t } = useTranslation()

  const getOrderStatusChangeActionLabel = (action) => {
    const isLoadedOrUnloaded = isLoaded(action) || isUnloaded(action);
    const waitingForApprovalLabel = `${action.approved === null && isLoadedOrUnloaded ? `(${t(`order.details.history.actions.waitingForApproval`)})` : ''}`
    if (action.deliveryPointCargo?.quantity && action.deliveryPointCargo?.weight && isLoadedOrUnloaded) {
      return `${t(
        `order.details.history.actions.orderStatus.${action.status}`)} ${action.deliveryPointPosition}: 
        ${action.deliveryPointCargo.quantity} × 
        ${t(`order.details.history.actions.wrappingTypes.${action.deliveryPointCargo.wrappingType}`)}, 
        ${action.deliveryPointCargo.weight} kg ${waitingForApprovalLabel}`
    }
    return `${t(
      `order.details.history.actions.orderStatus.${action.status}`)} ${action.deliveryPointPosition
      ? action.deliveryPointPosition
      : ''} ${waitingForApprovalLabel}`
  }

  const getDeliveryPointCargoStatusChangeActionLabel = (action) => {
    return `${t(`order.details.history.actions.deliveryPointCargo.${action.status}`)} ${action.deliveryPointPosition} 
    ${action.approved ? t(`order.details.history.actions.deliveryPointCargo.approvedBy`) :
      t(`order.details.history.actions.deliveryPointCargo.rejectedBy`)}: ${action.userFirstName} ${action.userLastName}`
  }

  const handleActionType = (param) => {
    switch (param.actionType) {
      case 'EDIT': {
        return `${t(
          'order.details.history.actions.edited')}: ${param.actionExecutor.firstName} ${param.actionExecutor.lastName}`
      }
      case 'DRIVER_STATUS_CHANGE': {
        return `${t('order.details.history.actions.driverStatus.title')}: ${t(
          `order.details.history.actions.driverStatus.${param.status}`)}`
      }
      case 'ORDER_STATUS_CHANGE': {
        return getOrderStatusChangeActionLabel(param)
      }
      case 'ATTACHMENT_REJECTED': {
        return `${t(
          'order.details.history.actions.attachmentRejected', { user: `${param.actionExecutor.firstName} ${param.actionExecutor.lastName}` })}`
      }
      case 'DELIVERY_POINT_CARGO_STATUS_CHANGE': {
        return getDeliveryPointCargoStatusChangeActionLabel(param)
      }
      default: {
        return t('order.details.history.actions.error')
      }
    }
  }

  const isActionRowWaitingForDecision = (row) => row.actionType === 'ORDER_STATUS_CHANGE' &&
    row.approved === null && (isLoaded(row) || isUnloaded(row))

  return (<RowsCollapse itemsCount={actions.length}>
      <div className={styles.historyItems}>
        {actions.map(row => {
          const isActionWaitingForDecision = isActionRowWaitingForDecision(row)
          return (
            <div className={classNames(styles.historyItem, styles.action)} key={row.id}>
              <div className={styles.historyItemCell}>
                {isActionWaitingForDecision ? <QuestionMarkIcon/> : <CheckIcon/>}
              </div>
              <div className={styles.historyItemCell}>
                {moment(row.creationDateTime).format('DD.MM.YYYY')}
              </div>
              <div className={styles.historyItemCell}>
                {moment(row.creationDateTime).format('HH:mm')}
              </div>
              <div className={styles.historyItemTypeCell}>{
                handleActionType(row)
              }</div>
              {isActionWaitingForDecision &&
              <ClickableActionText
                label={'order.details.history.actions.cargo.button'}
                onClick={() => onCargoApproveRejectClicked(row)}/>
              }
              {row.actionType === 'ORDER_STATUS_CHANGE' &&
              <ClickableActionText
                label={'order.details.history.actions.edit'}
                onClick={() => onOrderStatusChangeEdit(row)}/>
              }
              {row.actionType === 'DELIVERY_POINT_CARGO_STATUS_CHANGE' &&
              <ClickableActionText
                label={'order.details.history.actions.show'}
                onClick={() => onDeliveryPointCargoShow(row)}/>
              }
            </div>
          )
        })}
      </div>
    </RowsCollapse>
  )
}

function ClickableActionText ({ onClick, label }) {
  const { t } = useTranslation()
  return <div className={styles.clickable + ' ml-2'}
              onClick={onClick}>
    {t(label)}
  </div>
}