import React, { useEffect, useState } from 'react'
import { fetchUserNotifications, markAllAsRead } from 'services/NotificationsService'
import styles from './NotificationsList.module.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Loader } from 'components/Shared/Loader'
import { Notification } from 'components/Notification'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Check } from 'assets/icons/check.svg'
import classNames from 'classnames'
import { useErrorHandling } from 'context/errorHandling'
import { Button } from '@material-ui/core'

export function NotificationsList () {
  const [notifications, setNotifications] = useState([])
  const [page, setPage] = useState(0)
  const [downloadedPageNumbers, setDownloadedPageNumbers] = useState([])
  const [hasMore, setHasMore] = useState(false)

  const { handleError } = useErrorHandling()
  const { t } = useTranslation()

  useEffect(() => {
    const isPageDownloaded = downloadedPageNumbers.find(p => p === page)
    if (!isPageDownloaded && isPageDownloaded !== 0) {
      setDownloadedPageNumbers([page, ...downloadedPageNumbers])

      fetchUserNotifications({ page: page, rowsPerPage: 20, sort: [], filters: [] })
        .then(pageResponse => {
          setNotifications([...notifications, ...pageResponse.items])
          setHasMore(pageResponse.totalItemCount > notifications.length)
        })
        .catch(handleError)
    }
  }, [page, downloadedPageNumbers, notifications, handleError])

  const nextPage = () => {
    setPage(page + 1)
  }

  const onNotificationUpdate = (newNotification) => {
    setNotifications(notifications.map(notification => {
      if (notification.id === newNotification.id) {
        return newNotification
      } else {
        return notification
      }
    }))
  }

  const markAllRead = () => {
    markAllAsRead()
      .then(() => {
        setNotifications(notifications.map(notification => ({
          ...notification,
          read: true
        })))
      })
      .catch(handleError)
  }

  return <div id="scrollableDiv" className={styles.notificationsWrapper}>
    {notifications.length > 0 ?
      <>
        <Button className={styles.markAllReadButton}
                onClick={markAllRead}>
          <div>
            <Check className={styles.check}/>
            <Check className={classNames(styles.check, styles.secondCheck)}/>
            {t('notificationsList.markAllRead')}
          </div>
        </Button>
        <InfiniteScroll
          className={styles.infiniteScroll}
          dataLength={notifications.length}
          next={nextPage}
          hasMore={hasMore}
          loader={<Loader/>}
          scrollableTarget="scrollableDiv"
        >
          {notifications.map(item =>
            <Notification key={item.id}
                          bodyClassName={styles.notification}
                          item={item}
                          onNotificationUpdate={onNotificationUpdate}/>
          )}
        </InfiniteScroll>
      </> :
      <div className={styles.noNotificationsContainer}>
        {t('notificationsList.noNotificationsMessage')}
      </div>}
  </div>
}
