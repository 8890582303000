import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import styles from 'components/Order/OrderDetails/OrderDetails.module.css'
import mailOptions from 'components/Order/OrderForm/MailSettingsForm/mailOptions'
import mailLanguages from 'components/Order/OrderForm/MailSettingsForm/mailLanguages'
import { ReactComponent as ArrowRight } from 'assets/icons/back.svg'
import { Loader } from 'components/Shared/Loader'
import { getLabelFromArrayByParameter, getLabelFromArrayByParametersList } from 'components/Order/OrderDetails/Utils'
import { ReadOnlyTextField } from 'components/Shared/ReadOnlyTextField'
import { DeliveryPointExpansionPanel } from 'components/Order/OrderDetails/DeliveryPointExpansionPanel/DeliveryPointExpansionPanel'
import { deleteOrder, downloadAttachment, fetchUser, getAttachment, subscribeOrderActionsUpdated } from 'services'
import { FileChip } from 'components/Shared/FileChip'
import { CarrierDetails } from 'components/Order/OrderDetails/CarrierDetails'
import { PrimaryButton } from 'components/Shared/PrimaryButton'
import HistoryTabs from 'components/Order/OrderDetails/HistoryTabs/HistoryTabs'
import { StatusBar } from 'components/Order/OrderDetails/StatusBar/StatusBar'
import { OrderStatusSelectField } from 'components/Order/OrderDetails/OrderStatusSelectField'
import { SuccessDialog } from 'components/Shared/Dialogs/SuccessDialog'
import { WarningDialog } from 'components/Shared/Dialogs/WarningDialog'
import { fetchOrder, fetchOrderToken, getOrderActions, getStatusBar, sendToDriver } from 'services/OrderService'
import { AttachmentsDialog } from 'components/Shared/AttachmentsPreview'
import { useErrorHandling } from 'context/errorHandling'
import { useWebSocketConnection } from 'context'
import { HTTP_404_ERROR, HTTP_422_ERROR, HTTP_500_ERROR } from 'Constants'

export function OrdersDetails (props) {
  const [order, setOrder] = useState(null)
  const [forwarder, setForwarder] = useState(null)
  const [dispatcher, setDispatcher] = useState(null)
  const [successSendingToDriverDialogOpen, setSuccessSendingToDriverDialogOpen] = useState(false)
  const [warningSendingToDriverDialogOpen, setWarningSendingToDriverDialogOpen] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [successDeletingDialogOpen, setSuccessDeletingDialogOpen] = useState(false)
  const [orderToken, setOrderToken] = useState(null)
  const [actions, setActions] = useState([])
  const [statuses, setStatuses] = useState([])
  const [isDeleting, setIsDeleting] = useState(false)
  const [previewedAttachment, setPreviewedAttachment] = useState(null)

  const { handleError } = useErrorHandling()

  const history = useHistory()
  const id = props.match.params.id
  const attachmentId = props.match.params.attachmentId

  const isWebSocketConnection = useWebSocketConnection()

  useEffect(() => {
    if (order && attachmentId) {
      const previewedAttachment = order.attachments.find(attachment => attachment.id === attachmentId)
      setPreviewedAttachment(previewedAttachment)
    } else {
      setPreviewedAttachment(null)
    }
  }, [attachmentId, order])

  const { t } = useTranslation()

  const fetchStatuses = useCallback(() => {
    getStatusBar(id)
      .then(response => setStatuses(response))
      .catch(handleError)
  }, [id, handleError])

  const fetchActions = useCallback(() => {
    getOrderActions(id)
      .then(response => setActions(response))
      .catch(handleError)
  }, [id, handleError])

  useEffect(() => {
    if (isWebSocketConnection) {
      return subscribeOrderActionsUpdated(id, () => {
        fetchActions()
        fetchStatuses()
        fetchOrder(id).then(newOrder => {
          setOrder({
            ...order,
            status: newOrder.status
          })
        })
      })
    }
  }, [isWebSocketConnection, id, fetchActions, fetchStatuses, order])

  const onStatusChanged = useCallback((newStatus) => {
    setOrder({
      ...order,
      status: newStatus
    })
  }, [order])

  useEffect(() => {
    fetchOrderToken(id).then(orderToken => {
      setOrderToken(orderToken)
    }).catch(() => setOrderToken(null))

    fetchOrder(id).then(response => {
      response.attachments = [
        ...response.attachments.filter(attachment => !attachment.verified),
        ...response.attachments.filter(attachment => attachment.verified)]
      setOrder(response)
      return response
    }).then(response => {
      fetchUser(response.forwarderId)
        .then(setForwarder)
        .catch(error => handleError(error, {
          [HTTP_404_ERROR]: 'notifications.error.forwarderNotFound'
        }))
      if (response.dispatcherId) {
        fetchUser(response.dispatcherId)
          .then(setDispatcher)
          .catch(error => handleError(error, {
            [HTTP_404_ERROR]: 'notifications.error.dispatcherNotFound'
          }))
      }
    })
      .catch(handleError)

    fetchStatuses()
    fetchActions()
  }, [fetchActions, fetchStatuses, id, handleError])

  const onSendingToDriverDialogClose = (isSentConfirmed) => {
    if (isSentConfirmed) {
      sendToDriver(id).then(() => {
        setWarningSendingToDriverDialogOpen(false)
        setSuccessSendingToDriverDialogOpen(true)
      }).catch(error => {
        setWarningSendingToDriverDialogOpen(false)
        handleError(error, {
          [HTTP_404_ERROR]: 'notifications.error.sentToDriver',
          [HTTP_422_ERROR]: 'notifications.error.sentToDriver',
          [HTTP_500_ERROR]: 'notifications.error.sentToDriver'
        })
      })
    } else {
      setWarningSendingToDriverDialogOpen(false)
    }
  }

  const onDownloadAttachment = (attachment) => {
    downloadAttachment(attachment)
      .catch(error => handleError(error, {
        [HTTP_404_ERROR]: 'notifications.error.fileNotFound',
        [HTTP_500_ERROR]: 'notifications.error.fileDownloadError'
      }))
  }

  const onDeleteDialogClose = (isDeleteConfirmed) => {
    if (isDeleteConfirmed) {
      setIsDeleting(true)
      deleteOrder(id)
        .then(() => {
          setSuccessDeletingDialogOpen(true)
        })
        .catch(error => {
          handleError(error, {
            [HTTP_404_ERROR]: 'notifications.error.delete'
          })
        })
    }
    setIsDeleting(false)
    setShowDeleteDialog(false)
  }

  const editOrder = () => {
    history.push(`/orders/edit/${id}`)
  }

  const getCountryAndZipCodePair = (deliveryPoint) => deliveryPoint.country + ' ' + deliveryPoint.zipCode

  function updateAttachment (attachment) {
    setOrder({ ...order, attachments: order.attachments.map(a => a.id === attachment.id ? attachment : a) })
  }

  function removeAttachment (removedAttachment) {
    setOrder({ ...order, attachments: order.attachments.filter(attachment => attachment.id !== removedAttachment.id) })
    fetchActions()
  }

  const setPreviewAttachment = (attachmentId) => history.push('/orders/details/' + id + '/attachments/' + attachmentId)

  return (order && forwarder ? <div className={styles.orderDetailsWrapper}>
    <WarningDialog open={warningSendingToDriverDialogOpen}
                   onClose={onSendingToDriverDialogClose}
                   title={'notifications.warnings.sendToDriverTitle'}
                   content={'notifications.warnings.sendToDriver'}
    />
    <SuccessDialog open={successSendingToDriverDialogOpen}
                   onClose={() => {
                     setSuccessSendingToDriverDialogOpen(false)
                     history.push('/orders')
                   }} content={'notifications.success.sentToDriver'}
    />
    <WarningDialog open={showDeleteDialog}
                   onClose={onDeleteDialogClose}
                   title={'orderTable.deleteDialog.title'}
                   content={'orderTable.deleteDialog.content'}
                   isConfirmButtonDisabled={isDeleting}/>
    <SuccessDialog open={successDeletingDialogOpen}
                   onClose={() => {
                     setSuccessDeletingDialogOpen(false)
                     history.push('/orders')
                   }} content={'notifications.success.delete'}/>
    <div className={classNames(styles.detailsRow, styles.orderIdBox)}>
      <div className={classNames(styles.titleRow)}>
        <div style={{
          flexBasis: '280px', marginRight: '20px'
        }}>
          <h1>
            {'ID ' + order.readableId}
          </h1>
        </div>
        <div style={{ flexBasis: '400px', textAlign: 'left' }}>
          <h1>
            {getCountryAndZipCodePair(order.loadingDeliveryPoints[0])}
            {' '}
            <ArrowRight style={{ verticalAlign: '-1px' }}/>
            {' '}
            {getCountryAndZipCodePair(order.unloadingDeliveryPoints[order.unloadingDeliveryPoints.length - 1])}
          </h1>
        </div>
      </div>
      <div style={{
        display: 'flex', paddingRight: '20px'
      }}>
        <OrderStatusSelectField
          order={order}
          onStatusChange={(newStatus) => {
            fetchActions()
            fetchStatuses()
            onStatusChanged(newStatus)
          }}
          actions={actions}
        />
      </div>
    </div>
    <StatusBar statuses={statuses}/>
    <HistoryTabs order={order}
                 actions={actions}
                 refreshActions={fetchActions}
                 refreshStatuses={fetchStatuses}
                 onStatusChanged={onStatusChanged}/>
    <div className={styles.divider}/>
    <div
      className={classNames(styles.detailsRow, styles.orderDetailsHeader)}>
      <div className={styles.orderDetailsTitle}>
        <h1>{t('order.details.title')}</h1>
      </div>
      <div className={styles.buttonsBox}>
        <div className={styles.buttonMargin}><PrimaryButton
          onClick={editOrder}
          label={t('order.details.editOrder')}/></div>
        {order.deliveryMethod.internal && <div className={styles.buttonMargin}><PrimaryButton
          onClick={() => setWarningSendingToDriverDialogOpen(true)}
          label={t('order.details.sendToDriver')}/></div>}
        {orderToken && <div className={styles.buttonMargin}><PrimaryButton
          onClick={() => history.push('/contractor-view/' + orderToken)}
          label={t('order.details.toContractorView')}/></div>}
        <div className={styles.buttonMargin}>
          <PrimaryButton onClick={() => setShowDeleteDialog(true)}
                         label={t('order.details.deleteOrder')}/>
        </div>
      </div>
    </div>
    <div className={classNames(styles.detailsRow, styles.careProvidersBox)}>
      <ReadOnlyTextField clearDisabled label={'order.details.forwarder'}
                         value={forwarder.firstName + ' ' + forwarder.lastName}/>
      {dispatcher && <ReadOnlyTextField clearDisabled label={'order.details.dispatcher'}
                                        value={dispatcher.firstName + ' ' + dispatcher.lastName}/>}
    </div>
    <div className={classNames(styles.detailsRow, styles.clientsRow)}>
      <div className={styles.detailsColumnPair}>
        <div>
          <div className={styles.header}>
            {t('order.details.clientDetails')}
          </div>
          <div className={styles.detailsColumn}>
            <ReadOnlyTextField clearDisabled
                               label={'order.details.readableId'}
                               value={order.readableId}/>
            <ReadOnlyTextField clearDisabled
                               label={'order.details.referenceId'}
                               value={order.referenceId}/>
            <ReadOnlyTextField clearDisabled
                               label={'order.details.client.name'}
                               value={order.contractorInfo.name}/>
            {order.contractorInfo.emails.map((email, index) =>
              <ReadOnlyTextField clearDisabled
                                 key={email}
                                 label={'order.details.client.email'}
                                 value={email}
                                 additionalLabelText={index + 1}
              />)}
            {order.contractorInfo.bccEmails.map((email, index) =>
              <ReadOnlyTextField clearDisabled
                                 key={email}
                                 label={'order.details.client.bccEmail'}
                                 value={email}
                                 additionalLabelText={index + 1}
              />)}
          </div>
        </div>
        <div className={styles.secondInPair}>
          <div className={styles.header}>
            {t('order.details.carrier')}
          </div>
          <div className={styles.detailsColumn}>
            <CarrierDetails value={order.deliveryMethod}/>
          </div>
        </div>
      </div>
      <div className={styles.detailsColumnPair}>
        <div>
          <div className={styles.header}>
            {t('order.details.info')}
          </div>
          <div className={styles.detailsColumn}>
            <ReadOnlyTextField clearDisabled label={'order.details.comment'}
                               value={order.comment} multiline/>
          </div>
        </div>
        <div className={styles.secondInPair}>
          <div className={styles.header}>
            {t('order.details.attachments')}
          </div>
          <div className={styles.attachments}>
            <AttachmentsDialog open={!!previewedAttachment}
                               initAttachment={previewedAttachment}
                               attachmentSource={getAttachment}
                               attachments={order.attachments}
                               onClose={() => {
                                 history.push('/orders/details/' + id)
                               }}
                               showSettings
                               onAttachmentUpdated={updateAttachment}
                               onAttachmentRemove={removeAttachment}
                               setPreviewAttachment={setPreviewAttachment}
            />
            <ul className="d-flex flex-column">
              {order.attachments.map(attachment =>
                <FileChip
                  key={attachment.id}
                  file={{
                    id: attachment.id, name: attachment.filename
                  }}
                  onDownload={() => onDownloadAttachment(attachment)}
                  onShow={() => setPreviewAttachment(attachment.id)}
                  accessDetails={{
                    verified: attachment.verified,
                    driverAccess: attachment.attachmentAccess.driverAccess,
                    contractorAccess: attachment.attachmentAccess.contractorAccess
                  }}/>)}
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.headerFiller}>
        <div className={styles.header}/>
      </div>
    </div>
    <div className={styles.header}>
      {t('order.details.order')}
    </div>
    {order.loadingDeliveryPoints.map((deliveryPoint, index) =>
      <DeliveryPointExpansionPanel
        key={index}
        deliveryPoint={deliveryPoint}
        index={index}
        title={'order.delivery.loading.title'}/>)}
    {order.unloadingDeliveryPoints.map((deliveryPoint, index) =>
      <DeliveryPointExpansionPanel
        key={index}
        deliveryPoint={deliveryPoint}
        index={index}
        title={'order.delivery.unloading.title'}/>)}
    <div className={classNames(styles.detailsRow, styles.emailBox)}>
      <div>
        {t('order.details.emailNotificationSettings.autoUpdate')}
      </div>
      <div>
        <ReadOnlyTextField
          clearDisabled
          label={'order.details.emailNotificationSettings.frequency'}
          value={t(getLabelFromArrayByParametersList(order.emailNotificationSettings, mailOptions, ['frequencySettingsType', 'sendIntervalHours']))}
        />
      </div>
      <div>
        <ReadOnlyTextField
          clearDisabled
          label={'order.details.emailNotificationSettings.language'}
          value={t(getLabelFromArrayByParameter(order.emailNotificationSettings.language, mailLanguages, 'id'))}
        />
      </div>
    </div>
  </div> : <Loader/>)
}

