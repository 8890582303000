import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import styles from './AttachmentsDialog.module.css'
import { PrimaryButton } from 'components/Shared/PrimaryButton'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as NextIcon } from 'assets/icons/right.svg'
import { ReactComponent as PrevIcon } from 'assets/icons/left.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/add.svg'
import { ReactComponent as PDFIcon } from 'assets/icons/pdf.svg'
import { ReactComponent as ExitIcon } from 'assets/icons/exit.svg'
import { Loader } from 'components/Shared/Loader'
import { Tooltip } from '@material-ui/core'
import { ReactComponent as Verified } from 'assets/icons/check.svg'
import { ReactComponent as DriverAccess } from 'assets/icons/wheel.svg'
import { ReactComponent as ContractorAccess } from 'assets/icons/user.svg'
import { useTranslation } from 'react-i18next'
import { rejectAttachment, setAttachmentContractorAccessAs, setAttachmentDriverAccessAs, setAttachmentVerifiedAs } from 'services'
import { contractorAccessTooltip, driverAccessTooltip, verifiedTooltip } from 'components/Shared/FileChip/AccessControls'

const isImage = require('is-image')

export function AttachmentsDialog ({
  open, attachments, onClose, initAttachment, attachmentSource, onAttachmentUpdated, showSettings, onAttachmentRemove, setPreviewAttachment
}) {
  const [img, setImg] = useState()
  const [pdfUrl, setPdfUrl] = useState()
  const [unknownTypeUrl, setUnknownTypeUrl] = useState()
  const [loading, setLoading] = useState(false)

  const [attachmentToShow, setAttachmentToShow] = useState()
  const { t } = useTranslation()
  const [displayedAttachment, setDisplayedAttachment] = useState(null)

  useEffect(() => {
    if (attachments && initAttachment) {
      setAttachmentToShow(attachments.find(attachment => attachment.id === initAttachment.id))
    }
  }, [initAttachment, attachments])

  useEffect(() => {
    if (attachmentToShow && (!displayedAttachment || attachmentToShow.id !== displayedAttachment.id)) {
      setImg(null)
      setPdfUrl(null)
      setUnknownTypeUrl(null)
      setLoading(true)
      attachmentSource(attachmentToShow).then(response => {
        setDisplayedAttachment(attachmentToShow)
        setLoading(false)
        if (isImage(attachmentToShow.filename)) {
          const url = URL.createObjectURL(new Blob([response]))
          setImg(url)
        } else if (attachmentToShow.filename.endsWith('.pdf')) {
          const url = URL.createObjectURL(new Blob([response], { type: 'application/pdf' }))
          setPdfUrl(url)
        } else {
          setUnknownTypeUrl(URL.createObjectURL(new Blob([response])))
        }
      })

    }
  }, [attachmentToShow, attachmentSource, attachments, displayedAttachment])

  const next = () => {
    const nextAttachment = attachments[(attachments.indexOf(attachmentToShow) + 1) % attachments.length]
    setPreviewAttachment ? setPreviewAttachment(nextAttachment.id) : setAttachmentToShow(nextAttachment)
  }

  const previous = () => {
    const previousAttachment = attachments[(attachments.indexOf(attachmentToShow) - 1 + attachments.length) % attachments.length]
    setPreviewAttachment ? setPreviewAttachment(previousAttachment.id) : setAttachmentToShow(previousAttachment)
  }

  function download () {
    const url = img || pdfUrl || unknownTypeUrl
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', attachmentToShow.filename) //or any other extension
    document.body.appendChild(link)
    link.click()
  }

  const onAttachmentUpdate = updatedAttachment => {
    onAttachmentUpdated && onAttachmentUpdated(updatedAttachment)
  }

  const contractorAccessClick = (attachment) => setAttachmentContractorAccessAs(attachmentToShow.id, !attachment.attachmentAccess.contractorAccess)
    .then(attachment => onAttachmentUpdate(attachment))

  const driverAccessClick = (attachment) => setAttachmentDriverAccessAs(attachmentToShow.id, !attachment.attachmentAccess.driverAccess)
    .then(attachment => onAttachmentUpdate(attachment))

  const verifiedClick = (verified) => setAttachmentVerifiedAs(attachmentToShow.id, verified)
    .then(attachment => onAttachmentUpdate(attachment))

  const reject = () => rejectAttachment(attachmentToShow.id).then(() => {
    onAttachmentRemove(attachmentToShow)
    const updatedAttachmentsListLength = attachments.filter(attachment => attachmentToShow.id !== attachment.id).length
    if (updatedAttachmentsListLength === 0) {
      onClose()
    } else {
      next()
    }
  })

  function getIconClassName (accepted) {
    return `${styles.accessIcon} ${accepted ? styles.accepted : styles.denied}`
  }

  return (<div>
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={false}
      fullWidth
    >
      <div className="row mt-3 mx-0">
        <div className="col-2"></div>
        <div className="col-8">
          <h2
            className={'text-center'}>
            {attachmentToShow?.filename}
          </h2>
        </div>
        <div className="col-2">
          <div className="row mr-3 mt-3">
            <div className="flex-grow-1"/>
            <DownloadIcon onClick={download}
                          className={styles.downloadIcon}/>
            <ExitIcon onClick={onClose}
                      className={styles.exitIcon}/>
          </div>
        </div>
      </div>
      <DialogContent className={styles.contentContainer}>

        <div
          className={'row w-100 align-items-center ' + styles.imageContent}>
          {loading && <Loader/>}
          <div className={'col-1'}>
            {attachments?.length > 1 && <IconButton className={styles.button}
                                                    onClick={previous}><PrevIcon/></IconButton>}
          </div>
          <div className={'col-10'}>
            {img && !loading && <img className={'my-auto ' + styles.image} src={img}
                                     alt={'Attachment'}/>}
            {pdfUrl && !loading && <IconButton className={'m-auto'}
                                               onClick={() => window.open(pdfUrl)}><PDFIcon
              className={styles.fileFormatIcon}/></IconButton>}
            {!pdfUrl && !loading && !img && <IconButton className={'m-auto'}
                                                        onClick={() => download()}><DownloadIcon
              className={styles.fileFormatIcon}/></IconButton>}
          </div>
          <div className={'col-1'}>
            {attachments && attachments.length > 1 && <IconButton className={styles.button}
                                                                  onClick={next}><NextIcon/></IconButton>}
          </div>
        </div>
      </DialogContent>

      <DialogActions
        className={styles.dialogActions}
        disableSpacing
      >
        <div className='col'>
          {showSettings && attachmentToShow && <div className='row justify-content-center mb-2'>
            <h3 className='mt-2'>{t('order.attachment.settings')}</h3>
            <Tooltip className='mx-3'
                     title={t(verifiedTooltip(attachmentToShow.verified))}>
              <Verified
                onClick={() => verifiedClick(!attachmentToShow.verified)}
                className={getIconClassName(attachmentToShow.verified)}/>
            </Tooltip>
            <Tooltip className='mx-3' title={t(driverAccessTooltip(attachmentToShow.attachmentAccess.driverAccess))}>
              <DriverAccess onClick={() => driverAccessClick(attachmentToShow)}
                            className={getIconClassName(attachmentToShow.attachmentAccess.driverAccess)}/>
            </Tooltip>

            <Tooltip className='mx-3'
                     title={t(contractorAccessTooltip(attachmentToShow.attachmentAccess.contractorAccess))}>
              <ContractorAccess onClick={() => contractorAccessClick(attachmentToShow)}
                                className={getIconClassName(attachmentToShow.attachmentAccess.contractorAccess)}/>
            </Tooltip>
          </div>}

          {attachmentToShow && <div className='row'>
            {showSettings && <div className={styles.dialogButton}>
              <PrimaryButton
                disabled={attachmentToShow.verified}
                onClick={() => verifiedClick(!attachmentToShow.verified)}
                label={'order.attachment.verify'}
              />
            </div>}
            {onAttachmentRemove && !attachmentToShow.verified && <div className={styles.dialogButton}>
              <PrimaryButton
                onClick={() => reject()}
                label={'order.attachment.reject'}
              />
            </div>}
            {onAttachmentRemove && attachmentToShow.verified && <div className={styles.dialogButton}>
              <PrimaryButton
                onClick={() => reject()}
                label={'order.attachment.delete'}
              />
            </div>}
          </div>}
        </div>
      </DialogActions>
    </Dialog>
  </div>)
}
