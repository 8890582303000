import { makeStyles } from '@material-ui/core/styles'
import { AvenirRegular, primaryRed } from 'styles/variables'

export const useTableFiltersStyles = makeStyles({
  cell: {
    fontFamily: AvenirRegular,
    fontSize: 15,
    borderBottom: 'none',
    minWidth: 110,
    whiteSpace: 'nowrap',
  },
  clearFiltersCell: {
    minWidth: 'unset',
  },
  clearFiltersBtn: {
    textTransform: 'none',
    whiteSpace: 'normal',
    color: primaryRed,
    width: '50px',
  },
  searchInput: {
    '& input': {
      padding: '12.75px 0',
      fontSize: 13,
    },
    '& div > div': {
      marginRight: `1px`,
    },
  },
  numberInput: {
    width: 64,
    '& input': {
      padding: '12.75px 6px',
      fontSize: 13,
    },
    '& div > div': {
      marginRight: `1px`,
    },
  },
  dateInput: {
    '& input': {
      padding: '12.75px 10.75px',
      fontSize: 13,
    },
  }
})
