import api from 'services/api'
import { stringifyPageQuery } from 'services/utils'

export const createUser = (newUser) =>
  api.post('/users', {
    ...newUser
  })

export const fetchUser = (id) =>
  api.get(`/users/${id}`)

export const fetchUsersPage = (params) =>
  api.get(`/users?${stringifyPageQuery(params)}`)

export const fetchForwarders = () =>
  api.get('/users/forwarders')

export const fetchDispatchers = () =>
  api.get('/users/dispatchers')

export const deleteUser = (id) => api.post(`/users/${id}/mark-as-deleted`)

export const editUser = ({ id, ...updatedUser }) =>
  api.put(`/users/${id}`, {
      ...updatedUser
    }
  )

export const editUserAccount = ({
  username,
  password,
  id
}) =>
  api.put(`/users/${id}/account`, {
      username: username,
      password: password
    }
  )

export const fetchLoggedUser = () =>
  api.get(`/users/logged`)
