import React from 'react'
import { FieldArray } from 'formik'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AddIcon } from 'assets/icons/add-red.svg'
import styles from 'components/Order/OrderForm/ShipmentDetailsForm/ShipmentDetailsForm.module.css'
import { PrimaryButton } from 'components/Shared/PrimaryButton'
import { CargoComponent } from 'components/Order/OrderForm/ShipmentDetailsForm/CargoComponent'

export function DeliveryPointForm ({ values, setFieldValue, orderAction, onDeliveryPointAddClick }) {
  const { t } = useTranslation()

  const onDeliveryPointAddClickHandler = arrayHelpers => event => {
    onDeliveryPointAddClick(arrayHelpers)
    event.preventDefault()
  }

  return (
    <FieldArray
      name={orderAction}
      render={arrayHelpers => (
        <div className={styles.cargoComponentWrapper}>
          {values[orderAction].map((value, index) => {
            return <div key={index}>
              <CargoComponent
                name={`${orderAction}.${index}`}
                orderAction={orderAction}
                index={index}
                values={values}
                arrayHelpers={arrayHelpers}
                setFieldValue={setFieldValue}
              />
            </div>
          })}
          <div className={styles.buttonContainer}>
          <PrimaryButton
            type="button"
            label={t(`form.order.cargo.add.${orderAction}`)}
            onClick={onDeliveryPointAddClickHandler(arrayHelpers)}
            icon={<AddIcon/>}
            className={styles.addDeliveryPointButton}
          />
          </div>
        </div>
      )}
    />
  )
}
