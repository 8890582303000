import React, { useMemo, useRef } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from 'components/Shared/PrimaryButton'
import { Form, Formik } from 'formik'
import { triggerFormSubmit } from 'utils/utils'
import DialogContentText from '@material-ui/core/DialogContentText'
import styles from 'components/Order/OrderDetails/HistoryTabs/HistoryTabs.module.css'
import { mapToCargoInitialValues } from 'components/Order/OrderDetails/DeliveryPointCargoApproveForm/deliveryPointCargoHelper'
import {
  ApproveCargoSchema,
  DeliveryPointCargoApproveForm
} from 'components/Order/OrderDetails/DeliveryPointCargoApproveForm'
import { Tooltip } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

export function ApproveRejectCargoDialog ({
  order,
  action,
  onClose,
  onApprove,
  onReject,
  renderHeader
}) {
  const { t } = useTranslation()
  const history = useHistory()

  const initialFormValues = useMemo(() => mapToCargoInitialValues(action), [action])

  const handleDismiss = () => {
    onClose()
  }

  function handleFormSubmit (values) {
    onApprove(values)
  }

  function goToOrderDetails () {
    history.push('/orders/details/' + order.id)
    handleDismiss()
  }

  const formRef = useRef()

  return (
    <Dialog
      fullWidth
      open={true}
      maxWidth={'md'}
      onClose={handleDismiss}
    >
      <DialogContentText className={styles.contentContainer}>
        {t('order.details.history.actions.cargo.title')}
      </DialogContentText>
      <DialogContent>
        <div className={'m-auto text-center'}>
          <div className={classNames("pb-2", styles.orderDetails)} onClick={goToOrderDetails}>
            <h3>{renderHeader}</h3>
          </div>
          <Formik
            innerRef={formRef}
            initialValues={initialFormValues}
            enableReinitialize
            validationSchema={ApproveCargoSchema}
            onSubmit={values => handleFormSubmit(values)}>
            {() => (
              <Form>
                <DeliveryPointCargoApproveForm order={order} action={action}/>
              </Form>
            )
            }
          </Formik>
        </div>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          onClick={handleDismiss}
          label={'notifications.back'}
        />
        <Tooltip title={t('order.details.history.actions.cargo.warning')} arrow placement="top">
          <div>
            <PrimaryButton
              onClick={onReject}
              label='order.details.history.actions.cargo.reject'
            />
          </div>
        </Tooltip>
        <PrimaryButton
          onClick={triggerFormSubmit(formRef)}
          label='order.details.history.actions.cargo.approve'
        />
      </DialogActions>
    </Dialog>
  )
}
