import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { useTranslation } from 'react-i18next'
import styles from 'components/Shared/Dialogs/WarningDialog/WarningDialog.module.css'
import DialogTitle from '@material-ui/core/DialogTitle'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ContainedButton } from 'components/Shared/ContainedButton'
import { PrimaryButton } from 'components/Shared/PrimaryButton'

const useStyles = makeStyles({
  paper: {
    padding: '15px 30px 32px'
  }
})

export function WarningDialog ({ open, content, onClose, title, isConfirmButtonDisabled }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleClose = (result) => () => {
    onClose(result)
  }

  return (
    <div>
      <Dialog
        classes={{paper: classes.paper}}
        open={open}
        maxWidth={'md'}
        onClose={handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={styles.titleContainer} id="alert-dialog-title">{t(title)}</DialogTitle>
        <DialogContent>
          <DialogContentText className={styles.contentContainer} id="alert-dialog-description">
            {t(content)}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          className={styles.dialogActions}
          disableSpacing
        >
          <div className={styles.dialogButton}>
            <PrimaryButton
              onClick={handleClose(false)}
              label={'notifications.back'}
            />
          </div>
          <div className={styles.dialogButton}>
            <ContainedButton
              className={styles.dialogButton}
              onClick={handleClose(true)}
              label={'notifications.confirm'}
              isDisabled={isConfirmButtonDisabled}
            />
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}
