import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { ExternalCarForm } from 'components/Order/OrderForm/CarrierForm/ExternalCarForm'
import { InternalDriver } from 'components/Order/OrderForm/CarrierForm/InternalDriver'
import { ControlledSelectField } from 'components/Order/OrderForm/CarrierForm/ControlledSelectField'
import { fetchCarriers } from 'services/CarrierService'
import classes from 'components/Order/OrderForm/CarrierForm/CarrierForm.module.css'
import { fetchCarsForCarrier } from 'services/CarService'

export function CarrierForm ({ fieldName, onCarrierSelect }) {
  const { t } = useTranslation()

  const [, meta, helpers] = useField(fieldName)
  const { value } = meta
  const { setValue } = helpers

  const [carriers, setCarriers] = useState([])

  const [cars, setCars] = useState([])

  useEffect(() => {
    fetchCarsForCarrier(value.id).then(cars => setCars(cars))
  }, [value.id])

  function onInternalCarChanged (carId) {
    if (carId) {
      const car = cars.find(c => c.id === carId)
      onCarrierSelect(car.carrierId)
      setValue({
        id: car.carrierId,
        isInternal: true,
        internalCar: {
          carId: car.id,
          driverId: car.driver.id
        },
        externalCar: null
      })
    } else {
      onCarrierSelect(value.id)
      setValue({
        id: value.id,
        internalCar: {
          carId: null,
          driverId: null
        },
        isInternal: true,
        externalCar: null
      })
    }
  }

  useEffect(() => {
    fetchCarriers().then(carriers => setCarriers(carriers))
  }, [])

  function onCarrierChanged (carrierId) {
    if (carrierId) {
      const carrier = carriers.find(c => c.id === carrierId)
      onCarrierSelect(carrier.id)
      setValue({
        id: carrier.id,
        isInternal: carrier.internal,
        internalCar: carrier.internal
          ? {
            carId: null,
            driverId: null
          }
          : null,
        externalCar: !carrier.internal
          ? {
            carrierName: '',
            carRegistrationNumber: '',
            driverFirstName: '',
            driverLastName: '',
            driverPhoneNumber: ''
          }
          : null
      })
    } else {
      onCarrierSelect(null)
      setValue({
        id: null,
        isInternal: null,
        internalCar: null,
        externalCar: null
      })
    }
  }

  return (
    <div>
      <h2 className={classes.title}>{t('form.order.carrier.title')}</h2>
      <ControlledSelectField
        fieldName={`${fieldName}.id`}
        options={carriers}
        label={t('form.order.carrier.carrierSelect')}
        getOptionLabel={c => t(c.name)}
        onChange={onCarrierChanged}
      />
      {value.isInternal !== false &&
      <ControlledSelectField
        fieldName={`${fieldName}.internalCar.carId`}
        options={cars}
        getOptionLabel={car => car.registrationNumber}
        label={t('form.order.carrier.carRegistrationNumber')}
        onChange={onInternalCarChanged}
      />
      }
      {
        value.isInternal === true
        && <InternalDriver
          carrierId={value.id}
          driverId={value.internalCar.driverId}/>
      }
      {
        value.isInternal === false
        && <ExternalCarForm
          fieldName={`${fieldName}.externalCar`}/>
      }
    </div>
  )
}
