import React from 'react'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";

const useStyles = makeStyles({
  defaultStyles: {
    width: '310px',
    marginBottom: '24px'
  }
})

export function ReadOnlyTextField ({
  value,
  label,
  additionalLabelText = '',
  variantValue = 'filled',
  clearDisabled,
  InputClass,
  className,
  multiline
}) {
  const { t } = useTranslation()
  const classes = useStyles();

  return (
    <TextField
      InputProps={{
        disableUnderline: variantValue === 'filled',
        classes: {
          input: InputClass
        }
      }}
      InputLabelProps={{
        shrink: true,
        disableAnimation: true,
      }}
      label={`${t(label)} ${additionalLabelText}`}
      className={classNames(classes.defaultStyles, className, clearDisabled ? 'clearDisabled' : '')}
      variant={variantValue}
      disabled
      value={value ? value : '-'}
      multiline={multiline}
    />
  )
}

