import Row from 'react-bootstrap/Row'
import styles from 'components/Shared/CrudComponents/SimpleForm.module.css'
import Col from 'react-bootstrap/Col'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { ContainedButton } from 'components/Shared/ContainedButton'
import { UserAccountFormFields, UserAccountSchema } from 'components/Users/UserAccountFormFields'
import { SuccessDialog } from 'components/Shared/Dialogs/SuccessDialog'
import { USERNAME_CONFLICT } from 'services/errorCodes'
import { editDriverAccount, fetchDriverAccount } from 'services'
import { HTTP_404_ERROR, HTTP_422_ERROR } from "Constants";
import ApiError from "constants/ApiError";
import { useErrorHandling } from "context/errorHandling";

export function EditDriverAccountForm ({ id }) {
  const [isSubmitting, setSubmitting] = useState(false)
  const { t } = useTranslation()
  const [editedDialogOpen, setEditedDialogOpen] = useState(false)
  const [driverAccount, setDriverAccount] = useState(null)

  const history = useHistory()
  const { handleError } = useErrorHandling()

  useEffect(() => {
      fetchDriverAccount(id).then(response => {
          setDriverAccount(response)
        },
      ).catch(error => handleError(error, {
        [HTTP_404_ERROR]: 'notifications.error.getDiverAccount'
      }).then(() => history.push('/drivers')))
    }, [id, handleError, history],
  )

  function handleSubmit (values) {
    setSubmitting(true)
    editDriverAccount({
      id: id,
      ...values,
    }).then(() => {
        setEditedDialogOpen(true)
      },
    ).catch(error => handleError(error, {
      [HTTP_422_ERROR]: error?.response?.data?.errorCode === USERNAME_CONFLICT ?
        'notifications.error.usernameConflict' : ApiError.UnprocessableEntity
    }))
      .finally(() => setSubmitting(false))
  }

  return <div>
    <SuccessDialog open={editedDialogOpen}
                   onClose={() => {
                     setEditedDialogOpen(false)
                     history.push('/drivers')
                   }} content={t('notifications.success.updatedDriverAccount')}/>
    {driverAccount && <Formik
      initialValues={{
        username: driverAccount.username,
        password: '',
        passwordConfirmation: '',
      }}
      validationSchema={UserAccountSchema}
      onSubmit={handleSubmit}>
      <Form>
        <Row className={styles.containerRow}>
          <Col className={styles.container}>
            <h2>{t('form.account.title')}</h2>
            <UserAccountFormFields/>
            <div className={styles.buttonContainer}>
              <ContainedButton
                label={t('form.account.update')}
                isDisabled={isSubmitting}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Formik>
    }
  </div>
}
