import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/Order/OrderForm/ClientDataForm/ClientDataForm.module.css'
import { ReadOnlyTextField } from 'components/Shared/ReadOnlyTextField'
import { fetchDriver } from 'services'

export function InternalDriver ({ driverId }) {
  const { t } = useTranslation()

  const [driver, setDriver] = useState(null)

  useEffect(() => {
    if(driverId) {
      fetchDriver(driverId).then(driver => setDriver(driver))
    } else {
      setDriver(null)
    }
  }, [driverId])


  return (
    <>
      <div className={styles.internalDriverContainer}>
        <ReadOnlyTextField
          variantValue={'filled'}
          value={driver ? driver.firstName : ''}
          label={t('form.order.carrier.driver.firstName')}
        />
        <ReadOnlyTextField
          variantValue={'filled'}
          value={driver ? driver.lastName : ''}
          label={t('form.order.carrier.driver.lastName')}
        />
        <ReadOnlyTextField
          variantValue={'filled'}
          value={driver ? driver.phoneNumber : ''}
          label={t('form.order.carrier.driver.phoneNumber')}
        />
      </div>
    </>
  )
}
