import React, { useEffect, useMemo, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useTranslation } from 'react-i18next'
import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg'
import { Loader } from 'components/Shared/Loader'
import { fetchFinishedOrdersCount, getOrderAllowedStatusChanges, setOrderStatus, } from 'services/OrderService'
import Confetti from 'components/Order/OrderDetails/OrderStatusSelectField/Confetti'
import { StatusChangeDialog } from './ConfirmStatusChangeDialog'
import styles from './OrderStatusSelectField.module.css'
import { HTTP_403_ERROR, HTTP_404_ERROR, HTTP_422_ERROR } from 'Constants'
import { useErrorHandling } from 'context/errorHandling'
import { INVALID_STATUS_CHANGE } from 'services/errorCodes'

export function OrderStatusSelectField ({ order, onStatusChange, actions }) {
  const [allowedStatuses, setAllowedStatuses] = useState(null)
  const [changeStatusDialogOpen, setChangeStatusDialogOpen] = useState(false)
  const { t } = useTranslation()
  const [isConfettiShown, setConfettiShown] = useState(false)
  const [newStatus, setNewStatus] = useState(false)
  const [minNewStatusDateTime, setMinNewStatusDateTime] = useState(null)

  const currentOrderStatus = order?.status
  const { handleError } = useErrorHandling()

  const statusChanges = useMemo(() => actions.filter(action => action.actionType === 'ORDER_STATUS_CHANGE'), [actions])

  useEffect(() => {
    getOrderAllowedStatusChanges(order.id)
      .then(response => {
        setAllowedStatuses(response.statuses.reverse())
        setMinNewStatusDateTime(response.minNewStatusDateTime)
      })
  }, [order.id, actions])

  function changeStatus (result) {
    if (result.confirmed) {
      setOrderStatus(order.id, newStatus, result)
        .then(value => {
            onStatusChange({
              status: value.status,
              deliveryPointNumber: value.deliveryPointPosition
            })
            if (value.status === 'FINISHED') {
              fetchFinishedOrdersCount()
                .then(count => {
                  if (count > 0 && count % 100 === 0) {
                    setConfettiShown(true)
                  }
                })
            }
          },
        )
        .catch(error => {
          const errorCode = error?.response?.data?.errorCode;
          handleError(error, {
            [HTTP_403_ERROR]: 'notifications.error.noOrderStatusChangePrivileges',
            [HTTP_404_ERROR]: 'notifications.error.orderNotFound',
            [HTTP_422_ERROR]: errorCode === 'DRIVER_REQUIRED' ?
              'notifications.error.driverNotFound' :
              errorCode === 'UNSUPPORTED_STATUS' ?
                'notifications.error.unsupportedStatus' :
                errorCode === INVALID_STATUS_CHANGE ?
                  'notifications.error.invalidStatusChange' : 'notifications.error.orderStatusChange'
          })
        })
    }
  }

  return (
    <>
      <StatusChangeDialog open={changeStatusDialogOpen}
                          order={order}
                          newStatus={newStatus}
                          statusChanges={statusChanges}
                          minNewStatusDateTime={minNewStatusDateTime}
                          onClose={(result) => {
                            setChangeStatusDialogOpen(false)
                            changeStatus(result)
                          }}/>
      {isConfettiShown && <Confetti/>}
      {allowedStatuses && currentOrderStatus ? <Autocomplete
        id="OrderStatusSelectField"
        options={allowedStatuses}
        disableClearable
        getOptionLabel={(option) => t(
          `order.details.history.actions.orderStatus.${option.status}`) +
          (option.deliveryPointNumber
            ? ` ${option.deliveryPointNumber}`
            : '')}
        value={currentOrderStatus}
        onChange={(e, newStatus) => {
          setNewStatus(newStatus)
          setChangeStatusDialogOpen(true)
        }}
        noOptionsText={t('form.select.noOptionsText')}
        renderInput={params => (
          <TextField
            {...params}
            label={t('order.details.history.actions.orderStatus.label')}
            variant="filled"
            fullWidth
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            className={styles.orderSelect}
          />
        )}
        popupIcon={<DropdownIcon/>}
      /> : <Loader/>}
    </>
  )
}
