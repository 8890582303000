import React, { useMemo, useRef } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from 'components/Shared/PrimaryButton'
import { dateTime } from 'utils/form'
import { Form, Formik } from 'formik'
import { FieldComponent } from 'components/Shared/FieldComponent'
import * as Yup from 'yup'
import { parseMomentFromLocalDateTimeField, toDisplayDateTime, toISOZonedDateTime } from 'utils/date'
import { triggerFormSubmit } from 'utils/utils'
import moment from 'moment'
import {
  ApproveCargoSchema,
  DeliveryPointCargoApproveForm
} from "components/Order/OrderDetails/DeliveryPointCargoApproveForm";
import {
  isLoaded,
  isUnloaded,
  mapToCargoInitialValues
} from "components/Order/OrderDetails/DeliveryPointCargoApproveForm/deliveryPointCargoHelper";
import styles from './HistoryTabs.module.css'
import DialogContentText from "@material-ui/core/DialogContentText";

export const UpdateStatusChangeSchema = Yup.object().shape({
  creationDateTime: dateTime()
    .test(
      'date-time-not-in-future',
      'form.validation.dateFromFuture',
      value => {
        if (value) {
          const parsedDate = parseMomentFromLocalDateTimeField(value)
          return parsedDate.isValid() && parsedDate.isSameOrBefore(moment().format())
        }
        return true
      })
    .required('form.validation.required')
})

export default function UpdateStatusChangeDialog ({ statusChange, order, onClose }) {
  const { t } = useTranslation()

  const formRef = useRef()

  const isOpen = !!statusChange

  const initialFormValues = useMemo(() => {
    const cargoInitialValues = statusChange.deliveryPointCargo ? mapToCargoInitialValues(statusChange) : {}
    return {
      ...cargoInitialValues,
      creationDateTime: statusChange ? toDisplayDateTime(statusChange.creationDateTime) : '',
      cancellationCause: statusChange.cancellationCause
    }
  }, [statusChange])

  const handleDismiss = () => {
    onClose(null)
  }

  function handleFormSubmit (values) {
    onClose({
      ...values,
      creationDateTime: toISOZonedDateTime(values.creationDateTime)
    })
  }

  const showTextField = useMemo(() => {
    return statusChange.status === 'CANCELLED'
  }, [statusChange])

  const showCargoFields = useMemo(() => {
    return statusChange.approved && statusChange.status && (isLoaded(statusChange) || isUnloaded(statusChange))
  }, [statusChange])

  return (
    <Dialog
      open={isOpen}
      onClose={handleDismiss}
      maxWidth={'md'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContentText className={styles.contentContainer}>
        {t('order.details.history.actions.edit')}
      </DialogContentText>
      <DialogContent>
        <div className={'p-3'}>
          <Formik
            innerRef={formRef}
            initialValues={initialFormValues}
            enableReinitialize
            validationSchema={statusChange.deliveryPointCargo ?
              UpdateStatusChangeSchema.concat(ApproveCargoSchema) : UpdateStatusChangeSchema}
            onSubmit={values => handleFormSubmit(values)}>
            {
              () => (
                <Form>
                  <div className={'m-auto text-center'}>
                    <FieldComponent
                      fieldName='creationDateTime'
                      label='form.orderStatusChange.creationDateTime'/>
                    {showTextField && <FieldComponent fieldName={'cancellationCause'}
                                                      label={'form.orderStatusChange.cancellationCause'}
                                                      multiline/>
                    }
                    {showCargoFields && <DeliveryPointCargoApproveForm order={order}
                                                                       action={statusChange}/>
                    }
                  </div>
                </Form>
              )
            }
          </Formik>
        </div>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          onClick={handleDismiss}
          label={'notifications.back'}
        />
        <PrimaryButton
          onClick={triggerFormSubmit(formRef)}
          label='notifications.confirm'
        />
      </DialogActions>
    </Dialog>
  )
}
