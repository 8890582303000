import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Table, { FilterType } from 'components/Shared/Table'
import driverStatusOptions from 'constants/driverStatusOptions'
import { Loader } from 'components/Shared/Loader'
import { ErrorDialog } from 'components/Shared/Dialogs/ErrorDialog'
import { RelatedOrdersErrorMessage } from 'components/Shared/Dialogs/DialogContents'
import { deleteCar, fetchCars } from 'services/CarService'
import { fetchInternalCarriers } from 'services/CarrierService'
import { useErrorHandling } from 'context/errorHandling'
import { HTTP_404_ERROR } from "Constants";

export function CarsTable () {
  const [columnDefinitions, setColumnDefinitions] = useState()
  const [relatedOrdersErrors, setRelatedOrdersErrors] = useState([])
  const { handleError } = useErrorHandling()

  const history = useHistory()

  useEffect(() => {
    fetchInternalCarriers()
      .then(carriers => {
        let carriersOptions = carriers.map(carrier => ({
          label: carrier.name,
          value: carrier.id
        }))

        initializeColumnDefinitions(carriersOptions)
      })
      .catch(handleError)
  }, [handleError])

  const addCar = () => {
    history.push('/cars/create')
  }

  const copyCar = row => {
    history.push({
      pathname: '/cars/create',
      state: { idOfCopiedCar: row.id }
    })
  }

  const showCarDetails = (row) => {
    history.push('/cars/details/' + row.id)
  }

  const editCar = (row) => {
    history.push('/cars/edit/' + row.id)
  }

  const onDeleteError = (error) => {
    if (error?.response?.data?.data?.ordersId) {
      setRelatedOrdersErrors(error.response.data.data.ordersId)
    } else {
      handleError(error, {
        [HTTP_404_ERROR]: 'notifications.error.delete'
      }).then(() => setRelatedOrdersErrors([]))
    }
  }

  return (
    columnDefinitions ?
      <>
        <Table title={'car.title'}
               addButton={{
                 label: 'carsTable.addButtonLabel',
                 onClick: addCar
               }}
               fetchData={fetchCars}
               columns={columnDefinitions}
               tableKey={'cars'}
               menu={{
                 edit: {
                   onClick: editCar
                 },
                 copy: {
                   onClick: copyCar
                 },
                 delete: {
                   onDelete: deleteCar,
                   onDeleteError: onDeleteError,
                   deleteDialog: {
                     content: 'carsTable.deleteDialog.content',
                     title: 'carsTable.deleteDialog.title'
                   }
                 }
               }}
               onRowClick={showCarDetails}/>
        <ErrorDialog
          open={!!relatedOrdersErrors && relatedOrdersErrors.length > 0}
          onClose={() => {
            setRelatedOrdersErrors([])
          }}
          content={<RelatedOrdersErrorMessage idList={relatedOrdersErrors}/>}
        />
      </>
      : <Loader/>
  )

  function initializeColumnDefinitions (carriers) {
    setColumnDefinitions([
      {
        id: 'driverStatuses',
        label: 'driver.status.title',
        path: 'driver.status',
        filterType: FilterType.Select,
        selectOptions: driverStatusOptions,
        statusToIndicatorColorMap: {
          TRAFFIC_JAM: '#FFEE18',
          ACCIDENT: '#ED1C24',
          AVAILABLE: '#65D609',
          UNAVAILABLE: '#030B6F'
        }
      },
      {
        id: 'registrationNumber',
        label: 'car.registrationNumber',
        path: 'registrationNumber',
        filterType: FilterType.Text
      },
      {
        id: 'brand',
        label: 'car.brand',
        path: 'brand',
        filterType: FilterType.Text
      },
      {
        id: 'model',
        label: 'car.model',
        path: 'model',
        filterType: FilterType.Text
      },
      {
        id: 'trailerRegistrationNumber',
        label: 'car.trailerRegistrationNumber',
        path: 'trailerRegistrationNumber',
        filterType: FilterType.Text
      },
      {
        id: 'loadingSpace',
        label: 'car.loadingSpace',
        path: 'loadingSpace',
        filterType: FilterType.Text
      },
      {
        id: 'capacity',
        label: 'car.capacity',
        path: 'capacity',
        filterType: FilterType.Text
      },
      {
        id: 'type',
        label: 'car.type',
        path: 'type',
        filterType: FilterType.Text
      },
      {
        id: 'driverName',
        label: 'car.driver.name',
        path: 'driver.name',
        filterType: FilterType.Text
      },
      {
        id: 'phoneNumber',
        label: 'car.driver.phoneNumber',
        path: 'driver.phoneNumber',
        filterType: FilterType.Text
      },
      {
        id: 'carrierIds',
        label: 'car.carrier',
        path: 'carrierId',
        filterType: FilterType.Select,
        selectOptions: carriers
      }
    ])
  }
}
