import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { ReactComponent as ErrorIcon } from 'assets/fields/error.svg'
import styles from 'components/Shared/Dialogs/ErrorDialog/ErrorDialog.module.css'
import { PrimaryButton } from 'components/Shared/PrimaryButton'

export function ErrorDialog ({ open, content, onClose }) {
  const [animateIcon, setAnimateIcon] = useState(false)

  const handleAnimation = () => {
    setAnimateIcon(!animateIcon)
  }

  const handleClose = (result) => event => {
    onClose(result)
    handleAnimation()
    event.stopPropagation()
  }

  const handleClick = e => {
    e.stopPropagation()
  }

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        onEnter={handleAnimation}
        onClose={handleClose(false)}
        onClick={handleClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className={styles.iconContainer}>
            <ErrorIcon className={animateIcon ? styles.errorIconAnimation : styles.errorIcon}/>
          </div>
          <div className={styles.contentContainer} id="alert-dialog-description">
            {content}
          </div>
        </DialogContent>
        <DialogActions
          className={styles.dialogActions}
          disableSpacing
        >
          <PrimaryButton
            className={styles.dialogButton}
            onClick={handleClose(true)}
            label={'notifications.confirm'}
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}
