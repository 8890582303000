import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckIcon } from 'assets/icons/check-copy-2.svg'
import styles from 'components/Shared/Dialogs/SuccessDialog/SucessDialog.module.css'
import { PrimaryButton } from 'components/Shared/PrimaryButton'

export function SuccessDialog ({ open, content, onClose }) {
  const { t } = useTranslation()
  const [animateIcon, setAnimateIcon] = useState(false)

  const handleAnimation = () => {
    setAnimateIcon(!animateIcon)
  }

  const handleClose = (result) => () => {
    onClose(result)
    handleAnimation()
  }

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        onEntered={handleAnimation}
        onClose={handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className={styles.iconContainer}>
            <CheckIcon className={styles.checkIcon}/>
            <CheckIcon className={animateIcon
              ?
              styles.checkIconShadowTranslated
              :
              styles.checkIconShadow
            }/>
          </div>
          <DialogContentText className={styles.contentContainer} id="alert-dialog-description">
            {t(content)}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          className={styles.dialogActions}
          disableSpacing
        >
          <PrimaryButton
            className={styles.dialogButton}
            onClick={handleClose(true)}
            label={'notifications.confirm'}
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}
