import { Subject } from 'rxjs'
import { Client } from '@stomp/stompjs'

const { REACT_APP_WEBSOCKET_API } = process.env

const onConnectTopic = new Subject()
export const onConnectObservable = onConnectTopic.asObservable()

const onDisconnectTopic = new Subject()
export const onDisconnectObservable = onDisconnectTopic.asObservable()

export const client = new Client({
  brokerURL: REACT_APP_WEBSOCKET_API,
  debug: msg => console.log(msg),
  onConnect: receipt => onConnectTopic.next(receipt),
  onWebSocketClose: evt => onDisconnectTopic.next(evt)
})

export function isConnected () {
  return client.connected
}

export function connect (authToken) {
  client.connectHeaders = {
    Authorization: `Bearer ${authToken}`
  }
  client.activate()
}

export function disconnect () {
  client.deactivate()
}
