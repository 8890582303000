import React, { useEffect, useState } from 'react'
import {
  mapDeliveryPointToFormValues,
  mapFormValuesToRequestBody,
  OrderForm
} from 'components/Order/OrderForm/OrderForm'
import { editOrder, fetchOrder } from 'services/OrderService'
import { SuccessDialog } from 'components/Shared/Dialogs/SuccessDialog'
import { useHistory } from 'react-router-dom'
import { HTTP_404_ERROR, HTTP_422_ERROR } from 'Constants'
import { useErrorHandling } from 'context/errorHandling'
import {
  DISPATCHER_NOT_FOUND,
  FORWARDER_NOT_FOUND,
  INVALID_DELIVERY_METHOD,
  INVALID_DELIVERY_POINTS
} from 'services/errorCodes'
import ApiError from 'constants/ApiError'
import { useNavigatePrevent } from 'context/navigatePrevent'

export function EditOrder (props) {
  const orderId = props.match.params.orderId
  const history = useHistory()
  const { handleError } = useErrorHandling()

  const [attachments, setAttachments] = useState(null)
  const [initialValues, setInitialValues] = useState(null)
  const [editedDialogOpen, setEditedDialogOpen] = useState(false)

  const { setNavigationUnlocked, setNavigationBlocked } = useNavigatePrevent()

  useEffect(() => {
      setNavigationBlocked()
    }, [setNavigationBlocked]
  )

  function initializeValues ({ attachments, loadingDeliveryPoints, unloadingDeliveryPoints, deliveryMethod, ...rest }) {
    return {
      attachments: attachments,
      carrier: deliveryMethod.internal
        ? {
          id: deliveryMethod.internal.carrierId,
          isInternal: true,
          internalCar: {
            carId: deliveryMethod.internal.carId,
            driverId: deliveryMethod.internal.driverId
          },
          externalCar: null
        }
        : {
          id: deliveryMethod.external.carrierId,
          isInternal: false,
          externalCar: {
            carrierName: deliveryMethod.external.carrierName,
            carRegistrationNumber: deliveryMethod.external.carRegistrationNumber,
            driverFirstName: deliveryMethod.external.driverFirstName,
            driverLastName: deliveryMethod.external.driverLastName,
            driverPhoneNumber: deliveryMethod.external.driverPhoneNumber
          },
          internalCar: null
        },
      loadingDeliveryPoints: loadingDeliveryPoints.map(
        mapDeliveryPointToFormValues),
      unloadingDeliveryPoints: unloadingDeliveryPoints.map(
        mapDeliveryPointToFormValues),
      ...rest
    }
  }

  useEffect(() => {
      fetchOrder(props.match.params.orderId)
        .then(response => {
          setAttachments(response.attachments)
          setInitialValues(initializeValues(response))
        })
        .catch(error => handleError(error, {
          [HTTP_404_ERROR]: 'notifications.error.orderNotFound'
        }).then(() => {
            setNavigationUnlocked()
            history.push(`/orders`)
          }
        ))
    }, [props.match.params.orderId, handleError, history, setNavigationUnlocked]
  )

  const handleSubmit = (values) => {
    const request = mapFormValuesToRequestBody(values)
    return editOrder(request)
      .then(() => setEditedDialogOpen(true))
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode
        handleError(error, {
          [HTTP_404_ERROR]: 'notifications.error.editOrder',
          [HTTP_422_ERROR]: errorCode === INVALID_DELIVERY_METHOD ?
            'notifications.error.invalidDeliveryMethod' :
            errorCode === INVALID_DELIVERY_POINTS ?
              'notifications.error.invalidDeliveryPoints' :
              errorCode === FORWARDER_NOT_FOUND ?
                'notifications.error.forwarderNotFound' :
                errorCode === DISPATCHER_NOT_FOUND ?
                  'notifications.error.dispatcherNotFound' :
                  ApiError.UnprocessableEntity
        })
      })
  }

  return (attachments && initialValues && (
    <>
      <SuccessDialog open={editedDialogOpen}
                     onClose={() => {
                       setNavigationUnlocked()
                       setEditedDialogOpen(false)
                       history.push(`/orders/details/${orderId}`)
                     }} content={'notifications.success.updatedOrder'}/>
      <OrderForm initialAttachments={attachments} initialValues={initialValues}
                 onSubmit={handleSubmit} title={'form.order.edit'}>
      </OrderForm>
    </>
  ))
}
