import wrappingTypes from "components/Order/OrderDetails/DeliveryPointCargoApproveForm/wrappingTypes.json";

export const getDeliveryPointCargo = (order, action) => {
  let deliveryPoint = null
  if (isLoaded(action)) {
    deliveryPoint = order.loadingDeliveryPoints.find(ldp => ldp.positionInOrder === action.deliveryPointPosition)
  } else if (isUnloaded(action)) {
    deliveryPoint = order.unloadingDeliveryPoints.find(udp => udp.positionInOrder === action.deliveryPointPosition)
  }
  if (deliveryPoint) {
    const cargoDetails = deliveryPoint.cargoDetails
    return {
      'quantity': cargoDetails.quantity,
      'weight': cargoDetails.weight,
      'wrappingType': cargoDetails.wrappingType,
      'responsiblePerson': cargoDetails.dispenser,
      'signed': true
    }
  }
}

export const isLoaded = (action) => action.status === 'LOADED'

export const isUnloaded = (action) => action.status === 'UNLOADED'

export const mapToCargoInitialValues = (action) => {
  let cargoBuilder = {}
  if (isLoaded(action) || isUnloaded(action)) {
    cargoBuilder = {
      ...cargoBuilder,
      quantity: action.deliveryPointCargo.quantity,
      weight: action.deliveryPointCargo.weight,
      wrappingType: wrappingTypes.find(wt => wt.id === action.deliveryPointCargo.wrappingType)?.id || 'ELEMENT'
    }
  }
  if (isUnloaded(action)) {
    cargoBuilder = {
      ...cargoBuilder,
      responsiblePerson: action.deliveryPointCargo.responsiblePerson,
      signed: action.deliveryPointCargo.signed
    }
  }
  return {
    cargo: cargoBuilder
  }
}
