import React, { useMemo } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from 'components/Shared/PrimaryButton'
import DialogContentText from '@material-ui/core/DialogContentText'
import styles from 'components/Order/OrderDetails/HistoryTabs/HistoryTabs.module.css'
import wrappingTypes from 'components/Order/OrderDetails/DeliveryPointCargoApproveForm/wrappingTypes'
import {
  isLoaded,
  isUnloaded
} from 'components/Order/OrderDetails/DeliveryPointCargoApproveForm/deliveryPointCargoHelper'
import { ReadOnlyTextField } from 'components/Shared/ReadOnlyTextField'
import { ReadOnlyCheckbox } from 'components/Shared/ReadOnlyCheckbox'

export function ShowCargoDialog ({ action, onClose }) {

  const { t } = useTranslation()
  const wrappingType = useMemo(() => (wrappingTypes.find(wt => wt.id === action.deliveryPointCargo.wrappingType)?.label), [action])

  return (
    <Dialog
      fullWidth
      open={true}
      maxWidth={'sm'}
      onClose={onClose}
    >
      <DialogContentText className={styles.contentContainer}>
        {isLoaded(action) ? t('form.deliveryPointCargo.title.loaded') : t('form.deliveryPointCargo.title.unloaded')}
      </DialogContentText>
      <DialogContent>
        <div className='m-auto text-center align-items-center d-flex flex-column'>
          <>
            <ReadOnlyTextField
              className="row"
              clearDisabled
              label={'form.deliveryPointCargo.quantity'}
              value={action.deliveryPointCargo.quantity}/>
            <ReadOnlyTextField
              clearDisabled
              label={'form.deliveryPointCargo.weight'}
              value={action.deliveryPointCargo.weight}/>
            <ReadOnlyTextField
              clearDisabled
              label={'form.deliveryPointCargo.wrappingType'}
              value={t(wrappingType)}/>
            {isUnloaded(action) &&
            <>
              <ReadOnlyTextField
                clearDisabled
                label={'form.deliveryPointCargo.responsiblePerson'}
                value={action.deliveryPointCargo.responsiblePerson}/>
              <ReadOnlyCheckbox
                checked={action.deliveryPointCargo.signed}
                label={t('form.deliveryPointCargo.signed')}/>
            </>
            }
          </>
        </div>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          onClick={onClose}
          label={'notifications.ok'}
        />
      </DialogActions>
    </Dialog>
  )
}
