import React, { createRef } from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { useTranslation } from 'react-i18next'
import optionalFeatures from 'components/Order/orderOptionalFeatures'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from 'components/Order/OrderDetails/DeliveryPointExpansionPanel/DeliveryPointExpansionPanel.module.css'
import { ReactComponent as ExpandIcon } from 'assets/icons/dropdown.svg'
import moment from 'moment'
import { ReadOnlyTextField } from 'components/Shared/ReadOnlyTextField'
import { getLabelFromArrayByParameter } from 'components/Order/OrderDetails/Utils'
import { ReadOnlyCheckbox } from 'components/Shared/ReadOnlyCheckbox'

const useStyles = makeStyles({
  root: {
    '&:before': {
      height: 0
    },
    boxShadow: 'none',
    backgroundColor: '#FFF'
  },
  dateFieldInput: {
    paddingLeft: '6px',
    paddingRight: '6px'
  }
})

function formatDateTime (dateTime) {
  return moment(dateTime).format('YYYY-MM-DD HH:mm')
}

export function DeliveryPointExpansionPanel ({ deliveryPoint, index, title }) {
  const myRef = createRef()

  const { t } = useTranslation()
  const classes = useStyles()

  function handleChange (event, isExpanded) {
    if (isExpanded) {
      const timer = setTimeout(() => {
        window.scrollTo({
          top: myRef.current.offsetTop,
          behavior: 'smooth'
        })
        clearTimeout(timer)
      }, 200)
    }
  }

  return (
    <Accordion
      className={classes.root}
      onChange={handleChange}
      ref={myRef}
    >
      <AccordionSummary
        expandIcon={<ExpandIcon/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={styles.titleContainer}>
          <div className={styles.indexBox}>{index + 1}</div>
          <div className={styles.titleBox}>{t(title)}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.row}>
          <div className={styles.col}>
            <ReadOnlyTextField clearDisabled label={'order.details.deliveryPoint.companyName'}
                               value={deliveryPoint.companyName}/>
            <div style={{ flexWrap: 'nowrap' }} className={styles.rowInColumn}>
              <ReadOnlyTextField clearDisabled label={'order.details.deliveryPoint.fromDate'}
                                 className={styles.halfSizeTextField} InputClass={classes.dateFieldInput}
                                 value={formatDateTime(deliveryPoint.fromDateTime)}/>
              <ReadOnlyTextField clearDisabled label={'order.details.deliveryPoint.toDate'}
                                 className={styles.halfSizeTextField} InputClass={classes.dateFieldInput}
                                 value={formatDateTime(deliveryPoint.toDateTime)}/>
            </div>
            <ReadOnlyTextField clearDisabled label={'order.details.deliveryPoint.address'}
                               value={deliveryPoint.address}/>
            <ReadOnlyTextField clearDisabled label={'order.details.deliveryPoint.cityName'}
                               value={deliveryPoint.cityName}/>
            <div style={{ flexWrap: 'nowrap' }} className={styles.rowInColumn}>
              <ReadOnlyTextField clearDisabled label={'order.details.deliveryPoint.country'}
                                 value={t(`countries.${deliveryPoint.country}`)} className={styles.halfSizeTextField}/>
              <ReadOnlyTextField clearDisabled label={'order.details.deliveryPoint.zipCode'}
                                 value={deliveryPoint.zipCode} className={styles.halfSizeTextField}/>
            </div>
          </div>
          <div className={styles.col}>
            <ReadOnlyTextField clearDisabled label={'order.details.deliveryPoint.cargo.name'}
                               value={deliveryPoint.cargoDetails.name}/>
            <ReadOnlyTextField clearDisabled label={'order.details.deliveryPoint.cargo.wrappingType'}
                               value={deliveryPoint.cargoDetails.wrappingType}/>
            <div style={{ flexWrap: 'nowrap' }} className={styles.rowInColumn}>
              <ReadOnlyTextField clearDisabled label={'order.details.deliveryPoint.cargo.quantity'}
                                 value={deliveryPoint.cargoDetails.quantity} className={styles.halfSizeTextField}/>
              <ReadOnlyTextField clearDisabled label={'order.details.deliveryPoint.cargo.weight'}
                                 value={deliveryPoint.cargoDetails.weight} className={styles.halfSizeTextField}/>
            </div>
            <ReadOnlyTextField clearDisabled label={'order.details.deliveryPoint.cargo.dispenser'}
                               value={deliveryPoint.cargoDetails.dispenser}/>
            <ReadOnlyTextField clearDisabled label={'order.details.deliveryPoint.cargo.additionalInfo'}
                               value={deliveryPoint.additionalInfo} multiline/>
          </div>
          <div className={styles.col}>
            {t('order.details.deliveryPoint.cargo.optionalFeatures.title')}
            {deliveryPoint.optionalFeatures.map(feature => <ReadOnlyCheckbox
              key={feature}
              label={
                'order.details.deliveryPoint.cargo.optionalFeatures.' +
                t(getLabelFromArrayByParameter(feature, optionalFeatures, 'id'))}/>)}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
