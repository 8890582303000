import React, { useState } from 'react'
import { DatePickerInline } from 'components/Shared/DatePickerInline'
import { findFilter, setOrDropFilter } from 'components/Shared/Table/TableFiltersRow/utils'
import moment from 'moment'
import { useTableFiltersStyles } from 'components/Shared/Table/TableFiltersRow/styles'

export default ({ column, filters, onFiltersChanged }) => {
  const classes = useTableFiltersStyles()

  const filterName = column.filterName || column.id
  const filter = findFilter(filters, filterName)

  const defaultValue = filter?.value ? new Date(filter.value) : null

  const [value, setValue] = useState(defaultValue)

  const handleChange = newValue => {
    setValue(newValue)
    const filterValue = newValue ? moment(newValue).format('YYYY-MM-DD') : null
    const newFilters = setOrDropFilter(filters, filterName, filterValue)
    onFiltersChanged(newFilters)
  }

  return (
    <div className={classes.dateInput}>
      <DatePickerInline
        placeholder={'form.select.title'}
        value={value}
        format={'DD-MM-YYYY'}
        className={'tableFilterWidth'}
        onChange={handleChange}
      />
    </div>
  )
}
