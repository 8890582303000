import React from 'react'
import { useFormikContext } from 'formik'
import styles from 'components/Order/OrderForm/ClientDataForm/ClientDataForm.module.css'
import { useTranslation } from 'react-i18next'
import { MailField } from 'components/Order/OrderForm/ClientDataForm/MailField'
import { FieldComponent } from 'components/Shared/FieldComponent'

export function ClientDataForm () {
  const { values } = useFormikContext()

  const { t } = useTranslation()
  return <div className={styles.container}>
    <h2 className={styles.title}>{t('form.order.clientData.title')}</h2>
    {values.readableId &&
    <FieldComponent disabled fieldName={'readableId'} label={'form.order.clientData.orderNumber'}/>
    }
    <FieldComponent fieldName={'referenceId'} label={'form.order.clientData.referenceId'}/>
    <FieldComponent fieldName={'contractorInfo.name'} label={'form.order.clientData.name'}/>
    <div className={styles.mailField}>
      <MailField
        name="contractorInfo.emails"
        placeholder={t('form.order.clientData.emails')}
      />
    </div>
    <div className={styles.mailField}>
      <MailField
        name="contractorInfo.bccEmails"
        placeholder={t('form.order.clientData.bccEmails')}
      />
    </div>
  </div>
}
