import React from 'react'
import { ReactComponent as RemoveIcon } from 'assets/icons/trash.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/add.svg'
import { ReactComponent as ShowIcon } from 'assets/icons/loupe.svg'
import IconButton from '@material-ui/core/IconButton'
import styles from 'components/Shared/FileChip/FileChip.module.css'
import { getFileExtension } from 'utils/utils'
import AccessControls from './AccessControls'
import classNames from 'classnames'
import Tooltip from '@material-ui/core/Tooltip'

export function FileChip ({ file, onRemove, onDownload, onShow, accessDetails, nameContainerClassName }) {

  return (
    <li className={styles.fileChipsContainer}>
      <Tooltip title={file.name}>
        <div className={classNames(styles.nameContainer, nameContainerClassName)}>
          <span className={styles.name}>
            {file.name.slice(0, file.name.length - 4)}
          </span>
          <span>.{getFileExtension(file.name)}</span>
        </div>
      </Tooltip>
      <div className={'flex-grow-1'}></div>
      <div>
        <AccessControls accessDetails={accessDetails}/>
        {onRemove &&
        <IconButton className={styles.button}
                    onClick={() => onRemove(file)}><RemoveIcon/></IconButton>
        }
        {onDownload &&
        <IconButton className={styles.button + ' mb-1'}
                    onClick={() => onDownload(file)}><DownloadIcon/></IconButton>
        }
        {onShow &&
        <IconButton className={styles.button}
                    onClick={() => onShow(file)}><ShowIcon/></IconButton>
        }
      </div>
    </li>
  )
}
