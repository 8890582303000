import { useTranslation } from 'react-i18next'
import styles from 'components/Shared/Table/pagination/pagination.module.css'
import Select from '@material-ui/core/Select'
import { ReactComponent as Dropdown } from 'assets/icons/dropdown.svg'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'

export default function ({ rowsPerPageOptions, onChangeRowsPerPage, rowsPerPage }) {

  const { t } = useTranslation()

  function handleChange (event) {
    onChangeRowsPerPage(event.target.value)
  }

  return <div className={styles.selectRowsNumberContainer}>
    <div className={styles.show}>{t('form.show')} </div>
    <Select value={rowsPerPage}
            onChange={handleChange}
            displayEmpty
            IconComponent={(props) => <Dropdown  {...props}/>
            }
            disableUnderline
            renderValue={value => (
              <div className={styles.selectItem}>{value}</div>
            )}>
      {rowsPerPageOptions.map(option => {
        return <MenuItem className={styles.selectItem} key={option} value={option}>{option}</MenuItem>
      })}
    </Select>
  </div>
}
