export const mapStatusBarItemToClassName = (param) => {
  switch (param.status) {
    case 'DONE': {
      return 'bubbleFull'
    }
    case 'IN_PROGRESS': {
      return 'bubbleHalf'
    }
    case 'TO_DO': {
      return 'bubbleEmpty'
    }
    default: {
      return 'bubbleEmpty'
    }
  }
}

