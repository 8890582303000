import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AvenirMedium, hoverColor } from 'styles/variables'
import withStyles from '@material-ui/core/styles/withStyles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Popper from '@material-ui/core/Popper'
import { useTranslation } from 'react-i18next'
import { dropFilter, findFilter, setOrDropFilter } from 'components/Shared/Table/TableFiltersRow/utils'
import { FormControl } from '@material-ui/core'
import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import TableFilterTooltip from 'components/Shared/Table/TableTooltip'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles({
  formControl: {
    width: '100%',
    minWidth: '142px',
    maxWidth: '150px',
  },
  filterSelect: {
    '& div': {
      '& span': {
        fontFamily: 'inherit',
        fontSize: '13px',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      },
      '& .MuiCheckbox-colorSecondary': {
        padding: '6px'
      },
      '& .MuiAutocomplete-input': {
        marginTop: '2px',
        padding: '8px 4px'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        top: '-6px'
      }
    }
  },
  menuItem: {
    width: '200px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    '& span': {
      fontFamily: AvenirMedium,
      fontSize: 15
    },
    '&:hover': {
      backgroundColor: hoverColor,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#000',
      }
    }
  }
})

const StyledAutocomplete = withStyles(() => ({
  root: {
    '& div': {
      '& input': {
        fontFamily: 'inherit',
        fontSize: 13,
      },
      '& .MuiAutocomplete-inputRoot': {
        padding: '3px 9px',
      }
    }
  },
  option: {
    padding: 0
  }
}))(Autocomplete)

const StyledPopper = ({ children, ...rest }) => (
  <Popper {...rest} style={{ width: '200px' }} placement='bottom-start'>
    {children}
  </Popper>
)

export default function SelectFilter ({ column, filters, onFiltersChanged }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const filterName = column.filterName || column.id
  const filter = findFilter(filters, filterName)

  const defaultSelectedOptions = filter
    ? column.selectOptions.filter(option => filter.value.includes(option.value))
    : []
  const [selectedOptions, setSelectedOptions] = useState(defaultSelectedOptions)
  const [clearFiltersHover, setClearFiltersHover] = useState(false)

  const handleResetClick = () => {
    setSelectedOptions([])
    const newFilters = dropFilter(filters, filterName)
    onFiltersChanged(newFilters)
  }

  const handleChange = (event, newSelectedOptions) => {
    setSelectedOptions(newSelectedOptions)
    const filterValue = newSelectedOptions.length > 0
      ? newSelectedOptions.map(opt => opt.value)
      : null
    const newFilters = setOrDropFilter(filters, filterName, filterValue)
    onFiltersChanged(newFilters)
  }

  return (
    <FormControl className={classes.formControl}>
      <StyledAutocomplete
        PopperComponent={StyledPopper}
        className={classes.filterSelect}
        variant='outlined'
        multiple
        disableClearable
        onChange={handleChange}
        options={column.selectOptions}
        disableCloseOnSelect
        getOptionLabel={(option) => t(option.label)}
        popupIcon={<DropdownIcon/>}
        value={selectedOptions}
        noOptionsText={t('form.select.noOptionsText')}
        renderOption={(option, { selected }) => (
          <div className={classes.menuItem}>
            <Checkbox checked={selected}/>
            <ListItemText primary={t(option.label)}/>
          </div>
        )}
        renderTags={(values) => {
          return values && values.length > 0 && (
            <TableFilterTooltip title={t('table.clearFilters')} arrow>
              <Checkbox checked onClick={handleResetClick}
                        onMouseOver={() => setClearFiltersHover(true)}
                        onMouseOut={() => setClearFiltersHover(false)}/>
            </TableFilterTooltip>
          )
        }}
        renderInput={(params) => {
          const joinedSelectedOptions = selectedOptions
            .map(option => t(option.label))
            .join(', ')

          return (
            <TableFilterTooltip title={clearFiltersHover ? '' : joinedSelectedOptions} arrow>
              <TextField {...params} variant="outlined" placeholder={t('form.select.title')}/>
            </TableFilterTooltip>
          )
        }}
      />
    </FormControl>
  )
}
