import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { ReactComponent as ListMenuIcon } from 'assets/icons/list-menu-button.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg'
import styles from 'components/Shared/Table/TableMenu/TableMenu.module.css'
import { useTranslation } from 'react-i18next'
import { AvenirMedium, hoverColor } from 'styles/variables'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(() => ({
  root: {
    paddingBottom: '9px',
    '& .MuiListItemIcon-root': {
      minWidth: 40
    },
    '& div': {
      marginBottom: 0,
      '& span': {
        fontFamily: AvenirMedium,
        fontSize: 15
      }
    },

    '&:hover': {
      backgroundColor: hoverColor,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#000',
      },
    },
  },
}))(MenuItem)

export function TableMenu ({
  menuOptions
}) {
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <div className={styles.menuIconContainer} onClick={handleClick}>
        <ListMenuIcon
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          className={styles.menuIcon}
        />
      </div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {
          menuOptions.edit && (
            <StyledMenuItem onClick={menuOptions.edit.onClick}>
              <ListItemIcon>
                <EditIcon/>
              </ListItemIcon>
              <ListItemText primary={t('table.menu.edit')}/>
            </StyledMenuItem>
          )
        }
        {
          menuOptions.copy && (
            <StyledMenuItem onClick={menuOptions.copy.onClick}>
              <ListItemIcon>
                <CopyIcon/>
              </ListItemIcon>
              <ListItemText primary={t('table.menu.copy')}/>
            </StyledMenuItem>
          )
        }
        {
          menuOptions.delete && (
            <StyledMenuItem onClick={menuOptions.delete.onClick}>
              <ListItemIcon>
                <TrashIcon/>
              </ListItemIcon>
              <ListItemText primary={t('table.menu.delete')}/>
            </StyledMenuItem>
          )
        }
      </StyledMenu>
    </div>
  )
}
