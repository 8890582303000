import React from 'react'
import styles from 'components/Shared/Dialogs/Dialogs.module.css'
import { useTranslation } from 'react-i18next'

export const RelatedOrdersErrorMessage = ({idList}) => {
const {t} = useTranslation()
  return (
    <>
      <p className={styles.dialogTitle}>{t('notifications.error.relatedToOrderError')}</p>
      {idList.map((id, index) =>
        <a className={styles.linkToRelated}
           key={id}
           href={`orders/details/${id}`}
           target="_blank"
           rel="noopener noreferrer"
        >
          {`${index + 1}: ${t('notifications.error.goToRelatedToOrderError')}`}
        </a>
      )}
    </>
  )
}

export const RelatedCarErrorMessage = ({id}) => {
  const { t } = useTranslation()
  return (
    <>
      <p className={styles.dialogTitle}>{t('notifications.error.relatedToCarError')}</p>
      <a className={styles.linkToRelated}
         href={`cars/details/${id}`} target="_blank"
         rel="noopener noreferrer"
      >
        {t('notifications.error.goToRelatedToCarError')}
      </a>
    </>
  )
}


