export const findFilter = (filters, key) => filters.find(f => f.key === key)

export const dropFilter = (filters, key) => filters.filter(f => f.key !== key)

export const setOrDropFilter = (filters, key, value) => {
  const withFilterDropped = dropFilter(filters, key)
  if (value) {
    const filter = { key, value }
    withFilterDropped.push(filter)
  }
  return withFilterDropped
}
