import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { DriverForm, driverSchema } from 'components/Drivers/DriverForm'
import { UserAccountFormFields, UserAccountSchema } from 'components/Users/UserAccountFormFields'
import { SuccessDialog } from 'components/Shared/Dialogs/SuccessDialog'
import { USERNAME_CONFLICT } from 'services/errorCodes'
import { createDriver } from 'services'
import { HTTP_422_ERROR } from 'Constants'
import ApiError from 'constants/ApiError'
import { useErrorHandling } from 'context/errorHandling'
import { useNavigatePrevent } from 'context/navigatePrevent'
import styles from 'components/Shared/CrudComponents/SimpleForm.module.css'

const initialValues = {
  firstName: '',
  lastName: '',
  address: '',
  phoneNumber: '',
  personalPhoneNumber: '',
  idCardNumber: '',
  idCardExpirationDate: null,
  driverLicenseNumber: '',
  driverLicenseCategory: '',
  driverLicenseExpirationDate: null,
  adrCertificateNumber: '',
  adrCertificateExpirationDate: null,
  username: '',
  password: '',
  passwordConfirmation: '',
}

export function CreateDriverForm () {
  const [createdDialogOpen, setCreatedDialogOpen] = useState(false)

  const history = useHistory()
  const { handleError } = useErrorHandling()

  const { t } = useTranslation()

  const { setNavigationUnlocked, setNavigationBlocked } = useNavigatePrevent()

  useEffect(() => {
      setNavigationBlocked()
    }, [setNavigationBlocked]
  )

  const handleSubmit = (values) =>
    createDriver({
      ...values,
    })
      .then(() => {
          setCreatedDialogOpen(true)
        },
      )
      .catch(error => handleError(error, {
        [HTTP_422_ERROR]: error?.response?.data?.errorCode === USERNAME_CONFLICT ?
          'notifications.error.usernameConflict' : ApiError.UnprocessableEntity
      }))

  return <div>
    <SuccessDialog open={createdDialogOpen}
                   onClose={() => {
                     setCreatedDialogOpen(false)
                     setNavigationUnlocked()
                     history.push('/drivers')
                   }} content={t('notifications.success.createdDriver')}/>
    <DriverForm
      initialValues={initialValues}
      validationSchema={driverSchema.concat(UserAccountSchema)}
      onSubmit={handleSubmit}
      title={t('form.driver.createDriverTitle')}
    >
      <div className={styles.container}>
        <h2>{t('form.driver.credentials')}</h2>
        <UserAccountFormFields/>
      </div>
    </DriverForm>
  </div>
}
