import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'components/Shared/DateRangeSelect/DateRangeSelect.css'
import { addDays } from 'date-fns'
import i18n from 'i18next'
import * as locales from 'react-date-range/dist/locale'
import Row from 'react-bootstrap/Row'

export function DateRangeSelect ({ defaultValue, onClose }) {
  const { t } = useTranslation()

  const [state, setState] = useState([
    {
      startDate: defaultValue && defaultValue[0] ? defaultValue[0] : new Date(),
      endDate: defaultValue && defaultValue[1] ? defaultValue[1] : addDays(new Date(), 7),
      key: 'selection'
    }
  ])

  const handleBackClick = () => onClose()

  const handleConfirmClick = () => {
    const result = [state[0].startDate, state[0].endDate]
    onClose(result)
  }

  return (
    <div className={'p-4'}>
      <Row>
        <DateRange
          onChange={item => setState([item.selection])}
          months={2}
          ranges={state}
          direction="horizontal"
          locale={locales[i18n.language]}
        />
      </Row>
      <Row>
        <Button onClick={handleBackClick} color="primary">
          {t('notifications.back')}
        </Button>
        <Button onClick={handleConfirmClick} color="primary" autoFocus>
          {t('notifications.confirm')}
        </Button>
      </Row>
    </div>
  )
}
