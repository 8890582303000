import React from 'react'
import styles from 'components/Order/OrderForm/CargoForm/CargoForm.module.css'
import { useTranslation } from 'react-i18next'
import { DeliveryPointForm } from 'components/Order/OrderForm/ShipmentDetailsForm/DeliveryPointForm'
import {
  newLoadingDeliveryPointFormValues,
  newUnloadingDeliveryPointFormValues
} from 'components/Order/OrderForm/OrderForm'

export function CargoForm ({ values, setFieldValue }) {
  const { t } = useTranslation()

  function addLoadingPoint (arrayHelpers) {
    const point = newLoadingDeliveryPointFormValues()
    arrayHelpers.push(point)
  }

  function addUnloadingPoint (arrayHelpers) {
    const loadingPointsCount = values.loadingDeliveryPoints.length
    const unloadingPointsCount = values.unloadingDeliveryPoints.length
    if (loadingPointsCount > unloadingPointsCount) {
      const correspondingLoadingDeliveryPoint = values.loadingDeliveryPoints[unloadingPointsCount]
      const defaultUnloadingPoint = newUnloadingDeliveryPointFormValues()
      const newPoint = {
        ...defaultUnloadingPoint,
        cargoDetails: {
          ...defaultUnloadingPoint.cargoDetails,
          name: correspondingLoadingDeliveryPoint.cargoDetails.name,
          wrappingType: correspondingLoadingDeliveryPoint.cargoDetails.wrappingType,
          quantity: correspondingLoadingDeliveryPoint.cargoDetails.quantity,
          weight: correspondingLoadingDeliveryPoint.cargoDetails.weight
        },
        optionalFeatures: correspondingLoadingDeliveryPoint.optionalFeatures
      }
      arrayHelpers.push(newPoint)
    } else {
      const newPoint = newUnloadingDeliveryPointFormValues()
      arrayHelpers.push(newPoint)
    }
  }

  return <div className={styles.cargoContainer}>
    <h2>{t('form.order.cargo.title')}</h2>

    <DeliveryPointForm
      orderAction={'loadingDeliveryPoints'}
      values={values}
      onDeliveryPointAddClick={addLoadingPoint}
      setFieldValue={setFieldValue}
    />
    <div className={styles.divider}/>
    <DeliveryPointForm
      orderAction={'unloadingDeliveryPoints'}
      values={values}
      onDeliveryPointAddClick={addUnloadingPoint}
      setFieldValue={setFieldValue}
    />

  </div>
}
