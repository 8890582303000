import { makeStyles } from '@material-ui/core/styles'
import { AvenirRegular, hoverColor } from 'styles/variables'

export const useRowStyles = makeStyles({
  row: {
    '&:hover': {
      backgroundColor: hoverColor + '!important',
    },
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  highlightedRow: {
    backgroundColor: hoverColor + '!important',
  },
  cell: {
    fontFamily: AvenirRegular,
    fontSize: 15,
    borderBottom: 'none',
    minWidth: 110,
    whiteSpace: 'nowrap',
  },
  cellContent: {
    display: 'flex',
  },
  menuCell: {
    minWidth: 'unset',
  },
  statusIndicatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  indicator: {
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    marginRight: '7px',
  }
})
