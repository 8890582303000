import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ExpandIcon } from 'assets/icons/dropdown.svg'
import styles from 'components/Order/OrderDetails/HistoryTabs/Tabs/Tabs.module.css'
import Collapse from '@material-ui/core/Collapse'

export default function RowsCollapse ({ itemsCount, children }) {
  const [opened, setOpened] = useState(false)
  const { t } = useTranslation()
  const ROW_HEIGHT = 48
  const AMOUNT_OF_VISIBLE_ROWS = 3 * ROW_HEIGHT

  const handleCollapse = () => setOpened(!opened)

  return (<>
    <Collapse in={opened}
              collapsedHeight={AMOUNT_OF_VISIBLE_ROWS}>
      {children}
    </Collapse>
    {itemsCount > 3 && <div className={styles.expanderBox} onClick={() => handleCollapse()}>
        <span className={styles.expandText}>
          {opened ?
            t('order.details.history.showLess')
            :
            t('order.details.history.showMore')}
        </span>
      <ExpandIcon
        className={
          opened ? styles.expandIconExpanded : styles.expandIconCollapsed
        }/>
    </div>}
  </>)
}
