const ApiError = {
  Unauthorized: 'notifications.error.unauthorized',
  Forbidden: 'notifications.error.forbidden',
  NotFound: 'notifications.error.notFound',
  Timeout: 'notifications.error.timeout',
  UnprocessableEntity: 'notifications.error.unprocessableEntity',
  InternalError: 'notifications.error.internal',
  UnknownFailure: 'notifications.error.generic',
  NetworkError: 'notifications.error.networkError',
  FileUploadFailure: 'notifications.error.fileUpload'
}

export default ApiError
