import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import makeStyles from '@material-ui/core/styles/makeStyles'
import moment from 'moment'
import { DateRangeSelect } from 'components/Shared/DateRangeSelect'

const useStyles = makeStyles(() => ({
  picker: {
    '& input': {
      padding: '12.75px',
      fontSize: 13
    }
  }
}))

export function DateRangePickerInline ({ placeholder, defaultValue, onChange }) {
  const [fieldValue, setFieldValue] = useState('')
  const [parentComponent, setParentComponent] = useState(null)

  const classes = useStyles()

  const { t } = useTranslation()

  const closePopover = () => {
    setParentComponent(null)
  }

  const resetValue = () => {
    setFieldValue('')
    onChange(null)
  }

  useEffect(() => {
    const hasInitStartDate = defaultValue && defaultValue[0]
    const hasInitEndDate = defaultValue && defaultValue[1]

    if (hasInitEndDate && hasInitStartDate) {
      const newFieldValue = moment(defaultValue[0]).format('DD.MM') + ' - '
        + moment(defaultValue[1]).format('DD.MM')
      setFieldValue(newFieldValue)
    }

  }, [defaultValue])

  const closePopoverWithResults = (result) => {
    const startMoment = moment(result[0]).startOf('day')
    const endMoment = moment(result[1]).endOf('day')
    onChange([startMoment.toDate(), endMoment.toDate()])
    setFieldValue(startMoment.format('DD.MM') + ' - ' + endMoment.format('DD.MM'))
    setParentComponent(null)
  }

  return <div>
    <TextField className={classes.picker} variant='outlined' placeholder={t(placeholder)} value={fieldValue}
               onClick={(event) => setParentComponent(event.currentTarget)}
               InputProps={{
                 endAdornment: (
                   fieldValue &&
                   <IconButton size='small'
                               onClick={(event) => {
                                 resetValue()
                                 event.stopPropagation()
                               }}>
                     <ClearIcon fontSize="inherit"/>
                   </IconButton>
                 )
               }}/>
    <Popover
      open={parentComponent !== null}
      anchorEl={parentComponent}
      onClose={closePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <DateRangeSelect defaultValue={defaultValue}
                       onClose={(result) => {
                         if (result) {
                           closePopoverWithResults(result)
                         } else {
                           closePopover()
                         }
                       }}/>
    </Popover>
  </div>
}
