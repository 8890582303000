import api from 'services/api'
import { client as websocketClient } from './SocketClient'

import { stringifyPageQuery } from 'services/utils'

export const fetchUserNotifications = (params) => {
  return api.get(`/notifications?${stringifyPageQuery(params)}`)
}

export const fetchUserNotReadNotificationsCount = () => {
  return api.get(`/notifications/not-read-count`)
}

export const markAllAsRead = () => {
  return api.put(`/notifications/mark-all-as-read`)
}

export const markAsRead = (id) => {
  return api.put(`/notifications/${id}/mark-as-read`)
}

export const markAsNotRead = (id) => {
  return api.put(`/notifications/${id}/mark-as-not-read`)
}

export function subscribeNotifications (userId, callback) {
  const sub = websocketClient.subscribe(`/topic/notifications/${userId}`,
    message => {
      const body = JSON.parse(message.body)
      callback(body)
    })
  return () => {
    sub.unsubscribe()
  }
}

export function subscribeNotificationsCount (userAccountId, callback) {
  const sub = websocketClient.subscribe(`/topic/notifications-count/${userAccountId}`,
    message => {
      const body = JSON.parse(message.body)
      callback(body)
    })
  return () => {
    sub.unsubscribe()
  }
}
