import React, { useEffect, useState } from 'react'
import styles from 'components/Contractor/ContractorView.module.css'
import { ReactComponent as LoadingIcon } from 'assets/icons/loading.svg'
import { ReactComponent as UnloadingIcon } from 'assets/icons/unloading.svg'
import { ReactComponent as CarIcon } from 'assets/icons/car-red.svg'
import { downloadFileByOrderToken, getAttachmentByToken } from 'services'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import header from 'assets/images/contractor-view-header.jpg'
import { ContainedButton } from 'components/Shared/ContainedButton'
import { FileChip } from 'components/Shared/FileChip'
import { ContractorOrderStatus } from 'components/Contractor/ContractorOrderStatus'
import { fetchOrderByToken } from 'services/OrderService'
import { AttachmentsDialog } from 'components/Shared/AttachmentsPreview'
import { HTTP_404_ERROR, HTTP_500_ERROR } from 'Constants'
import { useErrorHandling } from 'context/errorHandling'

export function ContractorView (props) {
  const [order, setOrder] = useState(null)
  const tokenString = props.match.params.orderToken
  const { t, i18n } = useTranslation()
  const [previewedAttachment, setPreviewedAttachment] = useState(null)

  const { handleError } = useErrorHandling()

  useEffect(
    () => {
      fetchOrderByToken(tokenString).then(order => {
        setOrder(order)
        //Local language change, does not override localstorage value
        i18n.changeLanguage(order.language.toLowerCase())
      })
    },
    [tokenString, i18n])

  const onDownloadAttachmentByOrderToken = (attachment, token) => {
    downloadFileByOrderToken(attachment, token)
      .catch(error => handleError(error, {
        [HTTP_404_ERROR]: 'notifications.error.fileNotFound',
        [HTTP_500_ERROR]: 'notifications.error.fileDownloadError'
      }))
  }


    return (order && <div className={styles.background}>
      <div className={styles.contractorViewWrapper}>
        <div className={styles.headerPhotoContainer}>
          <img src={header} alt='header'/>
        </div>
        <div className={styles.contentContainer}>
          <div
            className={classNames(styles.header, (order.referenceId && order.referenceId.length > 14) && styles.reduceHeaderPadding)}>
            <h1>
              {`${t('contractor.title')} ${order.readableId}`}
              {order.referenceId && ` / ${order.referenceId}`}
            </h1>
          </div>
          <div className={styles.subHeader}>
            <h3>{t('contractor.orderDetails')}</h3>
          </div>
          <div className={styles.orderDetails}>
            <div className={styles.orderDetailsLoadings}>
              <div className={styles.textWithIcon}>
                <LoadingIcon/>
                <span>{t(
                  'contractor.loading')} {`${order.firstLoadingLocation.country} ${order.firstLoadingLocation.zipCode}`}</span>
              </div>
              <div className={styles.textWithIcon}>
                <UnloadingIcon/>
                <span>{t(
                  'contractor.unloading')} {`${order.lastUnloadingLocation.country} ${order.lastUnloadingLocation.zipCode}`}</span>
              </div>
            </div>
            <div className={styles.textWithIcon}>
              <CarIcon className={styles.carIcon}/>
              <span>{t(
                'contractor.carRegistration')} {order.carRegistrationNumber}</span>
            </div>
          </div>
          <div className={styles.subHeader}>
            <h3>{t('contractor.status')}</h3>
          </div>
          <ContractorOrderStatus orderStatuses={order.progressBar}/>
          {order.attachments.length !== 0 ?
            <>
              <div className={styles.subHeader}>
                <h3>{t('contractor.downloadFiles')}</h3>
              </div>
              <div className={styles.attachments}>
                <AttachmentsDialog open={!!previewedAttachment}
                                   initAttachment={previewedAttachment}
                                   attachmentSource={attachment => getAttachmentByToken(attachment, tokenString)}
                                   attachments={order.attachments}
                                   onClose={() => setPreviewedAttachment(null)}/>
                <ul>
                  {order.attachments.map(attachment =>
                    <FileChip
                      key={attachment.filename}
                      file={{
                        id: attachment.id,
                        name: attachment.filename,
                      }}
                      onDownload={() => onDownloadAttachmentByOrderToken(attachment, tokenString)}
                      onShow={() => setPreviewedAttachment(attachment)}
                      nameContainerClassName={styles.attachmentFilename}/>
                  )}
                </ul>
              </div>
            </>
            :
            ' '
          }
        </div>
        <div className={styles.footerContainer}>
          <div className={styles.questionsWrapper}>
            <div className={styles.questionsTitle}>
              <p>{t('contractor.questionsTitle')}</p>
              <p>{t('contractor.questionsSubTitle')}</p>
            </div>
            <div className={`${styles.orderCaretakersContainer} ${!order.dispatcher ? styles.reverseRow : ''}`}>
              <div className={styles.caretaker}>
                {order.dispatcher && <>
                  <p>{t('contractor.dispatcher')}</p>
                  <p>{`${order.dispatcher.firstName} ${order.dispatcher.lastName}`}</p>
                  <a
                    href={`tel:${order.dispatcher.phoneNumber}`}>{order.dispatcher.phoneNumber}</a>
                  <a
                    href={`mailto:${order.dispatcher.email}`}>{order.dispatcher.email}</a>
                </>}
              </div>
              <div className={styles.caretaker}>
                <p>{t('contractor.forwarder')}</p>
                <p>{`${order.forwarder.firstName} ${order.forwarder.lastName}`}</p>
                <a
                  href={`tel:${order.forwarder.phoneNumber}`}>{order.forwarder.phoneNumber}</a>
                <a
                  href={`mailto:${order.forwarder.email}`}>{order.forwarder.email}</a>
              </div>
            </div>
          </div>
          <div className={styles.satisfied}>
            <p>{t('contractor.newOrderSectionTitle')}</p>
            <ContainedButton className={styles.button}
                             label={'contractor.newOrderSectionButtonLabel'}
                             onClick={() => {
                               window.location.href = `mailto:${order.forwarder.email}`
                             }}/>
          </div>
        </div>
      </div>
    </div>
  )
}




