import * as Yup from 'yup'
import { parseMomentFromLocalDateTimeField } from 'utils/date'
import moment from 'moment'

export const dateTime = () =>
  Yup.string()
    .test(
      'date-time',
      'form.validation.date',
      value => {
        if (value) {
          return parseMomentFromLocalDateTimeField(value).isValid()
        }
        return true
      }
    )

export const time = () => Yup.string()
  .test('time', 'form.validation.time', value => {
    if (!value) {
      return true
    }
    const parsed = moment(value, ['h:mm', 'H:mm'], true)
    return parsed.isValid()
  })
  .required('form.validation.required')
