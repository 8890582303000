import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { UserDetails } from 'components/Users/Details/UserDetails'
import { fetchUser } from 'services'
import { TitleBar } from 'components/Shared/CrudComponents/TitleBar'

export function AdminUserDetailsView (props) {
  const history = useHistory()
  const { t } = useTranslation()

  const editUser = () => {
    history.push('/users/edit/' + props.match.params.id)
  }

  const getTitle = (user) => (
    <TitleBar
      buttonLabel={t('user.details.edit')}
      onButtonClick={editUser}
      title={user.firstName + ' ' + user.lastName}/>
  )

  const getUserById = () => {
    return fetchUser(props.match.params.id)
  }

  return <UserDetails fetchData={getUserById} title={getTitle}/>
}
