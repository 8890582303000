import { makeStyles } from '@material-ui/core/styles'
import { AvenirMedium } from 'styles/variables'

export const useTableHeadStyles = makeStyles({
  sortLabelContainer: {
    display: 'inline-flex'
  },
  columnLabel: {
    fontFamily: AvenirMedium,
    fontSize: 13,
    fontWeight: 500,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }
})
