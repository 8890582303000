import React from 'react'
import styles from 'components/Shared/PrimaryButton/PrimaryButton.module.css'
import { useTranslation } from 'react-i18next'
import classNames from "classnames";

export function PrimaryButton ({ label, onClick, icon, disabled, className }) {
  const { t } = useTranslation()

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={'submit'}
      className={classNames(disabled ? styles.disabledButton : styles.button,
        icon ? styles.withIcon : styles.withoutIcon,
        className,
        'text-nowrap')}>
      {icon ? <span className={styles.buttonIcon}>{icon}</span> : ''}
      {t(label)}
    </button>
  )
}
