import React from 'react'
import { TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { useTableFiltersStyles } from 'components/Shared/Table/TableFiltersRow/styles'
import { findFilter, setOrDropFilter } from 'components/Shared/Table/TableFiltersRow/utils'
import { useTranslation } from 'react-i18next'

export default ({ column, filters, onFiltersChanged }) => {
  const { t } = useTranslation()

  const filterName = column.filterName || column.id
  const filter = findFilter(filters, filterName)
  const inputExternalProps = column.filterInputProps || {}
  const classes = useTableFiltersStyles()

  const handleChange = event => {
    const value = event.target.value
    const newFilters = setOrDropFilter(filters, filterName, value)
    onFiltersChanged(newFilters)
  }

  return (
    <TextField variant='outlined'
               id={column.id}
               onChange={handleChange}
               placeholder={t('form.searchField')}
               className={classes.searchInput}
               defaultValue={filter?.value || ''}
               InputProps={{
                 startAdornment: (
                   <InputAdornment position="start">
                     <SearchIcon/>
                   </InputAdornment>
                 ),
                 ...inputExternalProps,
               }}
    />
  )
}
