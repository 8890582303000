import React from 'react'
import styles from 'components/Order/OrderForm/ShipmentDetailsForm/CargoComponent/CargoComponent.module.css'
import { useTranslation } from 'react-i18next'
import { ReactComponent as UpIcon } from 'assets/icons/up.svg'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg'
import { CargoOptionalFeaturesForm } from 'components/Order/OrderForm/ShipmentDetailsForm/CargoOptionalFeaturesForm'
import { CargoMainInfoForm } from 'components/Order/OrderForm/ShipmentDetailsForm/CargoMainInfoForm'
import { DestinationForm } from 'components/Order/OrderForm/ShipmentDetailsForm/DestinationForm'

export function CargoComponent ({
  orderAction,
  index,
  name,
  values,
  arrayHelpers,
  setFieldValue
}) {
  const { t } = useTranslation()

  return <>
    <div className={styles.cargoTitle}>
      <div className={styles.titleContainer}>
        <div className={styles.indexBox}>{index + 1}</div>
        <div className={styles.titleBox}>{t(`form.order.cargo.${orderAction}`)}</div>
      </div>
      <div className={styles.line}/>
      <div className={styles.utilityButtonsContainer}>
        {index !== 0 &&
        <button
          className={styles.button}
          type="button"
          onClick={() => arrayHelpers.swap(index, index - 1)}
        >
          <UpIcon/>
        </button>
        }
        {index !== values[orderAction].length - 1 &&
        <button
          className={styles.button}
          type='button'
          onClick={() => arrayHelpers.swap(index, index + 1)}
        >
          <DownIcon/>
        </button>
        }
        <button
          className={styles.button}
          type="button"
          onClick={() => arrayHelpers.remove(index)}
        >
          <TrashIcon/>
        </button>
      </div>
    </div>
    <div className={styles.containerRow}>
      <DestinationForm
        name={name}
        setFieldValue={setFieldValue}
      />
      <CargoMainInfoForm
        name={name}
      />
      <CargoOptionalFeaturesForm
        orderAction={orderAction}
        index={index}
        name={name}
        values={values}
      />
    </div>
  </>
}
