import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Loader } from 'components/Shared/Loader'
import { EditUserAccountForm } from 'components/Users/EditUserForm/EditUserAccountForm'
import { UserForm } from 'components/Users/UserForm'
import { editUser, fetchUser } from 'services'
import { UserDetailsSchema } from '../validationSchema'
import { SuccessDialog } from 'components/Shared/Dialogs/SuccessDialog'
import internalStyles from 'components/Users/EditUserForm/EditUserForm.module.css'
import { getUserPrivilege, useAuth } from 'context/auth'
import { ADMIN, SUPER_ADMIN } from 'components/Shared/AccessControl/Privileges'
import styles from 'components/Shared/CrudComponents/SimpleForm.module.css'
import { useErrorHandling } from 'context/errorHandling'
import { HTTP_404_ERROR, HTTP_422_ERROR } from 'Constants'
import ApiError from 'constants/ApiError'
import { useNavigatePrevent } from 'context/navigatePrevent'

export function EditUserForm (props) {
  const [editedDialogOpen, setEditedDialogOpen] = useState(false)

  const [user, setUser] = useState(null)

  const history = useHistory()
  const { handleError } = useErrorHandling()

  const { authTokens } = useAuth()
  const isAdmin = authTokens && [SUPER_ADMIN, ADMIN].includes(getUserPrivilege(authTokens))

  const { setNavigationUnlocked, setNavigationBlocked } = useNavigatePrevent()

  useEffect(() => {
      setNavigationBlocked()
    }, [setNavigationBlocked]
  )

  useEffect(() => {
      fetchUser(props.match.params.userId).then(response => {
          setUser(response)
        }
      ).catch(error =>
        handleError(error, {
          [HTTP_404_ERROR]: 'notifications.error.userNotFound'
        }).then(() => {
          setNavigationUnlocked()
          history.push('/users')
        })
      )
    }, [props.match.params.userId, handleError, history, setNavigationUnlocked]
  )

  const onUserEdit = useCallback((values) => editUser({
    id: user.id,
    ...values
  })
    .then(() => setEditedDialogOpen(true))
    .catch(error =>
      handleError(error, {
        [HTTP_404_ERROR]: 'notifications.error.userNotFound',
        [HTTP_422_ERROR]: error?.response?.data?.errorCode === 'DEPARTMENT_NOT_ALLOWED_FOR_ROLE' ?
          'notifications.error.departmentNotAllowedForRole' : ApiError.UnprocessableEntity
      })
    ), [user, handleError])

  const { t } = useTranslation()

  return <div>
    <SuccessDialog open={editedDialogOpen}
                   onClose={() => {
                     setEditedDialogOpen(false)
                     setNavigationUnlocked()
                     history.push('/users')
                   }} content={t('notifications.success.editedUserDetails')}/>
    {user ? <div className={styles.containerRow}>
        <UserForm
          initialValues={{
            role: user.role,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
            email: user.email,
            branchId: user.branchId,
            departmentId: user.departmentId,
            personalPhoneNumber: user.personalPhoneNumber,
            hash: user.hash
          }}
          validationSchema={UserDetailsSchema}
          onSubmit={onUserEdit}
          title={t('form.user.editTitle')}>
          {isAdmin && <div className={internalStyles.editUserCredentials}>
            <EditUserAccountForm user={user} inEditView/>
          </div>
          }
        </UserForm>
      </div>
      :
      <Loader/>
    }
  </div>
}
