const userRolesOptions = [
  {
    'label': 'userTable.role.superAdmin',
    'value': 'SUPER_ADMIN'
  },
  {
    'label': 'userTable.role.technicalSupport',
    'value': 'TECHNICAL_SUPPORT'
  },
  {
    'label': 'userTable.role.forwarder',
    'value': 'FORWARDER'
  },
  {
    'label': 'userTable.role.dispatcher',
    'value': 'DISPATCHER'
  }
]
export default userRolesOptions
