import React, { useCallback, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import { ErrorMessage } from 'components/Shared/ErrorMessage'
import { useField } from 'formik'
import { fetchDeliveryPointAddresses } from 'services'
import { debounce } from 'lodash'

export default function ({ onCompanySelected, fieldName, label, }) {
  const [, meta, helpers] = useField(fieldName)
  const { error, touched, value } = meta
  const { setValue, setTouched } = helpers
  const { t } = useTranslation()

  const [options, setOptions] = useState([])

  function stringifyAddr (addr) {
    const countryTranslated = t(`countries.${addr.country}`)
    return `${addr.companyName}, ${addr.address}, ${addr.zipCode} ${addr.cityName}, ${countryTranslated}`
  }

  function handleChange (event, newValue) {
    setValue(newValue.companyName)
    onCompanySelected(newValue)
  }

  const getOptionsDebounced = useCallback(
    debounce(companyName => {
      fetchDeliveryPointAddresses(companyName)
        .then(setOptions)
    }, 400),
    [setOptions]
  )

  function handleInputChange (event, newValue, reason) {
    if (reason !== 'input')
      return
    setValue(newValue)
    getOptionsDebounced(newValue)
  }

  return (
    <Autocomplete
      options={options}
      getOptionLabel={stringifyAddr}
      filterOptions={opt => opt}
      value={null}
      onChange={handleChange}
      disableClearable
      noOptionsText={t('form.select.noOptionsText')}
      popupIcon={null}
      freeSolo
      inputValue={value}
      onInputChange={handleInputChange}
      renderInput={params => (
        <TextField
          {...params}
          error={!!error && touched}
          helperText={<ErrorMessage name={fieldName}/>}
          label={t(label)}
          variant="filled"
          fullWidth
          onBlur={() => setTouched(true)}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true
          }}
        />
      )}
    />
  )
}
