import React from 'react'
import { FieldArray } from 'formik'
import orderOptions from 'components/Order/orderOptionalFeatures'
import { useTranslation } from 'react-i18next'
import styles from 'components/Order/OrderForm/ShipmentDetailsForm/ShipmentDetailsForm.module.css'
import { PrimaryCheckbox } from 'components/Shared/PrimaryCheckbox'

export function CargoOptionalFeaturesForm ({ name, values, orderAction, index }) {
  const { t } = useTranslation()
  return <div>
    <FieldArray
      name={`${name}.optionalFeatures`}
      render={arrayHelpers => (
        <div className={styles.optionalFeaturesContainer}>
          <h2>{t('order.details.deliveryPoint.cargo.optionalFeatures.title')}</h2>
          {orderOptions.map(option => (
            <div key={option.id} className={styles.optionalFeatureCheckBoxContainer}>
              <label>
                <PrimaryCheckbox
                  name={`${name}.optionalFeatures`}
                  type="checkbox"
                  value={option.id}
                  checked={values[orderAction][index].optionalFeatures.includes(option.id)}
                  onChange={e => {
                    if (e.target.checked) arrayHelpers.push(option.id)
                    else {
                      const idx = values[orderAction][index].optionalFeatures.indexOf(option.id)
                      arrayHelpers.remove(idx)
                    }
                  }}
                />{' '}
                {t(`form.order.cargo.${option.label}`)}
              </label>
            </div>
          ))}
        </div>
      )}
    />
  </div>
}

