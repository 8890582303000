import React, { useState } from 'react'
import styles from 'components/Navigation/Navigation.module.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { ReactComponent as ProfileIcon } from 'assets/icons/user.svg'
import { ReactComponent as OrdersListIcon } from 'assets/icons/list.svg'
import { ReactComponent as CarsListIcon } from 'assets/icons/car.svg'
import { ReactComponent as UsersListIcon } from 'assets/icons/users-list.svg'
import { ReactComponent as DriversListIcon } from 'assets/icons/kierowca.svg'
import { ReactComponent as VislineLogoIcon } from 'assets/logos/logo.svg'
import { ReactComponent as VislineLogotypeIcon } from 'assets/logos/visline.svg'
import classNames from 'classnames'
import { ADMIN, OFFICE_USER } from 'components/Shared/AccessControl/Privileges'
import { AccessControl } from 'components/Shared/AccessControl'
import Slide from '@material-ui/core/Slide'
import { NotificationMenuSwitch, NotificationsList } from 'components/Notification'
import { LanguageSelectField } from 'components/Navigation'
import { ReactComponent as OpenIcon } from 'assets/icons/right.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/left.svg'
import { backgroundColor, primaryRed } from "styles/variables";
import {
  getNavigationExpandedFromLocalStorage,
  setNavigationExpandedInLocalStorage
} from "components/Navigation/preferences";

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    display: 'flex',
    zIndex: 1200,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(
      ['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  },
  appBarShift: {
    marginLeft: drawerWidth => drawerWidth,
    width: drawerWidth => `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(
      ['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
  },
  hide: {
    display: 'none',
  },
  docked: {
    overflowX: 'inherit',
  },
  paper: {
    overflow: 'inherit',
    "&:hover $expandCollapseIcon": {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity 0.3s, visibility 0.3s'
    }
  },
  drawer: {
    position: 'relative',
    width: drawerWidth => drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth => drawerWidth,
    transition: theme.transitions.create(
      'width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '90px',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(
      0, 1), ...theme.mixins.toolbar,
  },
  content: {
    overflowX: "hidden"
  },
  expandCollapseIcon: {
    position: 'absolute',
    right: '-10px',
    border: '1px solid',
    borderRadius: '50%',
    borderColor: primaryRed,
    backgroundColor: backgroundColor,
    cursor: 'pointer',
    width: 20,
    height: 20,
    visibility: 'hidden',
    opacity: 0,
    transition: 'opacity 0.3s, visibility 0.3s'
  }
}))

export function Navigation ({ width, open, setOpen }) {
  const classes = useStyles(width)
  const [notificationListOpen, setNotificationListOpen] = useState(false)

  const { t } = useTranslation()

  const handleDrawerOpen = () => {
    setOpen(true)
    setNavigationExpandedInLocalStorage(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
    setNotificationListOpen(false)
    setNavigationExpandedInLocalStorage(false)
  }

  const handleNotificationOpen = () => {
    setOpen(true)
    setNotificationListOpen(true)
  }

  const handleNotificationClose = () => {
    setOpen(getNavigationExpandedFromLocalStorage())
    setNotificationListOpen(false)
  }

  const currentPathname = window.location.pathname

  const currentPathHighlight = (path) => {
    return ([
      styles.iconBackground,
      {
        [styles['currentPageIconBackground']]: currentPathname.includes(path)
      }])
  }

  return (<div className={classes.root}>
      <CssBaseline/>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx(classes.paper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          }),
          docked: clsx(classes.docked)
        }}
      >
        <div className={classes.content}>
          <div className={classes.toolbar}>
            <div className={open ? styles.logoBoxOpen : styles.logoBoxClosed}>
              <div className={open ? styles.logotypeOpen : styles.logotypeClosed}>
                <VislineLogotypeIcon/>
              </div>
              <div className={styles.logo}>
                <VislineLogoIcon/>
              </div>
            </div>
          </div>
          {open ?
            <CloseIcon className={classes.expandCollapseIcon}
                       onClick={handleDrawerClose}/> :
            <OpenIcon className={classes.expandCollapseIcon}
                      onClick={handleDrawerOpen}/>}
          <NotificationMenuSwitch
            openNotifications={handleNotificationOpen}
            closeNotifications={handleNotificationClose}
            notificationsOpen={notificationListOpen}/>

          <div className='d-flex'>
            <Slide direction="right" in={!notificationListOpen} mountOnEnter
                   unmountOnExit>
              <List>
                <AccessControl allowedPrivileges={[ADMIN, OFFICE_USER]}>
                  <ListItem className={styles.listItem} button key={'orders'}
                            component={Link} to="/orders">
                    <ListItemIcon>
                      <div
                        className={classNames(currentPathHighlight('/orders'))}>
                        <OrdersListIcon className={styles.icon}/>
                      </div>
                    </ListItemIcon>
                    <ListItemText primary={t('menu.orders')}/>
                  </ListItem>
                </AccessControl>
                <AccessControl allowedPrivileges={[ADMIN, OFFICE_USER]}>
                  <ListItem className={styles.listItem} button key={'cars'}
                            component={Link} to="/cars">
                    <ListItemIcon>
                      <div className={classNames(currentPathHighlight('/cars'))}>
                        <CarsListIcon className={styles.icon}/>
                      </div>
                    </ListItemIcon>
                    <ListItemText primary={t('menu.cars')}/>
                  </ListItem>
                </AccessControl>
                <AccessControl allowedPrivileges={[ADMIN, OFFICE_USER]}>
                  <ListItem className={styles.listItem} button key={'drivers'}
                            component={Link} to="/drivers">
                    <ListItemIcon>
                      <div
                        className={classNames(currentPathHighlight('/drivers'))}>
                        <DriversListIcon className={styles.icon}/>
                      </div>
                    </ListItemIcon>
                    <ListItemText primary={t('menu.drivers')}/>
                  </ListItem>
                </AccessControl>
                <AccessControl allowedPrivileges={[ADMIN]}>
                  <ListItem className={styles.listItem} button key={'users'}
                            component={Link} to="/users">
                    <ListItemIcon>
                      <div className={classNames(currentPathHighlight('/users'))}>
                        <UsersListIcon className={styles.icon}/>
                      </div>
                    </ListItemIcon>
                    <ListItemText primary={t('menu.users')}/>
                  </ListItem>
                </AccessControl>
                <AccessControl allowedPrivileges={[ADMIN, OFFICE_USER]}>
                  <ListItem className={styles.listItem} button key={'loggedUser'}
                            component={Link} to="/my-profile">
                    <ListItemIcon>
                      <div
                        className={classNames(
                          currentPathHighlight('/my-profile'))}>
                        <ProfileIcon className={styles.icon}/>
                      </div>
                    </ListItemIcon>
                    <ListItemText
                      primary={t('menu.profile')}/>
                  </ListItem>
                </AccessControl>
              </List>
            </Slide>
            <div className='position-absolute overflow-hidden'>
              <Slide direction="left" in={notificationListOpen} mountOnEnter
                     unmountOnExit>
                <List>
                  <NotificationsList/>
                </List>
              </Slide>
            </div>
          </div>
        </div>
        <div className='my-auto'/>

        <LanguageSelectField/>

      </Drawer>
    </div>
  )
}


