import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Menu from '@material-ui/core/Menu'
import { useTranslation } from 'react-i18next'
import styles from 'components/Shared/Table/TableSortingList/TableSortingList.module.css'
import classNames from 'classnames'
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg'
import { PrimaryCheckbox } from 'components/Shared/PrimaryCheckbox'
import SortPriorityBadge from 'components/Shared/Table/SortPriorityBadge'

function DraggableListItem ({ column, index, onCheckboxCheckedChange, sortPriority }) {
  const { t } = useTranslation()

  return (
    <Draggable
      key={column.id}
      draggableId={column.id}
      index={index}>
      {(provided, snapshot) =>
        (
          <div
            className={
              classNames(
                styles.draggable,
                snapshot.isDragging ? styles.isDragging : ''
              )
            }
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}>
            <div>
              <PrimaryCheckbox
                checked={column.isVisible}
                onChange={onCheckboxCheckedChange}
              />
              <div className={styles.label}>{t(column.label)}</div>
              {sortPriority != null &&
              <SortPriorityBadge
                priority={sortPriority}/>
              }
            </div>
          </div>
        )
      }
    </Draggable>
  )
}

export function TableSortingList ({ headers, setHeaders, sort }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }

    setHeaders(reorder(
      headers,
      result.source.index,
      result.destination.index
    ))
  }

  const onVisibilityChange = (header, index) => {
    const updatedItems = [...headers]
    updatedItems[index].isVisible = !header.isVisible
    setHeaders(updatedItems)
  }

  const getSortPriority = header => {
    const index = sort.findIndex(s => s.param === header.id)
    if (index === -1) {
      return null
    }
    return index + 1
  }

  const isAnySortedColumnHidden = headers.some(header => !header.isVisible && getSortPriority(header) != null)

  return (
    <div>
      <button className={styles.openButton} onClick={handleClick}>
        <FilterIcon/>
        {isAnySortedColumnHidden && <span className={styles.sortedColumnIsHiddenIndicator}>*</span>}
      </button>
      <Menu
        id="sorting-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/*
         Removes 'Stateless function components cannot be given refs' warning
         https://github.com/react-dnd/react-dnd/issues/904
         */}
        <div>
          <DragDropContext onDragEnd={result => onDragEnd(result)}>
            <Droppable droppableId='droppableId'>
              {(provided) =>
                (
                  <div
                    className={styles.droppable}
                    ref={provided.innerRef}
                    {...provided.droppableProps}>
                    {
                      headers.map((item, index) =>
                        (
                          <DraggableListItem
                            key={item.id}
                            column={item}
                            index={index}
                            onCheckboxCheckedChange={() => onVisibilityChange(item, index)}
                            sortPriority={getSortPriority(item)}/>
                        )
                      )}
                    {provided.placeholder}
                  </div>
                )
              }
            </Droppable>
          </DragDropContext>
        </div>
      </Menu>
    </div>
  )
}
