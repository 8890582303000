import { Tooltip } from '@material-ui/core'
import { ReactComponent as Verified } from 'assets/icons/check.svg'
import styles from 'components/Shared/FileChip/AccessControls.module.css'
import { ReactComponent as DriverAccess } from 'assets/icons/wheel.svg'
import { ReactComponent as ContractorAccess } from 'assets/icons/user.svg'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const contractorAccessTooltip = (value) => value? 'order.attachment.contractorAccess':'order.attachment.contractorAccessDenied'
export const driverAccessTooltip = (value) => value? 'order.attachment.driverAccess':'order.attachment.driverAccessDenied'
export const verifiedTooltip = (value) => value? 'order.attachment.verified':'order.attachment.notVerified'

export default function AccessControls ({accessDetails}) {
  const {t} = useTranslation()
  const verifiedClick = () =>{
    accessDetails.verifiedClicked && accessDetails.verifiedClicked(!accessDetails.verified)
  }

  const contractorAccessClick = () =>{
    accessDetails.contractorAccessClicked && accessDetails.contractorAccessClicked(!accessDetails.contractorAccess)
  }

  const driverAccessClick = () => {
    accessDetails.driverAccessClicked && accessDetails.driverAccessClicked(!accessDetails.driverAccess)
  }

  function getClassName (accepted) {
    return `${styles.accessIcon} ${accepted ? styles.accepted : styles.denied} ${accessDetails.cursorPointer ? styles.cursorPointer : ''}`
  }

  return <>
    {accessDetails &&
      <Tooltip className='mx-3' title={t(verifiedTooltip(accessDetails.verified))}>
        <Verified onClick={verifiedClick}
                  className={getClassName(accessDetails.verified)}/>
      </Tooltip>}
    {accessDetails &&
      <Tooltip className='mx-3' title={t(driverAccessTooltip(accessDetails.driverAccess))}>
        <DriverAccess onClick={driverAccessClick}
                      className={getClassName(accessDetails.driverAccess)}/>
      </Tooltip>
    }
    {accessDetails &&
      <Tooltip className='mx-3' title={t(contractorAccessTooltip(accessDetails.contractorAccess))}>
        <ContractorAccess onClick={contractorAccessClick}
                          className={getClassName(accessDetails.contractorAccess)}/>
      </Tooltip>}
  </>
}
