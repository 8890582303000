export function getFiltersFromLocalStorage (tableKey) {
  const key = `${tableKey}.filters`
  const val = localStorage.getItem(key)
  return val ? JSON.parse(val) : []
}

export function saveFiltersInLocalStorage (tableKey, filters) {
  const key = `${tableKey}.filters`
  const val = JSON.stringify(filters)
  localStorage.setItem(key, val)
}

export function getColumnsVisibilityFromLocalStorage (tableKey) {
  const key = `${tableKey}.columns`
  const val = localStorage.getItem(key)
  return val ? JSON.parse(val) : null
}

export function getDefaultColumnsVisibility (columns) {
  return columns.map(col => ({
      id: col.id,
      isVisible: true,
    }),
  )
}

export function saveColumnsVisibilityInLocalStorage (tableKey, columnsVisibility) {
  const key = `${tableKey}.columns`
  const val = JSON.stringify(columnsVisibility)
  localStorage.setItem(key, val)
}

export const mergeColumnsVisibilityWithColumns = (columnsVisibility, columns) => {
  const matched = columnsVisibility
    .map(item => {
      const column = columns.find(col => col.id === item.id)
      return column
        ? ({
          ...column,
          isVisible: item.isVisible,
        })
        : null
    })
    .filter(columnWithVisibility => columnWithVisibility !== null)

  const unmatched = columns
    .filter(
      col => columnsVisibility.find(item => item.id === col.id) === undefined)
    .map(col => ({
      ...col,
      isVisible: true,
    }))

  return [...matched, ...unmatched]
}

export function getSortFromLocalStorage (tableKey, defaultSort) {
  const key = `${tableKey}.sort`
  const val = localStorage.getItem(key)
  return val ? JSON.parse(val) : defaultSort
}

export function saveSortInLocalStorage (tableKey, sort) {
  const key = `${tableKey}.sort`
  const val = JSON.stringify(sort)
  localStorage.setItem(key, val)
}

export function getRowsPerPageFromLocalStorage (tableKey, defaultRowsPerPage) {
  const key = `${tableKey}.rowsPerPage`
  const val = localStorage.getItem(key)
  return val ? JSON.parse(val) : defaultRowsPerPage
}

export function saveRowsPerPageInLocalStorage (tableKey, rowsPerPage) {
  const key = `${tableKey}.rowsPerPage`
  const val = JSON.stringify(rowsPerPage)
  localStorage.setItem(key, val)
}
