import React from 'react'
import styles from 'components/Order/OrderDetails/StatusBar/StatusBar.module.css'
import { useTranslation } from 'react-i18next'
import { StatusCaptions } from 'components/Order/OrderDetails/StatusBar/StatusBarItem/StatusBarItemTypes/StatusCaptions/StatusCaptions'
import { mapStatusBarItemToClassName } from 'components/Order/OrderDetails/StatusBar/helpers'

export function LoadingAndUnloadingItem ({ status, index }) {
  const { t } = useTranslation()

  return (
    <div>
      <div className={styles.progressLineWrapper}>
        <div
          className={mapStatusBarItemToClassName(status) === 'bubbleHalf'
          || mapStatusBarItemToClassName(status) === 'bubbleFull'
            ? styles.joiningLineFull : styles.joiningLine}
        />
        <div className={styles[mapStatusBarItemToClassName(status)]}>{index + 1}</div>
      </div>
      <div className={styles.statuses}>
        <StatusCaptions status={status} t={t} />
      </div>
    </div>
  )
}
