import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/Order/OrderForm/ClientDataForm/ClientDataForm.module.css'
import { FieldComponent } from 'components/Shared/FieldComponent'

export function ExternalCarForm ({ fieldName }) {
  const { t } = useTranslation()

  return (
    <div className={styles.externalDriverContainer}>
      <FieldComponent
        fieldName={`${fieldName}.carrierName`}
        label={t('form.order.carrier.carrierName')}
      />
      <FieldComponent
        fieldName={`${fieldName}.carRegistrationNumber`}
        label={t('form.order.carrier.carRegistrationNumber')}
      />
      <FieldComponent
        fieldName={`${fieldName}.driverFirstName`}
        label={t('form.order.carrier.driver.firstName')}
      />
      <FieldComponent
        fieldName={`${fieldName}.driverLastName`}
        label={t('form.order.carrier.driver.lastName')}
      />
      <FieldComponent
        fieldName={`${fieldName}.driverPhoneNumber`}
        label={t('form.order.carrier.driver.phoneNumber')}
      />
    </div>
  )
}
