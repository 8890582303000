import React from 'react'
import { DateRangePickerInline } from 'components/Shared/DateRangeSelect'
import { findFilter, setOrDropFilter } from 'components/Shared/Table/TableFiltersRow/utils'
import moment from 'moment'

export default function DateRangeFilter ({ column, filters, onFiltersChanged }) {

  const defaultValue = column.filterNames.map(name => {
    const filterValue = findFilter(filters, name)?.value
    return filterValue ? new Date(filterValue) : null
  })

  const handleChange = value => {
    let newFilters = [...filters]
    column.filterNames.forEach((filterName, i) => {
      const filterValue = value?.[i] ? moment(value[i]).format('YYYY-MM-DD') : null
      newFilters = setOrDropFilter(newFilters, filterName, filterValue)
    })
    onFiltersChanged(newFilters)
  }

  return (
    <DateRangePickerInline
      placeholder='form.select.title'
      onChange={handleChange}
      defaultValue={defaultValue}
    />
  )
}
