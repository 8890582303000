import React, { useMemo, useRef, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckIcon } from 'assets/icons/check-copy-2.svg'
import styles from './StatusChangeDialog.module.css'
import { PrimaryButton } from 'components/Shared/PrimaryButton'
import * as Yup from 'yup'
import { time } from 'utils/form'
import {
  momentToISOLocalDate,
  momentToISOLocalTime,
  momentToISOZonedDateTime,
  parseMomentFromLocalDateAndLocalTimeFields
} from 'utils/date'
import moment from 'moment'
import { Form, Formik } from 'formik'
import { FieldComponent } from 'components/Shared/FieldComponent'
import { DatePickerFormFieldInline } from 'components/Shared/DatePickerInline'
import {
  getDeliveryPointCargo,
  isLoaded,
  isUnloaded
} from 'components/Order/OrderDetails/DeliveryPointCargoApproveForm/deliveryPointCargoHelper'
import {
  ApproveCargoSchema,
  DeliveryPointCargoApproveForm
} from 'components/Order/OrderDetails/DeliveryPointCargoApproveForm'
import wrappingTypes from 'components/Order/OrderDetails/DeliveryPointCargoApproveForm/wrappingTypes.json'

export function StatusChangeDialog ({ open, order, newStatus, statusChanges, onClose, minNewStatusDateTime }) {

  function validateTimeIsAfterLastStatusChange () {
    const time = formRef.current.values.time
    const date = formRef.current.values.date
    if (time && date) {
      const parsedDate = parseMomentFromLocalDateAndLocalTimeFields(date, time)
      const minimumDateTime = moment(minNewStatusDateTime).local()
      return parsedDate.isSameOrAfter(minimumDateTime, 'minute')
    }
    return true
  }

  function validateTimeIsBeforeNow () {
    const time = formRef.current.values.time
    const date = formRef.current.values.date
    if (time && date) {
      const parsedDate = parseMomentFromLocalDateAndLocalTimeFields(date, time)
      return parsedDate.isSameOrBefore(moment().format())
    }
    return true
  }

  const UpdateStatusChangeSchema = Yup.object().shape({
    time: time().required()
      .test('time',
        ' ', () => {
          return validateTimeIsBeforeNow()
        }).test('time',
        ' ', () => {
          return validateTimeIsAfterLastStatusChange()
        }),
    date: Yup.string()
      .test('date',
        'form.validation.dateFromFuture', () => {
          return validateTimeIsBeforeNow()
        }).test('date',
        'form.validation.dateBeforeLastStatusChange', () => {
          return validateTimeIsAfterLastStatusChange()
        }),
    cancellationCause: Yup.string()
  }).concat(ApproveCargoSchema)

  const { t } = useTranslation()
  const [animateIcon, setAnimateIcon] = useState(false)

  const isStatusLoaded = useMemo(() => isLoaded(newStatus), [newStatus])
  const isStatusUnloaded = useMemo(() => isUnloaded(newStatus), [newStatus])

  const action = useMemo(() => ({
    status: newStatus.status,
    deliveryPointCargo: newStatus.deliveryPointCargo,
    deliveryPointPosition: newStatus.deliveryPointNumber
  }), [newStatus])

  const initialFormValues = () => {
    const dateTime = {
      date: momentToISOLocalDate(moment()),
      time: momentToISOLocalTime(moment())
    }
    let cargoBuilder = {}
    if (isStatusLoaded) {
      const expectedDeliveryPointCargo = getDeliveryPointCargo(order, action)
      cargoBuilder = {
        quantity: expectedDeliveryPointCargo.quantity,
        weight: expectedDeliveryPointCargo.weight,
        wrappingType: wrappingTypes.find(wt => wt.id === expectedDeliveryPointCargo.wrappingType)?.id || 'ELEMENT'
      }
    } else if (isStatusUnloaded) {
      const expectedDeliveryPointCargo = getDeliveryPointCargo(order, action)
      const actualDeliveryPointCargo = statusChanges.find(change =>
        change.status === 'LOADED' && change.deliveryPointPosition === action.deliveryPointPosition)?.deliveryPointCargo
      cargoBuilder = {
        quantity: actualDeliveryPointCargo?.quantity,
        weight: actualDeliveryPointCargo?.weight,
        wrappingType: wrappingTypes.find(wt => wt.id === actualDeliveryPointCargo?.wrappingType)?.id || 'ELEMENT',
        responsiblePerson: expectedDeliveryPointCargo.responsiblePerson,
        signed: expectedDeliveryPointCargo.signed
      }
    }
    return {
      ...dateTime,
      cargo: cargoBuilder
    }
  }

  const handleAnimation = () => {
    setAnimateIcon(!animateIcon)
  }
  const formRef = useRef()

  const handleClose = (result) => () => {
    if (formRef.current) {
      const minimumDateTime = moment(minNewStatusDateTime).local();
      const selectedDateTime = parseMomentFromLocalDateAndLocalTimeFields(formRef.current.values.date, formRef.current.values.time);
      const isSelectedDateTimeSameAsMinimumDateTime = selectedDateTime.isSame(minimumDateTime, 'minute');
      const newResult = {
        dateTime: (formRef.current.touched.date || formRef.current.touched.time)
          ? momentToISOZonedDateTime(
            isSelectedDateTimeSameAsMinimumDateTime ? minimumDateTime.add(1, 'second') : selectedDateTime
          )
          : null,
        cancellationCause: formRef.current.values.cancellationCause,
        cargo: formRef.current.values.cargo,
        ...result
      }
      onClose(newResult)
    } else {
      onClose(result)
    }
    handleAnimation()
  }

  const showSendNotificationButton = useMemo(() => {
    if (newStatus.backwardStatus) {
      return false
    }
    if (newStatus.status === 'SAVED') {
      return false
    }
    if (newStatus.status === 'SENT_TO_DRIVER') {
      return false
    }
    if (newStatus.status === 'READ_BY_DRIVER') {
      return false
    }
    if (newStatus.status === 'REJECTED') {
      return false
    }
    if (newStatus.status === 'FINISHED') {
      return false
    }
    return true
  }, [newStatus])

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth={'md'}
        onEntered={handleAnimation}
        onClose={handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className={styles.iconContainer}>
            <CheckIcon className={styles.checkIcon}/>
            <CheckIcon className={animateIcon
              ? styles.checkIconShadowTranslated
              : styles.checkIconShadow
            }/>
          </div>
          <DialogContentText className={styles.contentContainer}
                             id="alert-dialog-description">
            {t('order.orderStatusChange.content',
              {
                status: t(
                  `order.details.history.actions.orderStatus.${newStatus.status}`) +
                  (newStatus.deliveryPointNumber
                    ? ` ${newStatus.deliveryPointNumber}`
                    : ''),
              })}
          </DialogContentText>
          <div className={'m-auto'}>
            {!newStatus.backwardStatus && <Formik
              innerRef={formRef}
              initialValues={initialFormValues()}
              enableReinitialize
              validationSchema={UpdateStatusChangeSchema}
              onSubmit={values => values}>
              {
                () => (
                  <Form className={styles.formAlignedCenter}>
                    <div>
                      <DatePickerFormFieldInline
                        name={`date`}
                        label={'form.orderStatusChange.date'}
                        skipIcon
                        notRequiredTouchedToError/>
                      <FieldComponent
                        fieldName={`time`}
                        label={'form.orderStatusChange.time'}
                        skipErrorIcon
                        className={styles.timeField}
                        requireTouchToShowError={false}
                      />
                    </div>
                    {newStatus.status === 'CANCELLED' && <FieldComponent fieldName={'cancellationCause'}
                                                                            label={'form.orderStatusChange.cancellationCause'}
                                                                            className={styles.cancellationCauseField}
                                                                            multiline/>
                    }
                    {(isStatusLoaded || isStatusUnloaded) &&
                    <DeliveryPointCargoApproveForm order={order} action={action}/>}
                  </Form>
                )
              }
            </Formik>}
          </div>
        </DialogContent>
        <DialogActions
          className={styles.dialogActions}
          disableSpacing
        >
          <div className={'p-1'}>
            {showSendNotificationButton && <PrimaryButton
              onClick={handleClose({ confirmed: true, sendEmail: true })}
              label={'order.orderStatusChange.confirm'}
            />}
          </div>
          <div className={'p-1'}>
            <PrimaryButton
              onClick={handleClose({ confirmed: true, sendEmail: false })}
              label={'order.orderStatusChange.withoutEmail'}
            />
          </div>
          <div className={'p-1'}>
            <PrimaryButton
              onClick={handleClose({ confirmed: false })}
              label={'order.orderStatusChange.cancel'}
            />
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}
