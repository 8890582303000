export function getNavigationExpandedFromLocalStorage () {
  const key = 'navigationExpanded'
  const val = localStorage.getItem(key)
  return val ? JSON.parse(val) : true
}

export function setNavigationExpandedInLocalStorage (isExpanded) {
  const key = 'navigationExpanded'
  const val = JSON.stringify(isExpanded)
  localStorage.setItem(key, val)
}
