import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/Order/OrderDetails/HistoryTabs/HistoryTabs.module.css'
import UpdateStatusChangeDialog from 'components/Order/OrderDetails/HistoryTabs/UpdateStatusChangeDialog'
import { approveCargo, editStatusChange, getOrderMailingHistory, rejectCargo } from 'services/OrderService'
import { ApproveRejectCargoDialog } from 'components/Order/OrderDetails/HistoryTabs/ApproveRejectCargoDialog'
import { ShowCargoDialog } from 'components/Order/OrderDetails/HistoryTabs/ShowCargoDialog'
import { HTTP_422_ERROR } from 'Constants'
import { INVALID_STATUS_CHANGE } from 'services/errorCodes'
import ApiError from 'constants/ApiError'
import { useErrorHandling } from 'context/errorHandling'
import { AppBar, Tab, Tabs, Typography } from '@material-ui/core'
import { AvenirMedium } from 'styles/variables'
import { MailingHistory, OrderHistory } from 'components/Order/OrderDetails/HistoryTabs/Tabs'

const ChangeError = {
  DateMustBeAfterPreviousStatusChange: 'notifications.error.editStatusChange.dateMustBeAfterPreviousStatusChangeError',
  DateMustBeBeforeNextStatusChange: 'notifications.error.editStatusChange.dateMustBeBeforeNextStatusChangeError',
  DateFromFuture: 'notifications.error.editStatusChange.dateFromFuture'
}
const ORDER_HISTORY_TAB = 0
const MAILING_HISTORY_TAB = 1

export default function HistoryTabs ({ order, actions, refreshActions, refreshStatuses, onStatusChanged }) {
  const { t } = useTranslation()
  const { handleError } = useErrorHandling()

  const [selectedTab, setSelectedTab] = useState(ORDER_HISTORY_TAB)
  const [mailingHistoryList, setMailingHistoryList] = useState()
  const [editedStatusChange, setEditedStatusChange] = useState()
  const [selectedAction, setSelectedAction] = useState()
  const [showDeliveryPointStatusChange, setShowDeliveryPointStatusChange] = useState()

  useEffect(() => {
    getOrderMailingHistory(order.id)
      .then(setMailingHistoryList)
      .catch(handleError)
  }, [order, handleError])

  const onActionEdit = newValues => {
    if (newValues) {
      editStatusChange(editedStatusChange.id, newValues)
        .then(() => {
          refreshActions()
        })
        .catch(error => {
            const errorCode = error?.response?.data?.errorCode
            return handleError(error, {
              [HTTP_422_ERROR]: errorCode === 'DATE_NOT_AFTER_PREVIOUS_STATUS_CHANGE' ?
                ChangeError.DateMustBeAfterPreviousStatusChange :
                errorCode === 'DATE_NOT_BEFORE_NEXT_STATUS_CHANGE' ?
                  ChangeError.DateMustBeBeforeNextStatusChange :
                  errorCode === 'DATE_FROM_FUTURE' ?
                    ChangeError.DateFromFuture : ApiError.UnprocessableEntity
            })
          }
        )
    }
    setEditedStatusChange(null)
  }

  const onDialogClose = () => {
    setSelectedAction(null)
  }

  const onCargoApproveAction = values => {
    if (values) {
      const approveParams = {
        ...values,
        emailSendRequested: true
      }
      approveCargo(selectedAction.id, approveParams)
        .then(() => {
          refreshActions()
        })
        .catch(error =>
          handleError(error, {
            [HTTP_422_ERROR]: error?.response?.data?.errorCode === INVALID_STATUS_CHANGE ?
              'notifications.error.invalidStatusChange' : ApiError.UnprocessableEntity
          })
        )
    }
    onDialogClose()
  }

  const onCargoRejectAction = rejected => {
    if (rejected) {
      rejectCargo(selectedAction.id)
        .then((response) => {
          refreshActions()
          refreshStatuses()
          onStatusChanged({
            status: response.status === 'LOADED' ? 'LOADING' : 'UNLOADING',
            deliveryPointNumber: response.deliveryPointPosition
          })
        })
        .catch(error =>
          handleError(error, {
            [HTTP_422_ERROR]: error?.response?.data?.errorCode === INVALID_STATUS_CHANGE ?
              'notifications.error.invalidStatusChange' : ApiError.UnprocessableEntity
          })
        )
    }
    onDialogClose()
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <div className={styles.historyTabsWrapper}>
      {editedStatusChange && <UpdateStatusChangeDialog statusChange={editedStatusChange}
                                                       order={order}
                                                       onClose={onActionEdit}/>}
      {selectedAction && <ApproveRejectCargoDialog order={order}
                                                   action={selectedAction}
                                                   onClose={onDialogClose}
                                                   onApprove={onCargoApproveAction}
                                                   onReject={onCargoRejectAction}/>}

      {showDeliveryPointStatusChange && <ShowCargoDialog action={showDeliveryPointStatusChange}
                                                         onClose={() => setShowDeliveryPointStatusChange(null)}/>}
      <AppBar position="static" color="default" elevation={0}>
        <Tabs value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="secondary"
              textColor="inherit">
          <StyledTab label={t('order.details.history.actions.historyTitle')}/>
          <StyledTab label={t('order.details.history.emails.historyTitle')}/>
        </Tabs>
      </AppBar>
      {selectedTab === ORDER_HISTORY_TAB && actions &&
      <OrderHistory actions={actions}
                    onOrderStatusChangeEdit={setEditedStatusChange}
                    onCargoApproveRejectClicked={setSelectedAction}
                    onDeliveryPointCargoShow={setShowDeliveryPointStatusChange}/>}
      {selectedTab === MAILING_HISTORY_TAB && mailingHistoryList &&
      <MailingHistory mailingHistory={mailingHistoryList}/>}
    </div>
  )
}

function StyledTab ({ label, ...rest }) {
  return (
    <Tab
      label={<Typography
        style={{
          fontFamily: AvenirMedium,
          textTransform: 'none'
        }}>
        {label}
      </Typography>}
      {...rest} />
  )
}
