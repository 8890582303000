import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import React from 'react'
import { NotificationBody } from 'components/Notification/NotificationBody/NotificationBody'
import { RouteNotificationContent } from 'components/Notification/NotificationContent/RouteNotificationContent'

export default function AttachmentNotification ({ item, content, onNotificationUpdate, bodyClassName }) {
  const { t } = useTranslation()
  const history = useHistory()

  function goToAttachment () {
    history.push('/orders/details/' + content.orderId + '/attachments/' + content.attachmentId)
  }

  return <NotificationBody title={'notificationsList.newAttachment.title'}
                           item={item}
                           footerLeftContent={content.registrationNumber}
                           onNotificationUpdate={onNotificationUpdate}
                           bodyClassName={bodyClassName}>
    <RouteNotificationContent item={item}
                              content={content}
                              additionalInfo={t('notificationsList.newAttachment.content')}
                              onNotificationUpdate={onNotificationUpdate}
                              action={{
                                isShow: true,
                                onClick: goToAttachment
                              }}/>
  </NotificationBody>
}
