import React, { useEffect, useState } from 'react'
import { createCar, fetchCar } from 'services/CarService'
import { useHistory } from 'react-router-dom'
import { Loader } from 'components/Shared/Loader'
import { CarForm, mapCarToFormValues } from 'components/Car/CarForm'
import { SuccessDialog } from 'components/Shared/Dialogs/SuccessDialog'
import { REGISTRATION_NUMBER_CONFLICT } from 'services/errorCodes'
import { useErrorHandling } from 'context/errorHandling'
import { HTTP_404_ERROR, HTTP_422_ERROR } from 'Constants'
import ApiError from 'constants/ApiError'
import { useNavigatePrevent } from 'context/navigatePrevent'

const emptyCar = {
  brand: '',
  model: '',
  registrationNumber: '',
  trailerRegistrationNumber: '',
  type: '',
  capacity: '',
  loadingSpace: '',
  optionalFeatures: [],
  driverId: '',
  phoneNumber: '',
  carrierId: ''
}

function mapCopiedCarToFromValues (car) {
  return ({
    ...mapCarToFormValues(car),
    registrationNumber: ''
  })
}

export function CreateCarForm (props) {
  const [createdDialogOpen, setCreatedDialogOpen] = useState(false)
  const [copiedCar, setCopiedCar] = useState(null)
  const { handleError } = useErrorHandling()

  const history = useHistory()

  const idOfCopiedCar = props.location.state?.idOfCopiedCar
  const { setNavigationUnlocked, setNavigationBlocked } = useNavigatePrevent()

  useEffect(() => {
      setNavigationBlocked()
    }, [setNavigationBlocked]
  )

  useEffect(() => {
      if (idOfCopiedCar) {
        fetchCar(idOfCopiedCar).then(response => {
            setCopiedCar(response)
          }
        ).catch(error =>
          handleError(error, {
            [HTTP_404_ERROR]: 'notifications.error.carNotFound'
          }).then(() => {
            setNavigationUnlocked()
            history.push('/cars')
          }))
      }
    }, [idOfCopiedCar, handleError, history, setNavigationUnlocked]
  )

  const handleSubmit = (values) =>
    createCar(values)
      .then(() => setCreatedDialogOpen(true))
      .catch(error =>
        handleError(error, {
          [HTTP_422_ERROR]: error?.response?.data?.errorCode === REGISTRATION_NUMBER_CONFLICT ?
            'notifications.error.carRegistrationNumberConflict' : ApiError.UnprocessableEntity
        }))

  const initialValues = idOfCopiedCar ? mapCopiedCarToFromValues(copiedCar) : emptyCar

  return <div>
    <SuccessDialog open={createdDialogOpen}
                   onClose={() => {
                     setCreatedDialogOpen(false)
                     setNavigationUnlocked()
                     history.push('/cars')
                   }} content={'notifications.success.createdCar'}/>
    {!idOfCopiedCar || copiedCar ?
      <CarForm
        initialValues={initialValues}
        translateTitleLabel={'createCarTitle'}
        onSubmit={handleSubmit}/>
      :
      <Loader/>
    }
  </div>
}
