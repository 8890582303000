import React from 'react'
import styles from 'components/Shared/ContainedButton/ContainedButton.module.css'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

export function ContainedButton ({ label, onClick, icon, type, isDisabled = false, className }) {
  const { t } = useTranslation()

  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames(styles.button,
        className || styles.defaultStyles,
        icon ? styles.withIcon : styles.withoutIcon)}
      disabled={isDisabled}>
      {icon && <span className={styles.buttonIcon}>{icon}</span>}
      {t(label)}
    </button>
  )
}
