import { ReactComponent as BellIcon } from 'assets/icons/bell.svg'
import styles from 'components/Notification/NotificationMenuSwitch.module.css'
import React, { useEffect, useState } from 'react'
import { Badge, withStyles } from '@material-ui/core'
import { primaryRed } from 'styles/variables'
import { fetchUserNotReadNotificationsCount } from 'services/NotificationsService'
import { fetchLoggedUser, subscribeNotificationsCount } from 'services'
import useWebsocketConnection from 'context/webSocketConnection'
import { useAuth } from "context/auth";
import { useTranslation } from "react-i18next";
import { ReactComponent as MenuIcon } from "../../assets/icons/list-menu-button.svg";

const StyledBadge = withStyles(() => ({
    badge: {
        color: 'white',
        backgroundColor: primaryRed,
        fontWeight: 1000
    },
    anchorOriginTopRightRectangle: {
        top: '8px',
        right: '24px'
    }
}))(Badge)

export function NotificationMenuSwitch({openNotifications, closeNotifications, notificationsOpen}) {
    const [unreadCount, setUnreadCount] = useState(0)
    const [loggedUser, setUser] = useState()
    const isConnected = useWebsocketConnection()
    const {authTokens} = useAuth()
    const {t} = useTranslation()

    useEffect(() => {
        if (authTokens) {
            fetchLoggedUser().then(user => {
                setUser(user)
            })
        }
    }, [authTokens])

    const updateCount = (count) => {
        setUnreadCount(count)
    }
    useEffect(() => {
        if (loggedUser && isConnected) {
            fetchUserNotReadNotificationsCount().then(count => {
                setUnreadCount(count)
            })
                .catch(() => {
                })
            subscribeNotificationsCount(loggedUser.accountId, updateCount)
        }
    }, [loggedUser, isConnected])

    const buttonClicked = () => {
        if(notificationsOpen){
            closeNotifications()
        } else {
            openNotifications()
        }
    }

    return <div className={styles.buttonWrapper + ' mt-3 mb-0 d-flex'}
                onClick={buttonClicked}>
        {notificationsOpen ?
          <div className={styles.navigationButton}>
              <MenuIcon className={styles.icon}/>
              <span>{t('menu.menu')}</span>
          </div> :
          <div className={styles.navigationButton}>
              <StyledBadge badgeContent={unreadCount}>
                  <BellIcon className={styles.icon}/>
              </StyledBadge>
              <span>{t('menu.notifications')}</span>
          </div>
        }
    </div>
}
