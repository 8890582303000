const driverStatusOptions = [
  {
    'label': 'driver.status.available',
    'value': 'AVAILABLE'
  },
  {
    'label': 'driver.status.unavailable',
    'value': 'UNAVAILABLE'
  },
  {
    'label': 'driver.status.trafficJam',
    'value': 'TRAFFIC_JAM'
  },
  {
    'label': 'driver.status.accident',
    'value': 'ACCIDENT'
  }
]

export default driverStatusOptions
