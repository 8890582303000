import { deepFind } from './utils'
import { FilterType } from '../enums'
import React, { useState } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import classNames from 'classnames'
import { TableMenu } from '../TableMenu'
import { useRowStyles } from './styles'
import TableCellTooltip from '../TableTooltip'
import { truncate } from 'utils/utils'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

function getCellContent (row, column, classes, t, isHovered) {
  const cellValue = deepFind(row, column.path)
  if (column.renderFunc) {
    return column.renderFunc(cellValue, row, column, isHovered)
  }
  switch (column.filterType) {
    case FilterType.Select:
      if (cellValue === null) {
        return '―'
      }
      const option = column.selectOptions.find(option => option.value === cellValue)
      const label = option
        ? option.label
        : 'notifications.error.unsupportedSelectOption'
      if (column.statusToIndicatorColorMap) {
        const color = column.statusToIndicatorColorMap[cellValue]
        return (
          <div className={classes.statusIndicatorContainer}>
            <div className={classes.indicator}
                 style={{ backgroundColor: color ? color : '#000' }}/>
            {t(label)}
          </div>
        )
      }
      return t(label)
    case FilterType.DateRange:
    case FilterType.Date:
      return cellValue ? moment(cellValue).format('YYYY-MM-DD') : ''
    case FilterType.Text:
    default :
      return cellValue
  }
}

export function truncateContentAndAddTooltip (cellContent, maxTextLength) {
  if (cellContent.length > maxTextLength) {
    return (
      <TableCellTooltip title={cellContent} arrow>
        <div>{truncate(cellContent, maxTextLength)}</div>
      </TableCellTooltip>
    )
  } else {
    return cellContent
  }
}

const BodyCell = ({ row, column, onClick, isHovered }) => {
  const { t } = useTranslation()
  const classes = useRowStyles()
  const content = getCellContent(row, column, classes, t, isHovered)

  return (
    <TableCell
      className={classes.cell}
      onClick={onClick}
    >
      <div className={classes.cellContent}>
        {content && truncateContentAndAddTooltip(content, 20)}
      </div>
    </TableCell>
  )
}

export default ({ row, columns, onClick, isHighlighted, menuOptions }) => {
  const classes = useRowStyles()
  const [isHovered, setHovered] = useState(false)

  return (
    <TableRow
      className={classNames(classes.row, isHighlighted && classes.highlightedRow)}
      style={{ 'backgroundColor': row.rowColor }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {
        columns.filter(col => col.isVisible).map(col =>
          <BodyCell
            key={col.id}
            row={row}
            column={col}
            onClick={onClick}
            isHovered={isHovered}/>
        )
      }
      <TableCell className={classNames(classes.cell, classes.menuCell)} padding="checkbox">
        <TableMenu menuOptions={menuOptions}/>
      </TableCell>
    </TableRow>
  )
}
