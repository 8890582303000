import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckIcon } from 'assets/icons/check-copy-10.svg'
import { ReactComponent as ErrorIcon } from 'assets/icons/exit.svg'
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark.svg'
import moment from 'moment'
import styles from 'components/Order/OrderDetails/HistoryTabs/Tabs/Tabs.module.css'
import RowsCollapse from "components/Order/OrderDetails/HistoryTabs/Tabs/RowsCollapse";
import { Tooltip } from "@material-ui/core";
import classNames from "classnames";

export function MailingHistory ({ mailingHistory }) {
  const { t } = useTranslation()

  const handleActionType = (param) => {
    switch (param.emailType) {
      case 'SCHEDULED': {
        return t('order.details.history.emails.types.scheduled')
      }
      case 'STATUS_CHANGE': {
        return t('order.details.history.emails.types.statusChange')
      }
      default: {
        return t('order.details.history.emails.error')
      }
    }
  }

  return (<RowsCollapse itemsCount={mailingHistory.length}>
      <div className={styles.historyItems}>
        {mailingHistory.length > 0 ? mailingHistory.map(row => {
          return (
            <div className={styles.historyItem} key={row.id}>
              <div className={styles.historyItemCell}>
                {row.sendStatus === 'SUCCESS' ? <CheckIcon/> : <ErrorIcon className={styles.errorIcon}/>}
              </div>
              <Tooltip
                title={t('order.details.history.emails.startedAt') + moment(row.startedAt).format('DD.MM.YYYY HH:mm')}
                arrow>
                <div className='d-flex'>
                  <div className={styles.historyItemCell}>
                    {moment(row.finishedAt).format('DD.MM.YYYY')}
                  </div>
                  <div className={styles.historyItemCell}>
                    {moment(row.finishedAt).format('HH:mm')}
                  </div>
                </div>
              </Tooltip>
              <div className={styles.historyItemTypeCell}>{
                handleActionType(row)
              }</div>
            </div>
          )
        }) : <div className={classNames(styles.historyItem, styles.emptyHistory)}>
          <div className={styles.historyItemCell}>
            <QuestionMarkIcon/>
          </div>
          <div className={styles.historyItemCell}>
            {t('order.details.history.emails.emptyList')}
          </div>
        </div>}
      </div>
    </RowsCollapse>
  )
}
