import * as Yup from 'yup'

export const UserDetailsSchema = Yup.object().shape({
  role: Yup.string()
    .required('form.validation.required').nullable(),
  firstName: Yup.string()
    .required('form.validation.required'),
  lastName: Yup.string()
    .required('form.validation.required'),
  phoneNumber: Yup.string()
    .required('form.validation.required'),
  personalPhoneNumber: Yup.string().nullable(),
  email: Yup.string()
    .email('form.validation.email')
    .required('form.validation.required'),
  branchId: Yup.string()
    .required('form.validation.required').nullable(),
  departmentId: Yup.string()
    .required('form.validation.required').nullable(),
  hash: Yup.string()
    .required('form.validation.required')
    .length(4, 'form.validation.userHash')
})
