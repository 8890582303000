import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useTranslation } from 'react-i18next'
import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg'
import { useField } from 'formik'
import { ErrorMessage } from 'components/Shared/ErrorMessage'
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  defaultWidth: {
    width: '310px'
  }
})

export function SelectField ({
  data,
  label,
  fieldName,
  setFieldValue,
  optionLabelKey = 'label',
  valueKey = 'id',
  defaultValue,
  disableClear = true,
  inputClassName
}) {
  const [, meta] = useField(fieldName)
  const { error, touched } = meta

  const { t } = useTranslation()
  const classes = useStyles();

  const getLabel = (option) => {
    return option.typeof === 'string' ? t(option) : t(option[optionLabelKey])
  }

  return (
    <Autocomplete
      options={data}
      getOptionLabel={getLabel}
      disableClearable={disableClear}
      defaultValue={defaultValue}
      popupIcon={<DropdownIcon/>}
      onChange={(e, value) => {
        let currentValueKey = value ? value[valueKey] : null
        setFieldValue(fieldName, currentValueKey)
      }}
      noOptionsText={t('form.select.noOptionsText')}
      renderInput={params => {
        return (
          <TextField
            {...params}
            error={!!error && touched}
            helperText={<ErrorMessage name={fieldName}/>}
            label={label}
            variant="filled"
            fullWidth
            InputProps={{
              ...params.InputProps,
              className: `${params.InputProps.className} ${inputClassName || classes.defaultWidth}`,
              disableUnderline: true
            }}
          />
        );
      }}
    />
  )
}
