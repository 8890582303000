export function getFileExtension (fileName) {
  const split = fileName.split('.')
  return split.length > 1 ? split.pop() : ''
}

export function truncate (text, maxTextLength) {
  let originalLength = text.length
  if (originalLength > maxTextLength) {
    return text.slice(0, maxTextLength - 6) + '...'
  }
  return text
}

export const triggerFormSubmit = (formRef) => () => {
  if (formRef.current) {
    formRef.current.handleSubmit()
  }
}
