import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styles from 'components/Shared/CrudComponents/SimpleDetails.module.css'
import carDetailsStyles from 'components/Car/Details/CarDetailsMediaStyles.module.css'
import { Tooltip } from '@material-ui/core'
import { Loader } from 'components/Shared/Loader'
import { ReadOnlyTextField } from 'components/Shared/ReadOnlyTextField'
import { getLabelFromArrayByParameter } from 'components/Order/OrderDetails/Utils'
import { ReadOnlyCheckbox } from 'components/Shared/ReadOnlyCheckbox'
import { TitleBar } from 'components/Shared/CrudComponents/TitleBar'
import { fetchCarrier } from 'services/CarrierService'
import { fetchDriver } from 'services'
import { fetchCar, fetchCarLocationServicesConnectionStatus } from 'services/CarService'
import errorIcon from 'assets/fields/error.svg'
import optionalFeatures from 'components/Car/carOptionalFeatures'
import { useErrorHandling } from "context/errorHandling";
import { HTTP_404_ERROR } from "Constants";

export function CarDetails (props) {
  const [car, setCar] = useState(null)
  const [carrier, setCarrier] = useState(null)
  const [driver, setDriver] = useState(null)
  const [locationServicesConnectionStatus, setLocationServicesConnectionStatus] = useState(null)
  const isDisconnectedWithLocationServices = locationServicesConnectionStatus && !locationServicesConnectionStatus.connected
  const history = useHistory()
  const { handleError } = useErrorHandling()
  const carId = props.match.params.id

  const handleNotFoundError = useCallback((error, message) =>
    handleError(error, {
        [HTTP_404_ERROR]: message
      }
    ).then(() => history.push('/cars')), [handleError, history])

  useEffect(() => {
      fetchCar(carId).then(response => {
          setCar(response)
        },
      ).catch((error) => handleNotFoundError(error, 'notifications.error.getCar'))

      fetchCarLocationServicesConnectionStatus(carId)
        .then(resp => {
          setLocationServicesConnectionStatus(resp.status)
        })
        .catch(error => {
          console.error('Could not fetch location services connection status', error)
        })
    }, [carId, handleNotFoundError],
  )

  useEffect(() => {
    if (car) {
      if (car.driverId) {
        fetchDriver(car.driverId)
          .then(response =>
            setDriver(response))
          .catch((error) => handleNotFoundError(error, 'notifications.error.getDriver'))
      }

      fetchCarrier(car.carrierId)
        .then(response => setCarrier(response))
        .catch((error) => handleNotFoundError(error, 'notifications.error.getCarrier'))
    }
  }, [car, handleNotFoundError])

  const { t } = useTranslation()

  const editCar = () => {
    history.push('/cars/edit/' + carId)
  }
  return <div>
    {car ?
      <div>
        <TitleBar
          buttonLabel={t('car.details.edit')}
          title={car.registrationNumber}
          onButtonClick={editCar}
          renderTitle={title => (
            <div className={carDetailsStyles.title}>
              <h1>{title}</h1>
              {isDisconnectedWithLocationServices &&
              <Tooltip title={t('car.details.location-services-connection-warning')} arrow>
                <img
                  className={carDetailsStyles.warningIcon}
                  src={errorIcon}
                  alt="Location services warning"/>
              </Tooltip>
              }
            </div>
          )
          }
        />
        <div>
          <div className={styles.contentRow}>
            <div className={styles.detailsColumn}>
              <div className={styles.header}>{t('car.details.carrier')}</div>
              <div className={styles.detailsContentColumn}>
                <ReadOnlyTextField clearDisabled label={'car.details.carrier'}
                                   value={carrier ? t(carrier.name) : ''}/>
              </div>
            </div>
            <div className={styles.detailsColumn + ' ' + carDetailsStyles.order3}>
              <div className={styles.header}>{t('car.details.car')}</div>
              <div className={styles.detailsContentColumn}>
                <ReadOnlyTextField clearDisabled label={'car.details.registrationNumber'}
                                   value={car.registrationNumber}/>
                <ReadOnlyTextField clearDisabled label={'car.details.brand'} value={car.brand}/>
                <ReadOnlyTextField clearDisabled label={'car.details.model'} value={car.model}/>
                <ReadOnlyTextField clearDisabled label={'car.details.trailerRegistrationNumber'}
                                   value={car.trailerRegistrationNumber}/>
                <ReadOnlyTextField clearDisabled label={'car.details.type'} value={car.type}/>
                <ReadOnlyTextField clearDisabled label={'car.details.capacity'} value={car.capacity}/>
                <ReadOnlyTextField clearDisabled label={'car.details.loadingSpace'} value={car.loadingSpace}/>
                <div className={carDetailsStyles.col}>
                  {t('car.details.optionalFeatures.title')}
                  <div className={carDetailsStyles.container}>
                    {car.optionalFeatures.map(feature => <ReadOnlyCheckbox
                      key={feature}
                      label={
                        'car.details.optionalFeatures.' +
                        t(getLabelFromArrayByParameter(feature, optionalFeatures, 'id'))}/>)}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.detailsColumn + ' ' + carDetailsStyles.order1}>
              <div className={styles.header}>{t('car.details.driver.title')}</div>
              <div className={styles.detailsContentColumn}>
                <ReadOnlyTextField clearDisabled label={'car.details.driver.firstName'}
                                   value={driver ? driver.firstName : '-'}/>
                <ReadOnlyTextField clearDisabled label={'car.details.driver.lastName'}
                                   value={driver ? driver.lastName : '-'}/>
                <ReadOnlyTextField clearDisabled label={'car.details.driver.phoneNumber'}
                                   value={driver ? driver.phoneNumber : '-'}/>
              </div>
            </div>
            <div className={styles.flexHeader}/>
          </div>
        </div>
      </div>
      :
      <Loader/>
    }
  </div>
}
