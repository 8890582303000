import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { ReactComponent as UploadIcon } from 'assets/icons/add_file.svg'
import styles from 'components/Shared/Dropzone/Dropzone.module.css'
import { useTranslation } from 'react-i18next'
import { FileChip } from 'components/Shared/FileChip'
import { uploadFile } from 'services/OrderService'
import { useErrorHandling } from 'context/errorHandling'
import ApiError from 'constants/ApiError'
import { HTTP_500_ERROR } from 'Constants'

export function Dropzone ({ initialAttachments = [], onAttachmentsUpdated }) {
  const { t } = useTranslation()

  const { handleError } = useErrorHandling()
  const [files, setFiles] = useState(initialAttachments.map(file => ({
    name: file.filename,
    id: file.id,
    verified: file.verified,
    attachmentAccess: {
      contractorAccess: file.attachmentAccess.contractorAccess,
      driverAccess: file.attachmentAccess.driverAccess
    }
  })))

  const onDrop = useCallback(acceptedFiles => {
    let filenames = files.map(value => value.name)
    const newFiles = acceptedFiles.filter(
      file => !filenames.includes(file.name))
    Promise.all(
      newFiles.map(file =>
        uploadFile({
          file: file
        })
      ))
      .then(response => {
        let addedFiles = newFiles
          .filter(
            file => response.filter(value => value.filename === file.name))
          .map(file => {
            let savedAttachment = response.find(value => value.filename === file.name);
            return ({
              name: file.name,
              id: savedAttachment.id,
              verified: savedAttachment.verified,
              attachmentAccess: savedAttachment.attachmentAccess
            });
          })

        const updatedFiles = [...files, ...addedFiles]
        setFiles(updatedFiles)
        onAttachmentsUpdated(updatedFiles)

      }).catch(error => handleError(error, {
        [HTTP_500_ERROR]: ApiError.FileUploadFailure
      })
    )

  }, [files, handleError, onAttachmentsUpdated])

  function handleRemove (fileToRemove) {
    const updatedFiles = files.filter(file => fileToRemove.name !== file.name)
    setFiles(updatedFiles)
    onAttachmentsUpdated(updatedFiles)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*, application/pdf',
    onDrop
  })

  function handleVerifiedChange (value, id) {
    const updatedFiles = files.map(file => {
      if (file.id === id) {
        file.verified = value
      }
      return file
    })
    setFiles(updatedFiles)
    onAttachmentsUpdated(updatedFiles)
  }

  function handleDriverAccessChange (value, id) {
    const updatedFiles = files.map(file => {
      if (file.id === id) {
        file.attachmentAccess.driverAccess = value
      }
      return file
    })
    setFiles(updatedFiles)
    onAttachmentsUpdated(updatedFiles)
  }

  function handleContractorAccessChange (value, id) {
    const updatedFiles = files.map(file => {
      if (file.id === id) {
        file.attachmentAccess.contractorAccess = value
      }
      return file
    })
    setFiles(updatedFiles)
    onAttachmentsUpdated(updatedFiles)
  }

  return (
    <div>
      <div className={styles.container} {...getRootProps()}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <div><UploadIcon/> {t('form.dropzone.hover')}</div> :
            <div><UploadIcon/> {t('form.dropzone.name')}</div>
        }
      </div>
      <div className={styles.fileChipRow}>
        <ul>
          {files.map(file =>
            <FileChip key={file.name}
                      file={file}
                      onRemove={handleRemove}
                      accessDetails={{
                        verified: file.verified,
                        contractorAccess: file.attachmentAccess.contractorAccess,
                        driverAccess: file.attachmentAccess.driverAccess,
                        verifiedClicked: (value) => {
                          handleVerifiedChange(value, file.id)
                        },
                        contractorAccessClicked: (value) => {
                          handleContractorAccessChange(value, file.id)
                        },
                        driverAccessClicked: (value) => {
                          handleDriverAccessChange(value, file.id)
                        },
                        cursorPointer: true
                      }}
            />
          )}
        </ul>
      </div>
    </div>
  )
}
