import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AccessControl } from 'components/Shared/AccessControl'
import { useAuth } from 'context/auth'

function PrivateRoute ({ component: Component, allowedPrivileges, ...rest }) {
  const { authTokens } = useAuth()
  const redirectPath = authTokens ? '/' : '/login/'
  return (
    <Route
      {...rest}
      render={props =>
        <AccessControl
          allowedPrivileges={allowedPrivileges}
          renderNoAccess={() => <Redirect to={redirectPath}/>}
        >
          <Component {...props} />
        </AccessControl>
      }
    />
  )
}

export default PrivateRoute
