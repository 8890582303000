import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import styles from 'components/Navigation/Navigation.module.css'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { ReactComponent as ProfileIcon } from 'assets/icons/user.svg'
import { ReactComponent as OrdersListIcon } from 'assets/icons/list.svg'
import { ReactComponent as CarsListIcon } from 'assets/icons/car.svg'
import { ReactComponent as DriversListIcon } from 'assets/icons/kierowca.svg'
import { ReactComponent as UsersListIcon } from 'assets/icons/users-list.svg'
import { useTranslation } from 'react-i18next'
import { ReactComponent as VislineLogotypeIcon } from 'assets/logos/visline.svg'
import { ReactComponent as VislineLogoIcon } from 'assets/logos/logo.svg'
import { ADMIN, OFFICE_USER } from 'components/Shared/AccessControl/Privileges'
import { AccessControl } from 'components/Shared/AccessControl'
import Slide from '@material-ui/core/Slide'
import { NotificationMenuSwitch, NotificationsList } from 'components/Notification'
import { LanguageSelectField } from 'components/Navigation'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    display: 'flex',
    zIndex: 1200,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: drawerWidth => `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth => drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 0
  },
  hide: {
    display: 'none',
  },
  drawer: {
    position: 'relative',
    width: drawerWidth => drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    width: drawerWidth => drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: drawerWidth => -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}))

export function MobileNavigation ({ width, open, setOpen }) {
  const { t } = useTranslation()
  const [notificationListOpen, setNotificationListOpen] = React.useState(false)

  const classes = useStyles(width)
  const theme = useTheme()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
    setNotificationListOpen(false)
  }

  const currentPathname = window.location.pathname

  const currentPathHighlight = (path) => {
    return ([styles.iconBackground,
      {
        [styles['currentPageIconBackground']]: currentPathname.includes(path),
      }])
  }

  return (
    <div className={classes.root}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, open && classes.hide)}
        >
          <MenuIcon/>
        </IconButton>
      </Toolbar>
      <Drawer
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div className={classes.toolbar}>
            <div className={open ? styles.logoBoxOpen : styles.logoBoxClosed}
                 style={{ transform: 'translateX(-19px)' }}>
              <div className={open ? styles.logotypeOpen : styles.logotypeClosed}>
                <VislineLogotypeIcon/>
              </div>
              <div className={styles.logo}>
                <VislineLogoIcon/>
              </div>
            </div>
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
          </IconButton>
        </div>
        <NotificationMenuSwitch openNotifications={() => setNotificationListOpen(true)}
                                closeNotifications={() => setNotificationListOpen(false)}
                                notificationsOpen ={notificationListOpen}/>
        <div className='d-flex'>
          <Slide direction="right" in={!notificationListOpen} mountOnEnter
                 unmountOnExit>
            <List>
              <AccessControl allowedPrivileges={[ADMIN, OFFICE_USER]}>
                <ListItem className={styles.listItem} button key={'loggedUser'}
                          component={Link} to="/my-profile"
                          onClick={handleDrawerClose}
                >
                  <ListItemIcon>
                    <div className={classNames(currentPathHighlight('/my-profile'))}>
                      <ProfileIcon className={styles.icon}/>
                    </div>
                  </ListItemIcon>
                  <ListItemText
                    primary={t('menu.profile')}/>
                </ListItem>
              </AccessControl>
              <AccessControl allowedPrivileges={[ADMIN, OFFICE_USER]}>
                <ListItem className={styles.listItem} button key={'orders'}
                          component={Link} to="/orders"
                          onClick={handleDrawerClose}
                >
                  <ListItemIcon>
                    <div className={classNames(currentPathHighlight('/orders'))}>
                      <OrdersListIcon className={styles.icon}/>
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t('menu.orders')}/>
                </ListItem>
              </AccessControl>
              <AccessControl allowedPrivileges={[ADMIN, OFFICE_USER]}>
                <ListItem className={styles.listItem} button key={'cars'}
                          component={Link} to="/cars"
                          onClick={handleDrawerClose}
                >
                  <ListItemIcon>
                    <div className={classNames(currentPathHighlight('/cars'))}>
                      <CarsListIcon className={styles.icon}/>
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t('menu.cars')}/>
                </ListItem>
              </AccessControl>
              <AccessControl allowedPrivileges={[ADMIN, OFFICE_USER]}>
                <ListItem className={styles.listItem} button key={'drivers'}
                          component={Link} to="/drivers"
                          onClick={handleDrawerClose}
                >
                  <ListItemIcon>
                    <div className={classNames(currentPathHighlight('/drivers'))}>
                      <DriversListIcon className={styles.icon}/>
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t('menu.drivers')}/>
                </ListItem>
              </AccessControl>
              <AccessControl allowedPrivileges={[ADMIN]}>
                <ListItem className={styles.listItem} button key={'users'}
                          component={Link} to="/users"
                          onClick={handleDrawerClose}
                >
                  <ListItemIcon>
                    <div className={classNames(currentPathHighlight('/users'))}>
                      <UsersListIcon className={styles.icon}/>
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t('menu.users')}/>
                </ListItem>
              </AccessControl>
            </List>
          </Slide>
          <div className='position-absolute'>
            <Slide direction="left" in={notificationListOpen} mountOnEnter
                   unmountOnExit>
              <List>
                <NotificationsList/>
              </List>
            </Slide>
          </div>
        </div>
        <div className={styles.languageButtons}>
          <LanguageSelectField/>
        </div>
      </Drawer>
    </div>
  )
}
