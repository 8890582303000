import React, { useCallback, useEffect, useState } from 'react'
import { TableContainer, } from '@material-ui/core'

const TOOLBAR_SHOW_THRESHOLD_PX = 50

export const TableContainerWrapper = ({ columnsVisibilityChanged, ...rest }) => {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset)
  const [isToolbarVisible, setToolbarVisibility] = useState(true)

  useEffect(() => {
    // timeout for setting transition after header rendering so headers don't slide down when they appear
    const timer = setTimeout(() => {
      const filterHeaderThNodes = document.getElementById('filter-headers-row').getElementsByTagName('th')
      for (let filterNode of filterHeaderThNodes) {
        filterNode.style.transition = 'top 0.3s'
      }
      const columnHeaderThNodes = document.getElementById('column-headers-row').getElementsByTagName('th')
      for (let headerNode of columnHeaderThNodes) {
        headerNode.style.transition = 'top 0.3s'
      }
    }, 50)
    return () => clearTimeout(timer)
  }, [columnsVisibilityChanged])

  useEffect(() => {
    const columnHeadersRow = document.getElementById('column-headers-row')
    const filterHeaderThNodes = document.getElementById('filter-headers-row').getElementsByTagName('th')
    const { offsetHeight: toolbarHeight, style: toolbarStyle } = document.getElementById('toolbar')
    const toolbarTopStyle = (toolbarHeight * !isToolbarVisible)
    toolbarStyle.top = `-${toolbarTopStyle}px`
    for (let filterNode of filterHeaderThNodes) {
      const topStyle = columnHeadersRow.offsetHeight + (toolbarHeight * isToolbarVisible)
      filterNode.style.top = `${topStyle}px`
    }
    const columnHeaderThNodes = columnHeadersRow.getElementsByTagName('th')
    const headerSpacerTopStyle = isToolbarVisible ? toolbarHeight : 0
    for (let headerNode of columnHeaderThNodes) {
      headerNode.style.top = `${headerSpacerTopStyle}px`
    }
    const spacer = document.getElementById('table-body-spacer')
    spacer.style.height = `${headerSpacerTopStyle}px`

  }, [isToolbarVisible, columnsVisibilityChanged])

  const handleScroll = useCallback(() => {
    const { scrollTop: currentScrollPos } = document.getElementById('table-container')
    if (prevScrollPos !== currentScrollPos) {
      const isScrollUp = prevScrollPos > currentScrollPos
      const isScrollDown = !isScrollUp
      if (isScrollUp && !isToolbarVisible && currentScrollPos < TOOLBAR_SHOW_THRESHOLD_PX) {
        setToolbarVisibility(true)
      } else if (isScrollDown && isToolbarVisible) {
        const { offsetHeight: toolbarHeight } = document.getElementById('toolbar')
        // Taking doubled toolbar height to make sure that toolbar has enough space to hide
        // fix lagging/jumping screen when list takes almost entire page
        // AND to make sure that it has enough space to show again - scrollbar can disappear without this
        if (currentScrollPos > toolbarHeight * 2) {
          setToolbarVisibility(false)
        }
      }
      setPrevScrollPos(currentScrollPos)
    }
  }, [prevScrollPos, isToolbarVisible])

  return <TableContainer {...rest}
                         id={'table-container'}
                         onScroll={handleScroll}/>
}
