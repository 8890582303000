import api from 'services/api'
import { stringifyPageQuery } from 'services/utils'

export const fetchDrivers = (params) =>
  api.get(`/drivers?${stringifyPageQuery(params)}`)

// TODO
export const fetchAllDrivers = () =>
  api.get('/drivers?pageSize=1000')
    .then(page => page.items)

export const fetchDriver = (id) => api.get(`/drivers/${id}`)

export const deleteDriver = (id) => api.post(`/drivers/${id}/mark-as-deleted`)

export const fetchDriverAccount = (id) => api.get(`/drivers/${id}/account`)

export const createDriver = ({
  firstName,
  lastName,
  address,
  phoneNumber,
  personalPhoneNumber,
  idCardNumber,
  idCardExpirationDate,
  driverLicenseNumber,
  driverLicenseCategory,
  driverLicenseExpirationDate,
  username,
  password,
  adrCertificateNumber,
  adrCertificateExpirationDate
}) => api.post('/drivers', {
  username: username,
  password: password,
  details: {
    firstName: firstName,
    lastName: lastName,
    address: address,
    phoneNumber: phoneNumber,
    personalPhoneNumber: personalPhoneNumber,
    idCardNumber: idCardNumber,
    idCardExpirationDate: idCardExpirationDate,
    driverLicenseNumber: driverLicenseNumber,
    driverLicenseCategory: driverLicenseCategory,
    driverLicenseExpirationDate: driverLicenseExpirationDate,
    adrCertificateNumber: adrCertificateNumber,
    adrCertificateExpirationDate: adrCertificateExpirationDate
  }
})

export const editDriver = ({
  firstName,
  lastName,
  address,
  phoneNumber,
  personalPhoneNumber,
  idCardNumber,
  idCardExpirationDate,
  driverLicenseNumber,
  driverLicenseCategory,
  driverLicenseExpirationDate,
  id,
  adrCertificateNumber,
  adrCertificateExpirationDate,
}) =>
  api.put(`/drivers/${id}`, {
    details: {
      firstName: firstName,
      lastName: lastName,
      address: address,
      phoneNumber: phoneNumber,
      personalPhoneNumber: personalPhoneNumber,
      idCardNumber: idCardNumber,
      idCardExpirationDate: idCardExpirationDate,
      driverLicenseNumber: driverLicenseNumber,
      driverLicenseCategory: driverLicenseCategory,
      driverLicenseExpirationDate: driverLicenseExpirationDate,
      adrCertificateNumber: adrCertificateNumber,
      adrCertificateExpirationDate: adrCertificateExpirationDate
    }
  })

export const editDriverAccount = ({
  username,
  password,
  id
}) =>
  api.put(`/drivers/${id}/account`, {
      username: username,
      password: password
    }
  )
