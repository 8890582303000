import React, { Suspense, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'App.css'
import 'styles/colors.css'
import 'styles/typography.css'
import { CreateUserForm, EditUserForm, UsersTable } from 'components/Users'
import { Navigation } from 'components/Navigation'
import { BrowserRouter as Router, Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { AuthContext, getUsername, getUserPrivilege, useAuth } from 'context/auth'
import PrivateRoute from 'PrivateRoute'
import { Login } from 'components/Login'
import { TOKEN_KEY } from 'Constants'
import { CreateOrder, OrdersTable } from 'components/Order'
import { ADMIN, MOBILE_USER, OFFICE_USER } from 'components/Shared/AccessControl/Privileges'
import { CreateCarForm } from 'components/Car/CreateCarForm'
import { EditCarForm } from 'components/Car/EditCarForm'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/pl'
import 'moment/locale/en-gb'
import 'moment/locale/de'
import { EditOrder } from 'components/Order/OrderForm/EditOrder'
import { CarsTable } from 'components/Car'
import { DriverDetails, DriversTable } from 'components/Drivers'
import { CreateDriverForm } from 'components/Drivers/CreateDriverForm'
import { EditDriverForm } from 'components/Drivers/EditDriverForm'
import { makeStyles, StylesProvider } from '@material-ui/core/styles'
import { OrdersDetails } from 'components/Order/OrderDetails/OrderDetails'
import { ContractorView } from 'components/Contractor'
import { CarDetails } from 'components/Car/Details'
import { MyProfile } from 'components/Users/Details/MyProfile'
import { AdminUserDetailsView } from 'components/Users/Details/AdminUserDetailsView'
import { DriverOnBoardingScreen } from 'components/DriverOnBoardingScreen'
import { useMediaQuery } from 'react-responsive'
import { MobileNavigation } from 'components/MobileNavigation'
import { fetchLoggedUser } from 'services'
import { Loader } from 'components/Shared/Loader'
import { ErrorHandlingProvider } from 'context/errorHandling'
import ErrorHandler from 'components/ErrorHandler'
import { NotificationDialog } from 'components/Notification'
import { connect as connectToWebSocket, disconnect as disconnectFromWebSocket } from 'services/SocketClient'
import classNames from 'classnames'
import { getNavigationExpandedFromLocalStorage } from 'components/Navigation/preferences'
import { NavigatePreventProvider } from 'context/navigatePrevent'

const drawerWidth = 260

const useStyles = makeStyles((theme) => ({
  shiftTextLeft: {
    marginLeft: '90px'
  },
  shiftTextRight: {
    marginLeft: drawerWidth,
  },
  contentWrapper: {
    flex: 1,
    padding: '0',
    transition: theme.transitions.create(
      ['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      })
  }
}))

function Page () {
  const { i18n } = useTranslation()
  const [authTokens, setAuthTokens] = useState()
  const [user, setUser] = useState(null)

  let tokenFromLocalStorage = JSON.parse(localStorage.getItem(TOKEN_KEY))

  const privilege = authTokens ? getUserPrivilege(authTokens) : null
  const username = authTokens ? getUsername(authTokens) : null

  const setTokens = (data) => {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(data))
    setAuthTokens(data)
  }

  if (authTokens !== tokenFromLocalStorage) {
    setAuthTokens(tokenFromLocalStorage)
  }

  useEffect(() => {
    if (authTokens) {
      connectToWebSocket(authTokens)
    } else {
      disconnectFromWebSocket()
    }
  }, [authTokens])

  useEffect(() => {
    moment.locale(i18n.language)
  }, [i18n.language])

  useEffect(() => {
    if (authTokens) {
      fetchLoggedUser()
        .then(user => setUser(user))
    } else {
      setUser(null)
    }
  }, [authTokens])

  return <AuthContext.Provider value={{
    authTokens,
    username,
    privilege,
    user,
    setAuthTokens: setTokens
  }}>
    <ErrorHandlingProvider>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
        <StylesProvider injectFirst>
          <Router>
            <NavigatePreventProvider>
              <div className="App">
                <Switch>
                  <Route path="/contractor-view/:orderToken" component={ContractorView}/>
                  <Route component={AppContainer} privilege={privilege}/>
                </Switch>
                <ErrorHandler/>
              </div>
            </NavigatePreventProvider>
          </Router>
        </StylesProvider>
      </MuiPickersUtilsProvider>
    </ErrorHandlingProvider>
  </AuthContext.Provider>
}

const AppContainer = withRouter(({ location }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const { authTokens } = useAuth()
  const privilege = authTokens ? getUserPrivilege(authTokens) : ''
  const [isNavigationOpen, setNavigationOpen] = useState(!isMobile && getNavigationExpandedFromLocalStorage())
  const classes = useStyles()

  return (
    <>
      <div className="navigationWrapper">
        {
          location.pathname.includes('login') || (isMobile ?
            <MobileNavigation width={drawerWidth}
                              open={isNavigationOpen}
                              setOpen={setNavigationOpen}/> :
            <Navigation width={drawerWidth}
                        open={isNavigationOpen}
                        setOpen={setNavigationOpen}/>)
        }
      </div>
      <div
        className={classNames(classes.contentWrapper, !isMobile && (isNavigationOpen ? classes.shiftTextRight : classes.shiftTextLeft))}>
        {authTokens && <NotificationDialog/>}
        <Switch>
          {!authTokens && <Route path="/login" component={Login}/>}
          <PrivateRoute path="/driver_onboarding" component={DriverOnBoardingScreen}
                        allowedPrivileges={[MOBILE_USER]}/>
          <PrivateRoute path="/cars" exact component={CarsTable} allowedPrivileges={[ADMIN, OFFICE_USER]}/>
          <PrivateRoute path="/cars/create" component={CreateCarForm} allowedPrivileges={[ADMIN, OFFICE_USER]}/>
          <PrivateRoute path="/cars/edit/:id" component={EditCarForm} allowedPrivileges={[ADMIN, OFFICE_USER]}/>
          <PrivateRoute path="/cars/details/:id" component={CarDetails} allowedPrivileges={[ADMIN, OFFICE_USER]}/>
          <PrivateRoute path="/drivers" exact component={DriversTable} allowedPrivileges={[ADMIN, OFFICE_USER]}/>
          <PrivateRoute path="/drivers/create" component={CreateDriverForm}
                        allowedPrivileges={[ADMIN, OFFICE_USER]}/>
          <PrivateRoute path="/drivers/edit/:id" component={EditDriverForm}
                        allowedPrivileges={[ADMIN, OFFICE_USER]}/>
          <PrivateRoute path="/drivers/details/:id" component={DriverDetails}
                        allowedPrivileges={[ADMIN, OFFICE_USER]}/>
          <PrivateRoute path="/orders" exact component={OrdersTable} allowedPrivileges={[ADMIN, OFFICE_USER]}/>
          <PrivateRoute path="/orders/create" component={CreateOrder} allowedPrivileges={[ADMIN, OFFICE_USER]}/>
          <PrivateRoute path="/orders/edit/:orderId" component={EditOrder}
                        allowedPrivileges={[ADMIN, OFFICE_USER]}/>
          <PrivateRoute path="/orders/details/:id/attachments/:attachmentId" component={OrdersDetails}
                        allowedPrivileges={[ADMIN, OFFICE_USER]}/>
          <PrivateRoute path="/orders/details/:id" component={OrdersDetails}
                        allowedPrivileges={[ADMIN, OFFICE_USER]}/>
          <PrivateRoute path="/users" exact component={UsersTable} allowedPrivileges={[ADMIN]}/>
          <PrivateRoute path="/users/create" component={CreateUserForm} allowedPrivileges={[ADMIN]}/>
          <PrivateRoute path="/users/edit/:userId" component={EditUserForm} allowedPrivileges={[ADMIN]}/>
          <PrivateRoute path="/my-profile" component={MyProfile} allowedPrivileges={[ADMIN, OFFICE_USER]}/>
          <PrivateRoute path="/users/details/:id" component={AdminUserDetailsView} allowedPrivileges={[ADMIN]}/>
          <Redirect to={privilege === MOBILE_USER ? '/driver_onboarding' : '/orders'}/>
        </Switch>
      </div>
    </>
  )
})

// loading component for suspense fallback
const LoaderComponent = () => (
  <div className="App">
    <Loader/>
  </div>
)

// here app catches the suspense from page in case translations are not yet loaded
export default function App () {
  return (
    <Suspense fallback={<LoaderComponent/>}>
      <Page/>
    </Suspense>
  )
}
