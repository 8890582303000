import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import InputAdornment from '@material-ui/core/InputAdornment'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import styles from 'components/Order/OrderTable/OrderTable.module.css'
import { useTranslation } from 'react-i18next'
import { Loader } from 'components/Shared/Loader'
import Table, { FilterType, Order } from 'components/Shared/Table'
import { CalendarFilter } from 'components/Order/OrderTable/CalendarFilter'
import { deleteOrder, fetchOrders } from 'services/OrderService'
import { fetchDispatchers, fetchForwarders, subscribeOrderUpdated } from 'services'
import { fetchCarriers } from 'services/CarrierService'
import driverStatusOptions from 'constants/driverStatusOptions'
import orderStatusOptions from 'constants/orderStatusOptions'
import TableIconTooltip from 'components/Shared/Table/TableTooltip'
import { CommentEdit } from 'components/Order/CommentEdit/CommentEdit'
import moment from 'moment'
import { momentToISOLocalDate } from 'utils/date'
import { useErrorHandling } from 'context/errorHandling'
import { useWebSocketConnection } from 'context'
import { isEqual, omit } from 'lodash'
import { useAuth } from 'context/auth'
import { ADMIN, SUPER_ADMIN } from 'components/Shared/AccessControl/Privileges'
import { truncateContentAndAddTooltip } from 'components/Shared/Table/TableBodyRow/TableBodyRow'

function createColumnDefinitions (t, carriers, forwarders, dispatchers, tableRef) {
  return [
    {
      id: 'readableId',
      label: 'order.id',
      path: 'readableId',
      filterType: FilterType.Text,
      filterInputProps: {
        className: styles.orderIdFilterInput,
        inputProps: {
          maxLength: 8
        },
        endAdornment:
          <InputAdornment position='end'>
            <TableIconTooltip title={t('order.idFilterCaption')} arrow>
              <InfoIcon className={styles.orderIdFilterInfoIcon}/>
            </TableIconTooltip>
          </InputAdornment>
      }
    },
    {
      id: 'status',
      label: 'order.status.title',
      path: 'status',
      sortable: true,
      filterType: FilterType.Select,
      selectOptions: orderStatusOptions,
      statusToIndicatorColorMap: {
        SAVED: '#398BFF',
        SENT_TO_DRIVER: '#FFD7F0',
        READ_BY_DRIVER: '#D6C99E',
        ACCEPTED: '#5EFF38',
        REJECTED: '#FF0003',
        LOADING: '#CABBFF',
        LOADED_NOT_APPROVED: '#7967BB',
        LOADED: '#B200FF',
        UNLOADING: '#00C2D5',
        UNLOADED_NOT_APPROVED: '#0091A1',
        UNLOADED: '#8100C7',
        FINISHED: '#0E6A00',
        CANCELLED: '#000'
      }
    },
    {
      id: 'driverStatuses',
      label: 'driver.status.title',
      path: 'driverStatus',
      filterType: FilterType.Select,
      selectOptions: driverStatusOptions,
      statusToIndicatorColorMap: {
        TRAFFIC_JAM: '#FFEE18',
        ACCIDENT: '#ED1C24',
        AVAILABLE: '#65D609',
        UNAVAILABLE: '#030B6F'
      }
    },
    {
      id: 'referenceId',
      label: 'order.referenceId',
      path: 'referenceId',
      filterType: FilterType.Text
    },
    {
      id: 'creationDate',
      label: 'order.issueDate',
      path: 'creationDate',
      sortable: true,
      filterType: FilterType.DateRange,
      filterNames: ['creationDateGe', 'creationDateLe']
    },
    {
      id: 'lastEditedDateTime',
      label: 'order.lastEditedDate',
      path: 'lastEditedDateTime',
      sortable: true,
      filterType: FilterType.DateRange,
      filterNames: ['lastEditedDateGe', 'lastEditedDateLe']
    },
    {
      id: 'firstLoadingDate',
      label: 'order.delivery.loading.title',
      path: 'firstLoadingDate',
      sortable: true,
      filterType: FilterType.Date
    },
    {
      id: 'lastUnloadingDate',
      label: 'order.delivery.unloading.title',
      path: 'lastUnloadingDate',
      sortable: true,
      filterType: FilterType.Date
    },
    {
      id: 'firstLoadingLocation',
      label: 'order.delivery.loading.from',
      path: 'firstLoadingLocation',
      filterType: FilterType.Text
    },
    {
      id: 'firstLoadingCity',
      label: 'order.delivery.loading.fromCity',
      path: 'firstLoadingCity',
      sortable: true,
      filterType: FilterType.Text
    },
    {
      id: 'lastUnloadingLocation',
      label: 'order.delivery.unloading.to',
      path: 'lastUnloadingLocation',
      filterType: FilterType.Text
    },
    {
      id: 'lastUnloadingCity',
      label: 'order.delivery.unloading.toCity',
      path: 'lastUnloadingCity',
      sortable: true,
      filterType: FilterType.Text
    },
    {
      id: 'carRegistrationNumber',
      label: 'order.carRegistrationNumber',
      path: 'carRegistrationNumber',
      filterType: FilterType.Text
    },
    {
      id: 'contractor',
      label: 'order.contractor',
      path: 'contractor',
      filterType: FilterType.Text
    },
    {
      id: 'carrierIds',
      label: 'order.carrier',
      path: 'carrierId',
      filterType: FilterType.Select,
      selectOptions: carriers
    },
    {
      id: 'dispatcherIds',
      label: 'order.dispatcherId',
      path: 'dispatcherId',
      filterType: FilterType.Select,
      selectOptions: dispatchers
    },
    {
      id: 'forwarderIds',
      label: 'order.forwarderId',
      path: 'forwarderId',
      filterType: FilterType.Select,
      selectOptions: forwarders
    },
    {
      id: 'orderComment',
      label: 'order.comment',
      path: 'comment',
      filterType: FilterType.None,
      renderFunc: (cell, row, col, isHovered) =>
        (
          <>
            {cell ? truncateContentAndAddTooltip(cell, 25) : ''}
            <CommentEdit row={row}
                         visible={isHovered}
                         onSuccess={() => tableRef.current.refreshContent()}
            />
          </>
        )
    },
    {
      id: 'distanceToNextDeliveryPointKm',
      label: 'order.distanceToNextDeliveryPoint',
      path: 'distanceToNextDeliveryPointKm',
      sortable: true,
      renderFunc: distance => distance === null ? '―' : distance,
      filterType: FilterType.NumberRange,
      filterNames: ['distanceToNextDeliveryPointKmGe', 'distanceToNextDeliveryPointKmLe'],
      filterPlaceholders: ['orderTable.minDistanceFilterPlaceholder', 'orderTable.maxDistanceFilterPlaceholder']
    }
  ]
}

const fetchData = queryParams => {
  const { orderBy, filters, ...rest } = queryParams
  let newOrderBy = orderBy
  if (orderBy === 'orderStatuses') {
    newOrderBy = 'status'
  }
  const newFilters = filters.map(({ key, value }) => {
    if (key === 'creationDateGe' || key === 'creationDateLe') {
      return ({
        key: key,
        value: momentToISOLocalDate(moment(value))
      })
    }
    return ({ key, value })
  })
  const newQueryParams = {
    ...rest,
    orderBy: newOrderBy,
    filters: newFilters
  }
  return fetchOrders(newQueryParams)
}

const backgroundRefreshConfig = {
  shouldHighlightRow: (oldItem, newItem) => {
    const ignoredColumns = ['distanceToNextDeliveryPointKm']
    return !isEqual(
      omit(oldItem, ignoredColumns),
      omit(newItem, ignoredColumns)
    )
  }
}

const sortConfig = {
  defaultSortParameters: [{ param: 'creationDate', order: Order.Descending }],
  columnsGroupedBy: ['status']
}

export function OrdersTable () {
  const [carriers, setCarriers] = useState()
  const [forwarders, setForwarders] = useState()
  const [dispatchers, setDispatchers] = useState()
  const { t } = useTranslation()

  const history = useHistory()

  const { handleError } = useErrorHandling()

  const tableRef = useRef()

  const { privilege } = useAuth()

  useEffect(() => {
    fetchCarriers()
      .then(carriers => {
        let carriersOptions = carriers.map(
          carrier => ({ label: carrier.name, value: carrier.id }))
        setCarriers(carriersOptions)
      })
      .catch(handleError)
  }, [handleError])

  useEffect(() => {
    fetchDispatchers()
      .then(dispatchers => {
        let dispatcherOptions = dispatchers.map(
          dispatcher => ({
            label: dispatcher.firstName + ' ' + dispatcher.lastName,
            value: dispatcher.id
          }))
        setDispatchers(dispatcherOptions)
      })
      .catch(handleError)
  }, [handleError])

  useEffect(() => {
    fetchForwarders()
      .then(forwarders => {
        let forwardersOptions = forwarders.map(
          forwarder => ({
            label: forwarder.firstName + ' ' + forwarder.lastName,
            value: forwarder.id
          }))
        setForwarders(forwardersOptions)
      })
      .catch(handleError)
  }, [handleError])

  const isWebSocketConnection = useWebSocketConnection()

  useEffect(() => {
    if (isWebSocketConnection) {
      return subscribeOrderUpdated(() => {
        tableRef.current.refreshContentInBackground()
      })
    }
  }, [isWebSocketConnection])

  const columnDefinitions = useMemo(() => {
    if (carriers && forwarders && dispatchers) {
      return createColumnDefinitions(t, carriers, forwarders, dispatchers, tableRef)
    }
    return null
  }, [carriers, forwarders, dispatchers, t])

  const addOrder = () => {
    history.push('/orders/create')
  }

  const showDetails = (order) => {
    history.push(`/orders/details/${order.id}`)
  }

  const copyOrder = (order) => {
    history.push({
      pathname: '/orders/create',
      state: { idOfCopiedOrder: order.id }
    })
  }

  const editOrder = (order) => {
    history.push(`/orders/edit/${order.id}`)
  }

  const menuOptions = {
    edit: {
      onClick: editOrder
    },
    copy: {
      onClick: copyOrder
    },
    delete: (privilege === SUPER_ADMIN || privilege === ADMIN) ? {
      onDelete: deleteOrder,
      deleteDialog: {
        title: 'orderTable.deleteDialog.title',
        content: 'orderTable.deleteDialog.content'
      }
    } : undefined
  }

  return columnDefinitions
    ? (
      <Table
        title={'order.title'}
        addButton={{
          label: 'orderTable.addButtonLabel',
          onClick: addOrder
        }}
        fetchData={fetchData}
        columns={columnDefinitions}
        tableKey={'orders'}
        onRowClick={showDetails}
        menu={menuOptions}
        renderToolbarLeftPanel={() =>
          <CalendarFilter
            filters={tableRef.current.filters}
            onFiltersChanged={tableRef.current.setFilters}/>
        }
        sort={sortConfig}
        backgroundRefresh={backgroundRefreshConfig}
        ref={tableRef}
      />
    )
    : <Loader/>
}
